import { CSSResultArray, TemplateResult, customElement, html, property } from 'lit-element';
import { css, unsafeCSS } from 'lit-element/lib/css-tag';
import { RealBaseElement } from '../../base/BaseElement';
import { query } from 'lit-element/lib/decorators';
import { ifDefined } from 'lit-html/directives/if-defined';
import { event } from '../../../decorators/event.decorator';
import { EventWithTarget } from '../../../types';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin';

import { TimePickerDayTimeToggle } from '../time-picker-day-time-toggle/time-picker-day-time-toggle.component';

import { hostStyles } from '../../../host.styles';
import sharedStyles from '../shared/time-picker-input-part.scss';
import styles from './time-picker-input-part-day-time.component.scss';

const timePickerInputPartStyles = css`
  ${unsafeCSS(sharedStyles)}
`;
const timePickerInputPartDayTimeComponentStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The `zui-time-picker-input-part-day-time` is part of the `zui-time-picker-input` and is not designed for single usage.
 *
 * @example
 * html```
 * <zui-time-picker-input-part-day-time></zui-time-picker-input-part-day-time>
 * ```
 *
 * @fires CustomEvent - the `time-picker-input-part-day-time-changed` event is emitted when the input value has changed
 * @fires CustomEvent - the `time-picker-input-part-day-time-input` event is emitted on input
 */
@customElement('zui-time-picker-input-part-day-time')
export class TimePickerInputPartDayTime extends DelegateFocusMixin(RealBaseElement) {
  static get styles(): CSSResultArray {
    return [hostStyles, timePickerInputPartStyles, timePickerInputPartDayTimeComponentStyles];
  }

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * placeholder
   */
  @property({ reflect: true, type: String })
  placeholder: string;

  /**
   * readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * selected value
   */
  @property({ reflect: true, type: String })
  value: TimePickerDayTimeToggle['value'] | null;

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-input-part-focus-previous',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputPartFocusPreviousEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-part-focus-previous', {
        bubbles: true,
        composed: true,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-input-part-day-time-changed',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputPartDayTimeChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-part-day-time-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: this.value,
        },
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-input-part-day-time-input',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputPartDayTimeInputEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-part-day-time-input', {
        bubbles: true,
        composed: true,
        detail: {
          value: this._inputRef.value,
        },
      })
    );
  }

  @query('input')
  private _inputRef: HTMLInputElement;

  private _handleTimePickerInputPartDayTimeFocusEvent(): void {
    this._inputRef.select();
  }

  private _handleTimePickerInputPartDayTimeBluredEvent(): void {
    const oldValue = this.value;

    this.value = this._inputRef.value.length > 0 ? (this._inputRef.value as TimePickerDayTimeToggle['value']) : null;

    if (oldValue !== this.value) {
      this.emitTimePickerInputPartDayTimeChangedEvent();
    }
  }

  private _handleTimePickerInputPartDayTimeInputEvent(event: InputEvent | KeyboardEvent): void {
    event.stopPropagation();

    this.emitTimePickerInputPartDayTimeInputEvent();
  }

  private _handleTimePickerInputPartDayTimeKeydownEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    if (this.readonly) {
      return;
    }

    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Delete':
      case 'Tab':
        break;
      case 'a':
      case 'A':
        {
          event.preventDefault();
          this._inputRef.value = 'AM';

          this.emitTimePickerInputPartDayTimeInputEvent();
        }
        break;
      case 'Backspace':
        if (this._inputRef.value === '') {
          event.preventDefault();

          this.emitTimePickerInputPartFocusPreviousEvent();
        }
        break;
      case 'p':
      case 'P':
        {
          event.preventDefault();
          this._inputRef.value = 'PM';

          this.emitTimePickerInputPartDayTimeInputEvent();
        }
        break;
      case 'ArrowUp':
        {
          event.preventDefault();
          this._inputRef.value = this._inputRef.value === 'AM' ? 'PM' : 'AM';

          this.emitTimePickerInputPartDayTimeInputEvent();
        }
        break;
      case 'ArrowDown':
        {
          event.preventDefault();
          this._inputRef.value = this._inputRef.value === 'AM' ? 'PM' : 'AM';

          this.emitTimePickerInputPartDayTimeInputEvent();
        }
        break;
      default:
        event.preventDefault();
        break;
    }
  }

  protected render(): TemplateResult {
    return html`
      <input
        .value="${ifDefined(this.value)}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        ?zuiCaptureFocus="${!this.disabled}"
        placeholder="${ifDefined(this.placeholder)}"
        type="text"
        @blur=${this._handleTimePickerInputPartDayTimeBluredEvent}
        @focus=${this._handleTimePickerInputPartDayTimeFocusEvent}
        @input=${this._handleTimePickerInputPartDayTimeInputEvent}
        @keydown=${this._handleTimePickerInputPartDayTimeKeydownEvent}
      />
    `;
  }
}
