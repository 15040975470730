import { customElement, html, property, TemplateResult } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import type { MenuItem } from '../../menu/menu-item/menu-item.component';
import type { SelectOption } from '../select-option/select-option.component';

// eslint-disable-next-line no-shadow
enum PlaceholderSpecifier {
  ItemCount = 'itemCount',
  Item = 'item',
  Items = 'items',
  SelectedCount = 'selectedCount',
  Selected = 'selected',
  Selection = 'selection',
}

/**
 * A feature component providing functionality for select placeholders and selected labels.
 * It's used by the `zui-select` feature internally but can be slotted explicitly into it as well to customize placeholders
 * and labels likewise.
 *
 * ### Functionality
 * It can be parametrized with patterns for multiple selection states.
 * Every pattern template can use the following specifiers to be replaced:
 * | specifier      | description                            |
 * | :------------- | :------------------------------------- |
 * | %itemCount     | amount of available items              |
 * | %item          | first available item                   |
 * | %items         | all available items, joined by a comma |
 * | %selectedCount | amount of selected items               |
 * | %selected      | first selected item                    |
 * | %selection     | all selected items, joined by a comma  |
 *
 * @example
 * ```HTML
 * <zui-select-placeholder>Custom placeholder</zui-select-placeholder>
 * ```
 *
 * ```HTML
 * <zui-select-placeholder pattern-all="%selectedCount selected"
 *                         pattern-many="%selectedCount selected"
 *                         pattern-single="%selected"
 *                         slot="placeholder"
 * >
 *   Custom placeholder
 * </zui-select-placeholder>
 * ```
 *
 * @slot - default slot for defining the placeholder label (aka watermark)
 */
@customElement('zui-select-placeholder')
export class SelectPlaceholder extends BaseElement {
  /**
   * is used if all are selected
   */
  @property({ reflect: true, type: String, attribute: 'pattern-all' })
  patternAll = '%selectedCount selected';

  /**
   * is used if not all are selected, but more than one
   */
  @property({ reflect: true, type: String, attribute: 'pattern-many' })
  patternMany = '%selectedCount selected';

  /**
   * is used if exactly one item is selected (but more than one are selectable)
   */
  @property({ reflect: true, type: String, attribute: 'pattern-single' })
  patternSingle = '%selected';

  /**
   * formats a label for the current selection using the appropriate pattern
   *
   * @param items
   * @param selection
   * @param multiple
   */
  format(items: (MenuItem | SelectOption)[], selection: SelectOption[], multiple: boolean): string {
    // use replacement patters for multiple selection
    if (multiple) {
      if (items.length === selection.length) {
        return this.usePattern(this.patternAll, items, selection);
      } else if (selection.length > 1) {
        return this.usePattern(this.patternMany, items, selection);
      } else {
        return this.usePattern(this.patternSingle, items, selection);
      }
    } else {
      // single selections use the options content
      const ref = selection[0] || (this.renderRoot as Element);
      return ref.innerHTML;
    }
  }

  /**
   * utility to get text only from a given option or menu item
   *
   * @param option
   */
  extractText(option: MenuItem | SelectOption): string {
    return Array.from(option.childNodes)
      .map(({ textContent }) => textContent)
      .join('')
      .trim();
  }

  /**
   * delivers the label based on the current selection and all available items
   *
   * @param pattern
   * @param items
   * @param selection
   */
  usePattern(pattern: string, items: (MenuItem | SelectOption)[], selection: SelectOption[]): string {
    const specifiers = Object.values(PlaceholderSpecifier);
    const regex = new RegExp(`%(${specifiers.join('|')})`, 'gi');
    return pattern.replaceAll(regex, (match: string, specifier: PlaceholderSpecifier): string => {
      switch (specifier) {
        // replace all item variables
        case PlaceholderSpecifier.ItemCount:
          return items.length.toString();
        case PlaceholderSpecifier.Item:
          return this.extractText(items[0]);
        case PlaceholderSpecifier.Items:
          return items.map((item) => this.extractText(item)).join(', ');

        // replace all selection variables
        case PlaceholderSpecifier.SelectedCount:
          return selection.length.toString();
        case PlaceholderSpecifier.Selected:
          return this.extractText(selection[0]);
        case PlaceholderSpecifier.Selection:
          return selection.map((selected) => this.extractText(selected)).join(', ');

        default:
          // just make sure that changes to the enum require a replacement implementation
          // https://stackoverflow.com/a/39419171/1146207
          // eslint-disable-next-line no-case-declarations,@typescript-eslint/no-unused-vars
          const exhaustiveCheck: never = specifier;
          return match;
      }
    });
  }

  protected render(): TemplateResult {
    return html`<slot></slot>`;
  }
}
