import { CSSResultArray, TemplateResult, customElement, html, property } from 'lit-element';
import { css, unsafeCSS } from 'lit-element/lib/css-tag';
import { styleMap } from 'lit-html/directives/style-map';
import { BaseElement } from '../../base/BaseElement';
import { event } from '../../../decorators/event.decorator';
import { EventWithTarget } from '../../../types';

import { InteractiveText } from '../../interactive-text/interactive-text.component';

import { hostStyles } from '../../../host.styles';
import styles from './time-picker-day-time-toggle.component.scss';

const timePickerDayTimeToggleComponentStyles = css`
  ${unsafeCSS(styles)}
`;

type DayTime = 'AM' | 'PM';

/**
 * Time picker day time toggle.
 *
 * @example
 * html```
 * <zui-time-picker-day-time-toggle value="AM"></zui-time-picker-day-time-toggle>
 * ```
 *
 * @fires CustomEvent - the `time-picker-day-time-changed` event is emitted when the day time changes
 */
@customElement('zui-time-picker-day-time-toggle')
export class TimePickerDayTimeToggle extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, timePickerDayTimeToggleComponentStyles];
  }

  /**
   * standalone / integrated
   */
  @property({ reflect: true, type: Boolean })
  integrated = false;

  /**
   * value
   */
  @property({ reflect: true, type: String })
  value: DayTime;

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-day-time-changed',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitTimePickerDayTimeChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-day-time-changed', {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: {
          value: this.value,
        },
      })
    );
  }

  private _handleAmPmToggle(event: EventWithTarget<InteractiveText>): void {
    const value = event.target.getAttribute('id') as DayTime;

    if (value === this.value) {
      return;
    }

    this.value = value;

    this.emitTimePickerDayTimeChangedEvent();
  }

  protected render(): TemplateResult {
    return html`
      <zui-interactive-text
        id="AM"
        style="${styleMap({
          '--zui-interactive-text-font': 'var(---zui-time-picker-day-time-toggle-font)',
          '--zui-interactive-text-hitarea-size': '4px',
        })}"
        emphasis="${this.value === 'AM' ? 'highlight' : 'subtle'}"
        @click="${this._handleAmPmToggle}"
      >
        <span>AM</span>
      </zui-interactive-text>
      <zui-interactive-text
        id="PM"
        style="${styleMap({
          '--zui-interactive-text-font': 'var(---zui-time-picker-day-time-toggle-font)',
          '--zui-interactive-text-hitarea-size': '4px',
        })}"
        emphasis="${this.value === 'PM' ? 'highlight' : 'subtle'}"
        @click="${this._handleAmPmToggle}"
      >
        <span>PM</span>
      </zui-interactive-text>
    `;
  }
}
