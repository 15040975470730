import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import { EventWithTarget } from '../../../types';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { RealBaseElement } from '../../base/BaseElement';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { emptyStringToNullWrapperConverter, stringUndefinedConverter } from '../../../utils/component.utils';

import { RadioButton } from '../radio-button/radio-button.component';

import style from './radio-button-group.component.scss';

const radioButtonGroupStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The radioButtonGroup is a component to encapsulate multiple radio buttons.
 * Only one of this radio buttons can be chaecked at a given time.
 * If a new radio button gets checked the other checked radio button gets unchecked.
 * It is possible to reset the radio button group to it's default value, by calling the function reset(); on it.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-radio-button-group name="size" value="small">
 * 		<zui-radio-button label="Small" value="small"></zui-radio-button>
 * 		<zui-radio-button label="Medium" value="medium"></zui-radio-button>
 * 		<zui-radio-button label="Large" value="large"></zui-radio-button>
 * </zui-radio-button-group>
 * ```
 *
 * @fires input - Simulates the default `input` event to imitate default behavior
 * @fires change - Simulates the default `input` event to imitate default behavior
 * @fires blur - Simulates the default `blur` event to imitate default behavior
 * @slot default - In this slot should the radio buttons of the radio button group be placed.
 * No other type of HTML elements should be placed inside.
 */
@customElement('zui-radio-button-group')
export class RadioButtonGroup
  extends FormValidationMixin(
    FormDataHandlingMixin(DelegateFocusMixin(RealBaseElement), {
      formControlSelector: function () {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore because TS does not know, that we dynamically bind this in a mixin; I would forget it myself!
        return this._selectedRadioButton;
      },
    })
  )
  implements FormValidationElement<FormEnabledElement> {
  static get styles(): CSSResultArray {
    return [hostStyles, radioButtonGroupStyles];
  }

  /**
   * value of the radio button group
   */
  @property({ reflect: true, converter: emptyStringToNullWrapperConverter(stringUndefinedConverter) })
  value: string | undefined;

  /**
   * whether the radio button group is required
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * @deprecated
   */
  @event({
    eventName: 'changed',
    bubbles: true,
    composed: false,
  })
  emitChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('changed', {
        bubbles: true,
        composed: false,
        detail: this.value,
      })
    );
  }

  @event({
    eventName: 'change',
    bubbles: true,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        composed: false,
      })
    );
  }

  @event({
    eventName: 'input',
    bubbles: true,
    composed: true,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new Event('input', {
        bubbles: true,
        composed: true,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'blur',
    bubbles: true,
    composed: false,
    cancelable: false,
  })
  emitBlurEvent(): void {
    this.dispatchEvent(
      new FocusEvent('blur', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  @queryAssignedNodes('', true, 'zui-radio-button')
  private _assignedRadioButtons: RadioButton[];

  private get _selectedRadioButton(): RadioButton | null {
    return this._assignedRadioButtons.find((radioButton) => radioButton.value === this.value) ?? null;
  }

  private _propagateDisabled = false;

  private _isUserInteraction = false;

  constructor() {
    super();

    this.addValidator({
      type: 'valueMissing',
      validator: () => {
        return this.required === true
          ? this.value !== undefined &&
              // if we are required, only a value that can be found within buttons is valid
              this._assignedRadioButtons.find((radioButton) => radioButton.value === this.value) !== undefined
          : true;
      },
      validatesOnProperties: ['required'],
    });
  }

  focusCallback(): void {
    if (!this.disabled) {
      const [firstRadioButton] = this._assignedRadioButtons;
      firstRadioButton?.focus();
    }
  }

  private _handleFocusOut({ relatedTarget: focusDestination }: FocusEvent): void {
    const isInsideClick = this._assignedRadioButtons.some((radioButton) => radioButton === focusDestination);
    if (!isInsideClick) {
      this.emitBlurEvent();
    }
  }

  private _inputEventHandler(event: EventWithTarget<RadioButton>): void {
    event.stopPropagation();

    if (this.disabled || this.readonly) {
      return;
    }

    if (event.target.tagName.toLowerCase() === 'zui-radio-button') {
      this.value = event.target.value;
      this._isUserInteraction = true;
      this.emitInputEvent();
    }
  }

  private _propagateState(): void {
    this._assignedRadioButtons.forEach((button) => {
      if (this._propagateDisabled) {
        button.disabled = this.disabled;
      }

      if (this.value) {
        button.checked = button.value === this.value;
      } else {
        button.checked = false;
      }
    });
  }

  private _handleSlotChange(): void {
    this._propagateState();
    // missing options might turn it invalid
    this.checkValidity();
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    // if disabled is true it should always be propagated
    if (this.disabled || changedProperties.get('disabled')) {
      this._propagateDisabled = true;
    }

    if (changedProperties.has('value') && this._isUserInteraction) {
      this.emitChangedEvent();
      this.emitChangeEvent();
      this._isUserInteraction = false;
    }

    this._propagateState();
  }

  protected render(): TemplateResult | void {
    return html`<div @focusout="${this._handleFocusOut}">
      <slot id="radio-button-slot" @slotchange="${this._handleSlotChange}" @input="${this._inputEventHandler}"></slot>
    </div>`;
  }
}
