import { CSSResultArray, TemplateResult, customElement, html, property } from 'lit-element';
import { css, unsafeCSS } from 'lit-element/lib/css-tag';
import { BaseElement } from '../../base/BaseElement';
import { event } from '../../../decorators/event.decorator';
import { getDefaultLocale, isoDateConverter } from '../../date-picker/utils/date-picker.utils';
import { getDayTime, getHourByDayTime, getLocalizedHour, getUpdatedDate, hasDayTime } from '../utils/time-picker.utils';
import { DateTime } from 'luxon';
import { ifDefined } from 'lit-html/directives/if-defined';
import { nothing } from 'lit-html/lib/part';
import { query, state } from 'lit-element/lib/decorators';

import { TimePickerDayTimeToggle } from '../time-picker-day-time-toggle/time-picker-day-time-toggle.component';
import { TimePickerCell } from '../time-picker-cell/time-picker-cell.component';

import { hostStyles } from '../../../host.styles';
import styles from './time-picker.component.scss';

const timePickerComponentStyles = css`
  ${unsafeCSS(styles)}
`;

type TimePickerHourCycle = 'h12' | 'h23';

/**
 * The `zui-time-picker` is part of the `zui-textfield-time-picker` form component.
 * It consists of two `zui-time-picker-cell`'s that are responsible for manipulating hours and minutes.
 * The am/pm toggle is available when the `hour-cycle` is set to `h12`. Switching between am and pm adjusts the date value accordingly.
 *
 * @example
 * html```
 * <zui-time-picker default-value="2021-12-01T08:30:00.000Z" hour-cycle="h23"></zui-time-picker>
 * ```
 *
 * @fires CustomEvent - the `time-picker-changed` event is emitted when the time changes
 * @fires CustomEvent - the `time-picker-input` event is emitted when there is some input interaction on one of the cells
 */
@customElement('zui-time-picker')
export class TimePicker extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, timePickerComponentStyles];
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  private static readonly MAX_12_HOURS = 12;
  private static readonly MAX_24_HOURS = 23;
  private static readonly MIN_12_HOURS = 1;
  private static readonly MIN_24_HOURS = 0;
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * the default value is used when there is no value present and an interaction with one of the cell's has happened
   */
  @property({ reflect: true, type: String, attribute: 'default-value', converter: isoDateConverter })
  defaultValue: Date = new Date();

  private get _defaultValueDT(): DateTime {
    return DateTime.fromJSDate(this.defaultValue);
  }

  /**
   * disabled
   *
   * @private
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * hour cycle
   *
   * @returns {TimePickerHourCycle} locale dependent or overridden hour cycle
   */
  @property({ reflect: true, type: String, attribute: 'hour-cycle' })
  get hourCycle(): TimePickerHourCycle {
    if (!this._internalHourCycle) {
      return hasDayTime(this.locale) ? 'h12' : 'h23';
    }

    return this._internalHourCycle;
  }

  set hourCycle(value: TimePickerHourCycle) {
    const oldValue = this._internalHourCycle;
    this._internalHourCycle = value;

    this.requestUpdate('_internalHourCycle', oldValue);
  }

  /**
   * standalone / integrated
   */
  @property({ reflect: true, type: Boolean })
  integrated = false;

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * step size
   *
   * @private
   */
  @property({ reflect: true, type: Number })
  step = 1;

  /**
   * selected value
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  value: Date | null = null;

  private get _valueDT(): DateTime | undefined {
    return this.value ? DateTime.fromJSDate(this.value) : undefined;
  }

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-changed',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitTimePickerChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-changed', {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: { value: this.value?.toISOString() },
      })
    );
  }

  /**
   * @param value value
   *
   * @private
   */
  @event({
    eventName: 'time-picker-input',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitTimePickerInputEvent(value: string): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input', {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: { value },
      })
    );
  }

  @query('zui-time-picker-cell#hour')
  private _timePickerCellHour: TimePickerCell;

  @query('zui-time-picker-cell#minute')
  private _timePickerCellMinute: TimePickerCell;

  @state()
  private _internalHourCycle: TimePickerHourCycle;

  private get _currentDate(): DateTime {
    return this._valueDT ?? this._defaultValueDT;
  }

  private get _dayTime(): TimePickerDayTimeToggle['value'] | undefined {
    return this._is12HourFormat ? getDayTime(this._currentDate, this.hourCycle) : undefined;
  }

  private get _defaultHour(): string {
    return getLocalizedHour(this._defaultValueDT, this.locale, this.hourCycle);
  }

  private get _hour(): string | undefined {
    return this._valueDT ? getLocalizedHour(this._valueDT, this.locale, this.hourCycle) : undefined;
  }

  private get _is12HourFormat(): boolean {
    return this.hourCycle === 'h12';
  }

  private get _literal(): string {
    return this._currentDate
      .toLocaleParts({ hour: 'numeric', minute: 'numeric' })
      .find(({ type }) => type === 'literal').value;
  }

  private _handleTimePickerDayTimeChangedEvent({
    detail,
  }: CustomEvent<{ value: TimePickerDayTimeToggle['value'] }>): void {
    const hour = getHourByDayTime(this._currentDate.hour, detail.value);
    this.value = this._currentDate.set({ hour }).toJSDate();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.emitTimePickerInputEvent(this._valueDT!.toISO());
    this.emitTimePickerChangedEvent();
  }

  private _handleTimePickerCellChangedEvent(): void {
    const hour = this._timePickerCellHour.value ?? this._timePickerCellHour.defaultValue;
    const minute = this._timePickerCellMinute.value ?? this._timePickerCellMinute.defaultValue;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.value = getUpdatedDate({ hour, minute }, this._currentDate, this.hourCycle, this._dayTime!).toJSDate();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.emitTimePickerInputEvent(this._valueDT!.toISO());
    this.emitTimePickerChangedEvent();
  }

  private _handleTimePickerCellInputEvent(): void {
    const hour = this._timePickerCellHour.value ?? this._timePickerCellHour.defaultValue;
    const minute = this._timePickerCellMinute.value ?? this._timePickerCellMinute.defaultValue;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const newDate = getUpdatedDate({ hour, minute }, this._currentDate, this.hourCycle, this._dayTime!);

    this.emitTimePickerInputEvent(newDate.toISO());
  }

  private _getDayTimeToggleTemplate(): TemplateResult {
    return html`
      <div class="day-time-toggle">
        <zui-time-picker-day-time-toggle
          ?integrated="${this.integrated}"
          value="${ifDefined(this._dayTime)}"
          @time-picker-day-time-changed="${this._handleTimePickerDayTimeChangedEvent}"
        ></zui-time-picker-day-time-toggle>
      </div>
    `;
  }

  protected render(): TemplateResult {
    return html`
      ${this._is12HourFormat && this.integrated ? this._getDayTimeToggleTemplate() : nothing}
      <div
        class="time-picker"
        @time-picker-cell-changed="${this._handleTimePickerCellChangedEvent}"
        @time-picker-cell-input="${this._handleTimePickerCellInputEvent}"
      >
        <zui-time-picker-cell
          ?disabled="${this.disabled}"
          default-value="${this._defaultHour}"
          id="hour"
          max="${this._is12HourFormat ? TimePicker.MAX_12_HOURS : TimePicker.MAX_24_HOURS}"
          min="${this._is12HourFormat ? TimePicker.MIN_12_HOURS : TimePicker.MIN_24_HOURS}"
          step="${this.step}"
          value="${ifDefined(this._hour)}"
        >
        </zui-time-picker-cell>
        <zui-time-picker-cell-divider
          ?disabled="${this.disabled}"
          literal="${this._literal}"
        ></zui-time-picker-cell-divider>
        <zui-time-picker-cell
          ?disabled="${this.disabled}"
          default-value="${this._defaultValueDT.minute}"
          id="minute"
          max="59"
          min="0"
          step="${this.step}"
          value="${ifDefined(this._valueDT?.minute)}"
        >
        </zui-time-picker-cell>
        ${this._is12HourFormat && !this.integrated ? this._getDayTimeToggleTemplate() : nothing}
      </div>
    `;
  }
}
