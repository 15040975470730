import { css, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import { state } from 'lit-element/lib/decorators';
import { BaseElement } from '../../base/BaseElement';

import { hostStyles } from '../../../host.styles';
import style from './pagination-dot.component.scss';

type Emphasis = 'default' | 'selected';
type Size = 's' | 'm';

const paginationDotStyles = css`
  ${unsafeCSS(style)}
`;

const INTERNAL_ATTRIBUTE_SELECTED_NAME = 'zui-internal-animate-selected';

/**
 * Renders a pagination dot based on the `emphasis` and `size` attribute/property.
 *
 * This component is internally used in the `PaginationDotBar` component.
 *
 * @example
 * ```HTML
 * <zui-pagination-dot emphasis="default" size="m"></zui-pagination-dot>
 * ```
 * @cssprop --zui-pagination-dot-transition-timing - animation duration of the dot
 */
@customElement('zui-pagination-dot')
export class PaginationDot extends BaseElement {
  static readonly styles = [hostStyles, paginationDotStyles];

  static readonly internalAnimateSelectedAttribute = INTERNAL_ATTRIBUTE_SELECTED_NAME;

  /**
   * internal prop for toggling anim
   *
   * @private
   * @returns result whether anim is to be triggered or not
   */
  @property({ reflect: true, type: Boolean, attribute: INTERNAL_ATTRIBUTE_SELECTED_NAME })
  get animateSelected(): boolean {
    return this._animateSelected;
  }

  set animateSelected(value: boolean) {
    this._animateSelected = value;
    // from false to true, schedule timeout
    if (this._animateSelected) {
      setTimeout(() => {
        this.removeAttribute(INTERNAL_ATTRIBUTE_SELECTED_NAME);
      }, this._timeout);
    }
  }

  /**
   * the dot emphasis
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * the dot size
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  @state()
  private _timeout = 0;

  private _animateSelected = false;

  protected firstUpdated(): void {
    this._timeout = parseInt(getComputedStyle(this).getPropertyValue('--zui-pagination-dot-transition-timing'), 10);
  }

  protected render(): TemplateResult {
    return html`
      <button>
        <span class="pagination-dot"></span>
      </button>
    `;
  }
}
