import { CSSResultArray, TemplateResult, customElement, css, unsafeCSS, html } from 'lit-element';

import { BaseElement } from '../../base/BaseElement';

import { hostStyles } from '../../../host.styles';
import styles from './multi-item-slider-item.component.scss';

const MULTI_ITEM_SLIDER_ITEM_STYLES = css`
  ${unsafeCSS(styles)}
`;

/**
 * The multi item slider items are used to declare single item contents of a slider.
 * It's slotting contents right through, but cuts-off horizontally overflowing content.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-multi-item-slider-item>Foo</zui-multi-item-slider-item>
 * ```
 *
 * @slot default - Default slot for passing in arbitrary content
 */
@customElement('zui-multi-item-slider-item')
export class MultiItemSliderItem extends BaseElement {
  static readonly styles: CSSResultArray = [hostStyles, MULTI_ITEM_SLIDER_ITEM_STYLES];

  protected render(): TemplateResult {
    return html`<slot></slot>`;
  }
}
