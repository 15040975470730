import { CSSResultArray, TemplateResult, customElement, html, property } from 'lit-element';
import { css, unsafeCSS } from 'lit-element/lib/css-tag';
import { RealBaseElement } from '../../base/BaseElement';
import { query, state } from 'lit-element/lib/decorators';
import { ifDefined } from 'lit-html/directives/if-defined';
import { event } from '../../../decorators/event.decorator';
import { cycle, isDefined, numberUndefinedConverter } from '../../../utils/component.utils';
import { EventWithTarget } from '../../../types';
import { addLeadingZeros } from '../../date-picker/utils/date-picker-input.utils';
import { sliceAndClamp } from '../utils/time-picker.utils';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin';
import { classMap } from 'lit-html/directives/class-map';

import { hostStyles } from '../../../host.styles';
import sharedStyles from '../shared/time-picker-input-part.scss';
import styles from './time-picker-input-part-number.component.scss';

const timePickerInputPartStyles = css`
  ${unsafeCSS(sharedStyles)}
`;
const timePickerInputPartNumberComponentStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The `zui-time-picker-input-part-number` is part of the `zui-time-picker-input` and is not designed for single usage.
 *
 * @example
 * html```
 * <zui-time-picker-input-part-number max="23" min="0" placeholder="HH"></zui-time-picker-input-part-number>
 * ```
 *
 * @fires CustomEvent - the `time-picker-input-part-focus-next` event is emitted when the max input characters is reached
 * @fires CustomEvent - the `time-picker-input-part-focus-previous` event is emitted when the input value is an empty string and backspace is pressed
 * @fires CustomEvent - the `time-picker-input-part-number-changed` event is emitted when the input value has changed
 * @fires CustomEvent - the `time-picker-input-part-number-input` event is emitted on input
 *
 * @cssprop --zui-time-picker-input-part-number-placeholder-width - width of the input when no value is present
 */
@customElement('zui-time-picker-input-part-number')
export class TimePickerInputPartNumber extends DelegateFocusMixin(RealBaseElement) {
  static get styles(): CSSResultArray {
    return [hostStyles, timePickerInputPartStyles, timePickerInputPartNumberComponentStyles];
  }

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * max value
   */
  @property({ reflect: true, type: Number })
  max: number;

  /**
   * min value
   */
  @property({ reflect: true, type: Number })
  min: number;

  /**
   * placeholder
   */
  @property({ reflect: true, type: String })
  placeholder: string;

  /**
   * readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * step
   *
   * @private
   */
  @property({ reflect: true, type: Number })
  step = 1;

  /**
   * value
   *
   * @returns value number | undefined
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  get value(): number | undefined {
    return this._internalValue;
  }

  set value(value: number | undefined) {
    const oldValue = this._internalValue;

    this._internalValue = value;
    this.showPlaceholder = !isDefined(value);

    this.requestUpdate('value', oldValue);
  }

  /**
   * default value
   */
  @property({ reflect: true, attribute: 'default-value', converter: numberUndefinedConverter })
  defaultValue: number | undefined;

  @state()
  showPlaceholder = true;

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-input-part-focus-next',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputPartFocusNextEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-part-focus-next', {
        bubbles: true,
        composed: true,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-input-part-focus-previous',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputPartFocusPreviousEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-part-focus-previous', {
        bubbles: true,
        composed: true,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-input-part-number-changed',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputPartNumberChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-part-number-changed', {
        bubbles: true,
        composed: true,
        detail: {
          // emit null instead of undefined when not defined
          value: this.value ?? null,
        },
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-input-part-number-input',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputPartNumberInputEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-part-number-input', {
        bubbles: true,
        composed: true,
        detail: {
          // emit null instead of NaN when not defined
          value: isNaN(this._inputRef.valueAsNumber) ? null : this._inputRef.valueAsNumber,
        },
      })
    );
  }

  @query('input')
  private _inputRef: HTMLInputElement;

  private _internalValue: number | undefined;

  private get _paddedValue(): string | null {
    if (isDefined(this.value)) {
      return addLeadingZeros(this.value, String(this.max).length);
    }

    return null;
  }

  private get _initialValue(): number | undefined {
    return this.value ?? this.defaultValue;
  }

  private _handleTimePickerInputPartNumberFocusEvent(): void {
    this._inputRef.select();
  }

  private _handleTimePickerInputPartNumberInputEvent(event: InputEvent | KeyboardEvent): void {
    event.stopPropagation();

    this._inputRef.value = sliceAndClamp(this._inputRef.value, this.min, this.max);
    this.showPlaceholder = this._inputRef.value.length === 0;

    this.emitTimePickerInputPartNumberInputEvent();

    if (this._inputRef.value.length === String(this.max).length) {
      this.emitTimePickerInputPartFocusNextEvent();
    }
  }

  private _handleTimePickerInputPartNumberBluredEvent(): void {
    const oldValue = this.value;

    if (oldValue === undefined && isNaN(this._inputRef.valueAsNumber)) {
      return;
    }

    const isValidValue =
      !isNaN(this._inputRef.valueAsNumber) &&
      this._inputRef.valueAsNumber >= this.min &&
      this._inputRef.valueAsNumber <= this.max;

    this.value = isValidValue ? this._inputRef.valueAsNumber : undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because TS does not know about the DOM
    this._inputRef.value = this._paddedValue;

    if (oldValue !== this.value) {
      this.emitTimePickerInputPartNumberChangedEvent();
    }
  }

  private _handleTimePickerInputPartNumberKeydownEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    if (this.readonly) {
      return;
    }

    switch (event.key) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
      case '0':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Delete':
      case 'Tab':
        break;
      case 'ArrowUp':
        {
          event.preventDefault();

          this._inputRef.value = isNaN(this._inputRef.valueAsNumber)
            ? String(this._initialValue ?? this.min)
            : String(cycle(this._inputRef.valueAsNumber, this.min, this.max, this.step, 'increase'));
          this.showPlaceholder = this._inputRef.value.length === 0;

          this.emitTimePickerInputPartNumberInputEvent();
        }
        break;
      case 'ArrowDown':
        {
          event.preventDefault();

          this._inputRef.value = isNaN(this._inputRef.valueAsNumber)
            ? String(this._initialValue ?? this.max)
            : String(cycle(this._inputRef.valueAsNumber, this.min, this.max, this.step, 'decrease'));
          this.showPlaceholder = this._inputRef.value.length === 0;

          this.emitTimePickerInputPartNumberInputEvent();
        }
        break;
      case 'Backspace':
        if (this._inputRef.value === '') {
          event.preventDefault();

          this.emitTimePickerInputPartFocusPreviousEvent();
        }
        break;
      default:
        event.preventDefault();
        break;
    }
  }

  protected render(): TemplateResult {
    return html`
      <input
        .value="${this._paddedValue}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        ?zuiCaptureFocus="${!this.disabled}"
        class="${classMap({ 'show-placeholder': this.showPlaceholder })}"
        max="${this.max}"
        min="${this.min}"
        placeholder="${ifDefined(this.placeholder)}"
        step="${this.step}"
        type="number"
        @blur=${this._handleTimePickerInputPartNumberBluredEvent}
        @focus=${this._handleTimePickerInputPartNumberFocusEvent}
        @input=${this._handleTimePickerInputPartNumberInputEvent}
        @keydown=${this._handleTimePickerInputPartNumberKeydownEvent}
      />
    `;
  }
}
