import { css, customElement, property, PropertyValues, query, TemplateResult, unsafeCSS } from 'lit-element';
import { html } from 'lit-html';

import { BaseElement } from '../base/BaseElement';
import { event } from '../../decorators/event.decorator';
import { getCursorPosition } from '../../utils/dom.utils';

import { hostStyles } from '../../host.styles';
import style from './scrollbar.component.scss';

// TODO: fix event handling
export type ScrollbarEvent = CustomEvent<{
  offset: number;
  orientation: 'horizontal' | 'vertical';
}>;

const SCROLLBAR_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * The scrollbar is a UI component with only some limited logic. It's used in the `zui-scrollable-directive` internally.
 *
 * ## Anatomy
 * ░ scroll track
 * █ scroll handle
 * ░░░░░░░░░███████░░░
 * ┗━━━┯━━━┻━━━┯━━━┛
 *   offset   size
 *
 * ## Functionality
 * It's basic function is to provide visual guidance to the user about a scroll state, either horizontal or vertical.
 * The informations about the scroll state have to be set from the outside, as this component has no relation to the
 * scroll target whatsoever. The scroll state consists basicly of two informations, the amount to scroll and the
 * relation of the visible to the non-visible (overflow) content. This is visualized by offsetting and scaling the
 * scroll handle accordingly. To keep this UI component as flexible and stateless as possible, the _size_ and the
 * _offset_ of the handle can be set explisitly using the custom properties `--zui-scrollbar-handle-size` and
 * `--zui-scrollbar-handle-offset`.
 *
 * ## Behavior
 * If not disabled, the scroll handle can be dragged along the axis of the defined orientation (x-axis if `horizontal`
 * _or_ y-axis if `vertical`). The dragging is limited to the boundaries of the scroll track. Additionally the track
 * around the handle can be clicked to jump-scroll directly to this position. Every interaction triggers a custom
 * `scrollbar` event which emits the resulting offset and the orientation of the scrollbar as payload. The offset is
 * a relative value as percentage.
 *
 * ## Notes
 * - if used in conjunction with another scrollbar, the `multiple` flag has to be set _on both_\
 *   to prevent the two end of the bars to overlap
 * - using `multiple` results in a dead square bewtween the bars which must be handled _outside_
 * - do not forget to add listeners for the wheel event to this elements as well
 * - the vertical hit area is extended by default which is not visible but aids interactions
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2745)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102414)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-scrollbar hitarea="minimal"
 *                orientation="horizontal"
 *                style="--zui-scrollbar-handle-size: 40%; --zui-scrollbar-handle-offset: 75%"
 * ></zui-scrollbar>
 *  ```
 * @cssprop --zui-scrollbar-background-color - sets the color of the scrollbar background
 * @cssprop --zui-scrollbar-background-opacity - sets opaqueness of the background, toggled by background visiblity by default
 * @cssprop --zui-scrollbar-handle-color - sets the color of the track handle
 * @cssprop --zui-scrollbar-handle-min-size - minimum size of the track handle
 * @cssprop --zui-scrollbar-hitarea-corner-size - dimensions of the quadratic corner between scrollbars
 * @cssprop --zui-scrollbar-hitarea-enlarge - enlargement of the hitarea, depends on hitarea attribute to be set to `enlarged`
 * @cssprop --zui-scrollbar-radius - corner radius of the track and handle
 * @cssprop --zui-scrollbar-track-color - background color of the track
 */
@customElement('zui-scrollbar')
export class Scrollbar extends BaseElement {
  static readonly styles = [hostStyles, SCROLLBAR_STYLES];

  /**
   * marks the scrollbars hitarea as colliding
   */
  @property({ reflect: true, type: Boolean })
  colliding = false;

  /**
   *  marks the scrollbars related axis to be currently scrolled
   */
  @property({ reflect: true, type: Boolean })
  scrolling = false;

  /**
   *  sets the scrollbar invisible
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * tells the scrollbar to be not alone
   */
  @property({ reflect: true, type: Boolean })
  multiple = false;

  /**
   * show the scrollbar hitboxes with a solid background-color on interactions
   */
  @property({ reflect: true, type: String })
  background: 'hidden' | 'visible' = 'hidden';

  /**
   * conditionally enlarge the vertical scrollbar to the left (is passed through to scrollbar component)
   */
  @property({ reflect: true, type: String })
  hitarea: 'enlarged' | 'minimal' = 'enlarged';

  /**
   * denes the orientation and implicitly the position as well
   */
  @property({ reflect: true, type: String })
  orientation: 'horizontal' | 'vertical' = 'vertical';

  /**
   * @param offset
   * @private
   */
  @event({ eventName: 'scrollbar', bubbles: true, composed: false })
  emitScrollbarEvent(offset: number): void {
    const detail = { offset, orientation: this.orientation };
    const scrollbarEvent: ScrollbarEvent = new CustomEvent('scrollbar', { detail });
    this.dispatchEvent(scrollbarEvent);
  }

  @query('.handle')
  private readonly _handleRef: HTMLDivElement;

  // we need to add and remove the methods as event listeners but bound to the scope
  // of this class, thus we have to create references that can be be reused safely
  /* eslint-disable lines-between-class-members */
  private readonly _draggingBound = this._dragging.bind(this);
  private readonly _jumpingBound = this._jumping.bind(this);
  private readonly _stopDragBound = this._stopDrag.bind(this);
  /* eslint-enable lines-between-class-members */

  // each time we start dragging we have to store the initial pointer offset
  private _initialOffset = 0;

  disconnectedCallback(): void {
    // we have to clean up when the component is removed
    this._stopDrag();
    this.removeEventListener('touchstart', this._jumpingBound, false);
    this.removeEventListener('mousedown', this._jumpingBound, false);

    super.disconnectedCallback();
  }

  // retrieves and stores initial pointer and scroll track position
  private _deriveInitialPositions(ev: MouseEvent | TouchEvent): void {
    const { clientX, clientY } = getCursorPosition(ev);
    const { left: x, top: y } = this._handleRef.getBoundingClientRect();
    if (this.orientation === 'horizontal') {
      this._initialOffset = clientX - x;
    } else {
      this._initialOffset = clientY - y;
    }
  }

  // starts a drag session
  private _startDrag(ev: MouseEvent | TouchEvent): void {
    // prevent scroll on pressed (middle) mouse button(s)
    // https://stackoverflow.com/a/30423436/1146207
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.stopPropagation();

    // the initial offset is required later to be subtracted while dragging
    this._deriveInitialPositions(ev);

    // add global listeners
    window.addEventListener('touchmove', this._draggingBound, false);
    window.addEventListener('mousemove', this._draggingBound, false);

    window.addEventListener('touchend', this._stopDragBound, false);
    window.addEventListener('mouseup', this._stopDragBound, false);

    // start dragging
    this._dragging(ev);
  }

  // ends the current drag session
  private _stopDrag(): void {
    // remove global event listeners
    window.removeEventListener('touchmove', this._draggingBound, false);
    window.removeEventListener('mousemove', this._draggingBound, false);

    window.removeEventListener('touchend', this._stopDragBound, false);
    window.removeEventListener('mouseup', this._stopDragBound, false);
  }

  // handles the scroll track movement whilst dragging
  private _dragging(ev: MouseEvent | TouchEvent): void {
    const { left: x, top: y, height, width } = this.getBoundingClientRect();
    const { clientX, clientY } = getCursorPosition(ev);

    // extract (inofficial) zoom CSS property
    let zoom = parseFloat(getComputedStyle(document.body).getPropertyValue('zoom'));
    // only firefox does not implement the zoom property, but it actually does
    // not require us to align as it scrolls properly when zoomed already...
    if (isNaN(zoom)) {
      zoom = 1;
    }
    // calculate the base when zoomed
    const base = 100 / zoom;

    let offset: number;
    if (this.orientation === 'horizontal') {
      offset = ((clientX - x - this._initialOffset) / width) * base;
    } else {
      offset = ((clientY - y - this._initialOffset) / height) * base;
    }

    this.emitScrollbarEvent(offset);
  }

  // handles the scroll track jumping to a specific position once
  private _jumping(ev: MouseEvent | TouchEvent): void {
    const { height, width } = this._handleRef.getBoundingClientRect();
    if (this.orientation === 'horizontal') {
      this._initialOffset = width / 2;
    } else {
      this._initialOffset = height / 2;
    }
    this._dragging(ev);
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    // everytime this component is clicked we want to scroll to that very position
    this.addEventListener('touchstart', this._jumpingBound, false);
    this.addEventListener('mousedown', this._jumpingBound, false);
  }

  protected render(): TemplateResult {
    return html` <div class="handle" @mousedown="${this._startDrag}" @touchstart="${this._startDrag}"></div> `;
  }
}
