import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS } from 'lit-element';
import { BaseElement } from '../base/BaseElement';

import { hostStyles } from '../../host.styles';
import style from './interactive-text.component.scss';

const interactiveTextStyles = css`
  ${unsafeCSS(style)}
`;

type Emphasis = 'default' | 'subtle' | 'highlight';

/**
 * The interactive text component behaves like a button. The content can be any text inside a wrapper (for example `span`).
 *
 * ## Figma
 * - [Styleguide - Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=7272%3A245618)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-interactive-text style="--zui-interactive-text-font: var(--zui-typography-label2);">
 *   <span style="text-transform: uppercase;">ZUi web component library</span>
 * </zui-interactive-text>
 * ```
 *
 * @slot default - text content
 *
 * @cssprop --zui-interactive-text-font - override default font of `var(--zui-typography-body)`
 * @cssprop --zui-interactive-text-hitarea-size - override default hitarea size of 8px
 * @cssprop --zui-interactive-text-padding-top-bottom - override default top and bottom padding of 0px
 * @cssprop --zui-interactive-text-padding-left-right - override default left and right padding of 0px
 */
@customElement('zui-interactive-text')
export class InteractiveText extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, interactiveTextStyles];
  }

  /**
   * emphasis
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  protected render(): TemplateResult | void {
    return html`<button ?disabled=${this.disabled}><slot></slot></button>`;
  }
}
