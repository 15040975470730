import { CSSResultArray, TemplateResult, customElement, html } from 'lit-element';
import { css, unsafeCSS } from 'lit-element/lib/css-tag';
import { BaseElement } from '../../base/BaseElement';
import { property } from 'lit-element/lib/decorators';

import { hostStyles } from '../../../host.styles';
import styles from './time-picker-cell-divider.component.scss';

const timePickerCellDividerComponentStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * Time picker cell divider.
 *
 * @example
 * html```
 * <zui-time-picker-cell-divider></zui-time-picker-cell-divider>
 * ```
 */
@customElement('zui-time-picker-cell-divider')
export class TimePickerCellDivider extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, timePickerCellDividerComponentStyles];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static COLON_SPACING = 10;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static COLON_CIRCLE_WIDTH = 3;

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * literal
   */
  @property({ reflect: true, type: String })
  literal = ':';

  private get _colonHeight(): number {
    return TimePickerCellDivider.COLON_SPACING + 2 * TimePickerCellDivider.COLON_CIRCLE_WIDTH;
  }

  private _getColonLiteral(): TemplateResult {
    return html`
      <svg width="${TimePickerCellDivider.COLON_CIRCLE_WIDTH}" height="${this._colonHeight}">
        <circle
          cx="${TimePickerCellDivider.COLON_CIRCLE_WIDTH / 2}"
          cy="${TimePickerCellDivider.COLON_CIRCLE_WIDTH}"
          r="${TimePickerCellDivider.COLON_CIRCLE_WIDTH / 2}"
        />
        <circle
          cx="${TimePickerCellDivider.COLON_CIRCLE_WIDTH / 2}"
          cy="${this._colonHeight - TimePickerCellDivider.COLON_CIRCLE_WIDTH}"
          r="${TimePickerCellDivider.COLON_CIRCLE_WIDTH / 2}"
        />
      </svg>
    `;
  }

  protected render(): TemplateResult {
    switch (this.literal) {
      case ':':
        return this._getColonLiteral();
      default:
        return this._getColonLiteral();
    }
  }
}
