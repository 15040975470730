import { CSSResultArray, TemplateResult, customElement, html, property } from 'lit-element';
import { css, unsafeCSS } from 'lit-element/lib/css-tag';
import { event } from '../../../decorators/event.decorator';
import { BaseElement } from '../../base/BaseElement';
import { EventWithTarget } from '../../../types';
import { addLeadingZeros } from '../../date-picker/utils/date-picker-input.utils';
import { query } from 'lit-element/lib/decorators';
import { clamp, cycle, isDefined } from '../../../utils/component.utils';

import styles from './time-picker-cell.component.scss';
import { hostStyles } from '../../../host.styles';

const timePickerCellComponentStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The `zui-time-picker-cell` is part of the `zui-time-picker` and is meant for displaying and editing hours and minutes.
 *
 * @example
 * html```
 * <zui-time-picker-cell default-value="12" max="23" min="0"></zui-time-picker-cell>
 * ```
 *
 * @fires CustomEvent - the `time-picker-cell-changed` event is emitted on input blur or when the value is decreased or increased via one of the buttons
 * @fires CustomEvent - the `time-picker-cell-input` event is emitted when there is an interaction with the input element and the input value is between the min and max value
 */
@customElement('zui-time-picker-cell')
export class TimePickerCell extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, timePickerCellComponentStyles];
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  static MAX_CHARACTER_LENGTH = 2;

  /**
   * default value
   */
  @property({ reflect: true, type: Number, attribute: 'default-value' })
  defaultValue = 0;

  /**
   * disabled
   *
   * @private
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * max
   */
  @property({ reflect: true, type: Number })
  max = 0;

  /**
   * min
   */
  @property({ reflect: true, type: Number })
  min = 0;

  /**
   * step size
   *
   * @private
   */
  @property({ reflect: true, type: Number })
  step = 1;

  /**
   * value
   */
  @property({ reflect: true, type: Number })
  value: number;

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-cell-changed',
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitTimePickerCellChangedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-cell-changed', {
        bubbles: true,
        cancelable: false,
        composed: true,
        detail: {
          value: this.value,
        },
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-cell-input',
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitTimePickerCellInputEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-cell-input', {
        bubbles: true,
        cancelable: false,
        composed: false,
        detail: {
          value: parseInt(this._input.value),
        },
      })
    );
  }

  @query('input')
  private _input: HTMLInputElement;

  private get _paddedValue(): string | null {
    if (isDefined(this.value)) {
      return addLeadingZeros(this.value, TimePickerCell.MAX_CHARACTER_LENGTH);
    }

    return null;
  }

  private get _currentValue(): number {
    return this.value ?? this.defaultValue;
  }

  private get _currentInputValue(): number {
    return this._input.value !== '' ? Number(this._input.value) : this.defaultValue;
  }

  private _getDecreasedValue(value: number): number {
    return cycle(value, this.min, this.max, this.step, 'decrease');
  }

  private _getIncreasedValue(value: number): number {
    return cycle(value, this.min, this.max, this.step, 'increase');
  }

  private _decreaseValue(): void {
    if (isDefined(this.value)) {
      this.value = this._getDecreasedValue(this._currentValue);
    } else {
      this.value = this._currentValue;
    }

    this.emitTimePickerCellChangedEvent();
  }

  private _increaseValue(): void {
    if (isDefined(this.value)) {
      this.value = this._getIncreasedValue(this._currentValue);
    } else {
      this.value = this._currentValue;
    }

    this.emitTimePickerCellChangedEvent();
  }

  private _handleInputEvent(): void {
    if (this._input.value.length > TimePickerCell.MAX_CHARACTER_LENGTH) {
      this._input.value = this._input.value.slice(0, TimePickerCell.MAX_CHARACTER_LENGTH);
    }

    if (this._input.value !== '') {
      this._input.value = String(clamp(this.min, Number(this._input.value), this.max));
    }

    this.emitTimePickerCellInputEvent();
  }

  private _handleInputBlurEvent(): void {
    const oldValue = this.value;
    this.value = this._currentInputValue;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because TS does not no nothing about the DOM
    this._input.value = this._paddedValue;

    if (oldValue !== this.value) {
      this.emitTimePickerCellChangedEvent();
    }
  }

  private _handleInputClickEvent(): void {
    if (!isDefined(this.value)) {
      this._input.value = addLeadingZeros(this.defaultValue, TimePickerCell.MAX_CHARACTER_LENGTH);
    }
  }

  private _handleInputKeydownEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    switch (event.key) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
      case '0':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Backspace':
      case 'Delete':
      case 'Tab':
        break;
      case 'ArrowUp':
        {
          event.preventDefault();
          event.target.value = String(this._getIncreasedValue(this._currentInputValue));

          this.emitTimePickerCellInputEvent();
        }
        break;
      case 'ArrowDown':
        {
          event.preventDefault();
          event.target.value = String(this._getDecreasedValue(this._currentInputValue));

          this.emitTimePickerCellInputEvent();
        }
        break;
      default:
        event.preventDefault();
        break;
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-interactive-icon
        emphasis="subtle"
        id="increase"
        ?disabled="${this.disabled}"
        @click="${this._increaseValue}"
      >
        <zui-icon-arrow-outline-arrow-outline-actually-centred-up></zui-icon-arrow-outline-arrow-outline-actually-centred-up>
      </zui-interactive-icon>
      <input
        .value="${this._paddedValue}"
        ?disabled="${this.disabled}"
        max="${this.max}"
        min="${this.min}"
        step="${this.step}"
        type="number"
        @blur="${this._handleInputBlurEvent}"
        @click="${this._handleInputClickEvent}"
        @input="${this._handleInputEvent}"
        @keydown="${this._handleInputKeydownEvent}"
      />
      <zui-interactive-icon
        emphasis="subtle"
        id="decrease"
        ?disabled="${this.disabled}"
        @click="${this._decreaseValue}"
      >
        <zui-icon-arrow-outline-arrow-outline-actually-centred-down></zui-icon-arrow-outline-arrow-outline-actually-centred-down>
      </zui-interactive-icon>
    `;
  }
}
