// TODO: this needs to fix value logic -> https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/662084
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS, property } from 'lit-element';
import { queryAssignedNodes } from 'lit-element/lib/decorators';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import {
  isElementAfterOther,
  isElementBeforeOther,
  traverseDOMSiblingsByStepAndDirection,
} from '../../../utils/dom.utils';
import { stringUndefinedConverter } from '../../../utils/component.utils';

import { StepIndicatorItem } from '../step-indicator-item/step-indicator-item.component';

import style from './step-indicator-bar.component.scss';

const stepIndicatorBarStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The `zui-step-indicator-bar` shows a list of `zui-step-indicator-item`'s and can be used as a progress indicator.
 * Each step has an emphasis of visited, selected or next.
 * To advance one step or step back one step you can call `next()` and `previous()`.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.2?node-id=76965%3A368673)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=45219%3A117)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-step-indicator-bar size="l" value="two">
 *   <zui-step-indicator-item text="One" value="one"></zui-step-indicator-item>
 *   <zui-step-indicator-item text="Two" value="two"></zui-step-indicator-item>
 *   <zui-step-indicator-item text="Three" value="three"></zui-step-indicator-item>
 *   <zui-step-indicator-item text="Four" value="four"></zui-step-indicator-item>
 * </zui-step-indicator-bar>
 * ```
 */
@customElement('zui-step-indicator-bar')
export class StepIndicatorBar extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, stepIndicatorBarStyles];
  }

  /**
   * size that is propagated to each `zui-step-indicator-item`
   */
  @property({ reflect: true, type: String })
  size: StepIndicatorItem['size'] = 'm';

  /**
   * (optional) value of the selected `zui-step-indicator-item`; has to be set when there is an item that should be selected on init
   */
  @property({ reflect: true, converter: stringUndefinedConverter })
  value: string | undefined;

  @queryAssignedNodes('', true, 'zui-step-indicator-item')
  private _assignedStepIndicatorItems: StepIndicatorItem[];

  private get _selectedStepIndicatorItem(): StepIndicatorItem | undefined {
    return this.value !== undefined
      ? this._assignedStepIndicatorItems.find((item) => item.value === this.value)
      : undefined;
  }

  private get _hasSelectedStepIndicatorItem(): boolean {
    return this._selectedStepIndicatorItem !== undefined;
  }

  /**
   * advance one step when possible and update `zui-step-indicator-item`'s emphasis accordingly
   */
  next(): void {
    if (this._hasSelectedStepIndicatorItem) {
      this.value = this._getSibling('next')?.value ?? this.value;
    } else {
      const [firstItem] = this._assignedStepIndicatorItems;
      this.value = firstItem?.value ?? this.value;
    }
  }

  /**
   * take a step back when possible and update `zui-step-indicator-item`'s emphasis accordingly
   */
  previous(): void {
    if (this._hasSelectedStepIndicatorItem) {
      this.value = this._getSibling('previous')?.value ?? this.value;
    }
  }

  private _getSibling(direction: 'next' | 'previous'): StepIndicatorItem | null {
    return traverseDOMSiblingsByStepAndDirection(this._selectedStepIndicatorItem, direction) as StepIndicatorItem;
  }

  private _propagateSizeChange(): void {
    this._assignedStepIndicatorItems.forEach((item) => (item.size = this.size));
  }

  private _propagateValueChange(): void {
    if (this._hasSelectedStepIndicatorItem) {
      this._assignedStepIndicatorItems.forEach((stepIndicatorItem) => {
        if (isElementBeforeOther(stepIndicatorItem, this._selectedStepIndicatorItem)) {
          stepIndicatorItem.emphasis = 'visited';
        } else if (isElementAfterOther(stepIndicatorItem, this._selectedStepIndicatorItem)) {
          stepIndicatorItem.emphasis = 'next';
        } else if (stepIndicatorItem === this._selectedStepIndicatorItem) {
          stepIndicatorItem.emphasis = 'selected';
        }
      });
    } else {
      this._assignedStepIndicatorItems.forEach((stepIndicatorItem) => (stepIndicatorItem.emphasis = 'next'));
    }
  }

  private _handleSlotChange(): void {
    this._propagateSizeChange();
    this._propagateValueChange();
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('size')) {
      this._propagateSizeChange();
    }

    if (changedProperties.has('value')) {
      this._propagateValueChange();
    }
  }

  protected render(): TemplateResult {
    return html`<slot @slotchange="${this._handleSlotChange}"></slot>`;
  }
}
