import {
  css,
  customElement,
  html,
  property,
  PropertyValues,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import type { EventWithTarget } from '../../../types';
import { BaseElement } from '../../base/BaseElement';

import { hostStyles } from '../../../host.styles';
import style from './menu-item.component.scss';
import { nothing } from 'lit-html';

type EmphasisDeprecated = 'active' | 'active-primary';
type Emphasis = 'default' | 'selected' | 'selected-primary' | EmphasisDeprecated;
type Size = 's' | 'l';

const MENU_ITEM_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * Internal UI component for menu items. It is primarily used by the `zui-select` feature component and mostly encapsulates
 * styling concerns and not that much logic.
 *
 * ## Functionality
 * Displays a checkbox if marked as `selectable`, replacing eventually passed-in icons.
 * It reflects its aligned size to optionally projected icons.
 *
 * ## Checkbox usage
 * Currently, the checkbox's `value` state is intended to be set declaratively by the menu-items `selected` state. The checkbox's `value` is *not* reflected back to the menu-item's `selected` state.
 * So, to implement the `<zui-menu-item selectable>` correctly, you have to listen to its `click` event and manually update its `selected` state in some way.
 *
 * @example
 * ```HTML
 * <zui-menu-item disabled>
 *   Item label
 *   <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 * </zui-menu-item>
 * ```
 *
 *
 * @fires click - (and other native ui events like `hover`, `focus`, ..) - *except* it is `disabled` (`pointer-events: none`)
 * @slot - default slot for an item label
 * @slot icon - allows optionally passing an icon which may be replaced by a checkbox if flagged as `selectable`
 * @cssprop --zui-menu-item-background - color of the item background, derived from emphasis attribute by default
 * @cssprop --zui-menu-item-color - color of the item typo, derived from emphasis attribute by default
 * @cssprop --zui-menu-item-font - font family of the item, derived from emphasis attribute by default
 * @cssprop --zui-menu-item-height - size of the item, derived from size attribute by default
 */
@customElement('zui-menu-item')
export class MenuItem extends BaseElement {
  static readonly styles = [hostStyles, MENU_ITEM_STYLES];

  /**
   * the tabindex of the menuItem;
   * defaults to -1, because keyboardNavigation is done imperatively
   */
  @property({ reflect: true, type: String })
  tabindex = -1;

  /**
   * enforces the "role" attribute for a11y reasons
   */
  @property({ reflect: true, type: String })
  role = 'menuitem';

  /**
   * toggles a checkbox (which will replace eventually defined icons)
   */
  @property({ reflect: true, type: Boolean })
  selectable = false;

  /**
   * marks the item as selected
   */
  @property({ reflect: true, type: Boolean })
  selected = false;

  /**
   * the emphasis of the menu item
   * the deprecated emphasis "active" and "active-primary" were renamed to "selected" and "selected-primary".
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * the size is derived from the touch environment initially if not provided
   */
  @property({ reflect: true, type: String })
  size: Size = this.hasTouch ? 'l' : 's';

  /**
   * the value of the menu item
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * a right aligned description or hint
   */
  @property({ reflect: true, type: String })
  description = '';

  /**
   * sets the menu item visually as disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * disables truncation and grows the item to its contents
   */
  @property({ reflect: true, type: Boolean, attribute: 'disable-truncation' })
  disableTruncation = false;

  @queryAssignedNodes('icon', true, '[zuiIcon]')
  private _iconSlotNodes: HTMLElement[];

  // setup keydown listener to simulate click; for this we need w/o a wrapper element, sadly
  // TODO: this should be a mixin

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('keydown', this._handleKeyDownEvent);
    this.addEventListener('click', this._handleClick);
  }

  disconnectedCallback(): void {
    this.removeEventListener('keydown', this._handleKeyDownEvent);
    this.removeEventListener('click', this._handleClick);
    super.disconnectedCallback();
  }

  private _handleClick(ev: Event): void {
    if (this.disabled) {
      ev.stopImmediatePropagation();
      ev.stopPropagation();
      ev.preventDefault();
    }
  }

  // aligns slotted icon sizes
  private _updateIconSize(): void {
    this._iconSlotNodes.forEach((element) => {
      element.setAttribute('size', this.size === 's' ? 'm' : 'l');
    });
  }

  // map 'Enter', 'Space' to 'click' event (when component is focused)
  private _handleKeyDownEvent({ code }: EventWithTarget<Element, KeyboardEvent>): void {
    if (this.disabled) {
      return;
    }
    switch (code) {
      case 'Enter':
      case 'Space':
        this.click();
        break;
    }
  }

  /**
   * Detects if one of the deprecated emphasis states was used
   *
   * @param {Map<string,unknown>} changedProperties properties which have been changed
   */
  protected update(changedProperties: Map<string, unknown>): void {
    super.update(changedProperties);
    if (changedProperties.has('emphasis')) {
      if (this.emphasis === 'active' || this.emphasis === 'active-primary') {
        console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-menu-item.`);
      }
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    // if "selectable" is true, no icon slot is rendered and therefore icon-update is not possible
    if (changedProperties.has('size') && !this.selectable) {
      this._updateIconSize();
    }
  }

  protected render(): TemplateResult {
    return html`
      ${this.selectable
        ? html`<zui-checkbox
            class="checkbox"
            tabindex="-1"
            value="${this.selected}"
            ?disabled="${this.disabled}"
          ></zui-checkbox>`
        : html`<slot name="icon" @slotchange="${this._updateIconSize}"></slot>`}
      <div class="content">
        <span class="label">
          <slot></slot>
        </span>
        ${this.description.length > 0 ? html`<span class="description">${this.description}</span>` : nothing}
      </div>
    `;
  }
}
