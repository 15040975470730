import {
  css,
  CSSResult,
  customElement,
  html,
  property,
  PropertyValues,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';

import styles from './title-stack.component.scss';
import { TitleStackMetaItem } from '../title-stack-meta-item/title-stack-meta-item.component';

type TitleStackSize = 'xs' | 's' | 'm' | 'l';

const titleStackStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * A “title stack” is a recurring pattern / stack of texts.
 *
 * @example
 * HTML:
 *
 * A title stack with two meta items
 * ```html
 * <zui-title-stack label="Label" size="s">
 *   <h1>Name or Headline</h1>
 *   <zui-icon-volume slot="icon"></zui-icon-volume>
 *   <zui-title-stack-meta-item slot="meta">Caption 1</zui-title-stack-meta-item>
 *   <zui-title-stack-meta-item slot="meta">Caption 2</zui-title-stack-meta-item>
 * </zui-title-stack>
 * ```
 *
 * @slot default - This is the default slot which should reflect the headline element.
 * @slot meta - Meta data items shall be reflected into this slot. The order is preserved.
 */
@customElement('zui-title-stack')
export class TitleStack extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, titleStackStyles];
  }

  /**
   * Sets the size of the title stack
   */
  @property({ reflect: true })
  size: TitleStackSize = 'm';

  /**
   * An optional label to be set above
   */
  @property({ reflect: true, type: String })
  label = '';

  /**
   * **deprecated** use disable-wrapping instead, which is this attribute inverted.
   * @deprecated
   */
  @property({ reflect: true, type: Boolean })
  get wrapping() {
    return !this.disableWrapping;
  }

  set wrapping(value: boolean) {
    this.disableWrapping = !value;
  }

  /**
   * If true it truncates the label, headline and meta data items if they are too long or too many.
   * If false, they get wrapped to new lines.
   */
  @property({ reflect: true, type: Boolean, attribute: 'disable-wrapping' })
  disableWrapping = false;

  @queryAssignedNodes('icon', true, '[zuiIcon]')
  private readonly _assignedIcons: HTMLElement[];

  @queryAssignedNodes('meta', true, 'zui-title-stack-meta-item')
  private readonly _assignedMetaItems: TitleStackMetaItem[];

  private get _hasIcons(): boolean {
    return this._assignedIcons.length > 0;
  }

  private get _hasMetaItems(): boolean {
    return this._assignedMetaItems.length > 0;
  }

  private _alignAssignedIcons(): void {
    // force the correct size to all projected icons
    if (this._hasIcons) {
      this._assignedIcons.forEach((icon) => icon.setAttribute('size', 's'));
    }

    // as the icons could have been removed or added,
    // we may have to align the first meta item
    this._toggleFirstMetaItemSeparator();
  }

  private _alignAssignedMetaItems(): void {
    // set the current size to all meta items
    if (this._hasMetaItems) {
      this._assignedMetaItems.forEach((item) => (item.size = this.size));
    }

    // depending of having an icon assigned,
    // we may have to align the first meta item
    this._toggleFirstMetaItemSeparator();
  }

  // align the first items separator attribute if no icon is in front of it
  private _toggleFirstMetaItemSeparator(): void {
    if (this._hasMetaItems) {
      this._assignedMetaItems[0].hideSeparator = !this._hasIcons;
    }
  }

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);
    if (_changedProperties.get('size')) {
      this._alignAssignedMetaItems();
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class="label" aria-role="label">${this.label}</div>
      <slot></slot>
      <div class="meta">
        <slot name="icon" @slotchange="${this._alignAssignedIcons}"></slot>
        <slot name="meta" @slotchange="${this._alignAssignedMetaItems}"></slot>
      </div>
    `;
  }
}
