import { CSSResultArray, TemplateResult, customElement, property, css, unsafeCSS, html } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { event } from '../../../decorators/event.decorator';

import { hostStyles } from '../../../host.styles';
import styles from './multi-item-slider-pagination.component.scss';

import '../../interactive-icon/interactive-icon.component';
import '../../pagination/pagination-dot-bar/pagination-dot-bar.component';

const MULTI_ITEM_SLIDER_PAGINATION_STYLES = css`
  ${unsafeCSS(styles)}
`;

/**
 * A pagination used by the multi item slider internally.
 * It's composed of the pagination dot bar and navigation
 * buttons to each side for paging back- and forward.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-multi-item-slider-pagination count="5" selected-item-index="2"></zui-multi-item-slider-pagination>
 * ```
 * @cssprop --zui-multi-item-slider-pagination-animation-duration - animation duration of the used pagination dots
 * @fires {CustomEvent<{ value: number }>} multi-item-slider-pagination-page-selected - emits when a page is selected
 */
@customElement('zui-multi-item-slider-pagination')
export class MultiItemSliderPagination extends BaseElement {
  static readonly styles: CSSResultArray = [hostStyles, MULTI_ITEM_SLIDER_PAGINATION_STYLES];

  /**
   * Amount of items to be pageable
   */
  @property({ reflect: true, attribute: 'item-count', type: Number })
  itemCount = 1;

  /**
   * Currently selected zero based item index
   */
  @property({ reflect: true, attribute: 'selected-item-index', type: Number })
  selectedItemIndex = 0;

  /**
   * Emits a custom multi-item-slider-pagination-page-selected event when a page is selected
   *
   * @param pageIndex zero based index of the selected page
   *
   * @private
   */
  @event({ eventName: 'multi-item-slider-pagination-page-selected', bubbles: true, composed: true })
  emitMultiItemSliderPageSelected(pageIndex: number): void {
    this.dispatchEvent(
      new CustomEvent('multi-item-slider-pagination-page-selected', {
        bubbles: true,
        composed: true,
        detail: { value: pageIndex },
      })
    );
  }

  private _handlePreviousClicked(): void {
    this.selectedItemIndex--;
    this.emitMultiItemSliderPageSelected(this.selectedItemIndex);
  }

  private _handleNextClicked(): void {
    this.selectedItemIndex++;
    this.emitMultiItemSliderPageSelected(this.selectedItemIndex);
  }

  private _handleDotSelected({ detail: { value } }: CustomEvent<{ value: number }>): void {
    this.selectedItemIndex = value;
    this.emitMultiItemSliderPageSelected(this.selectedItemIndex);
  }

  protected render(): TemplateResult {
    return html`
      <zui-interactive-icon ?disabled="${this.selectedItemIndex === 0}" @click="${this._handlePreviousClicked}">
        <zui-icon-arrow-outline-arrow-outline-visually-centered-left></zui-icon-arrow-outline-arrow-outline-visually-centered-left>
      </zui-interactive-icon>

      <zui-pagination-dot-bar
        class="pages"
        item-count="${this.itemCount}"
        selected-item-index="${this.selectedItemIndex}"
        @pagination-dot-bar-pagination-dot-selected="${this._handleDotSelected}"
      >
      </zui-pagination-dot-bar>

      <zui-interactive-icon
        ?disabled="${this.selectedItemIndex === this.itemCount - 1}"
        @click="${this._handleNextClicked}"
      >
        <zui-icon-arrow-outline-arrow-outline-visually-centered-right></zui-icon-arrow-outline-arrow-outline-visually-centered-right>
      </zui-interactive-icon>
    `;
  }
}
