import debounce from 'lodash-es/debounce';
import {
  CSSResultArray,
  TemplateResult,
  customElement,
  property,
  css,
  unsafeCSS,
  html,
  queryAssignedNodes,
  state,
  query,
  PropertyValues,
} from 'lit-element';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';

import { animateWithCSS } from '../../../utils/animation.utils';
import { BaseElement } from '../../base/BaseElement';

import { hostStyles } from '../../../host.styles';
import styles from './multi-item-slider.component.scss';

const multiItemSliderStyles = css`
  ${unsafeCSS(styles)}
`;

import type { MultiItemSliderItem } from '../multi-item-slider-item/multi-item-slider-item.component';

import '../multi-item-slider-item/multi-item-slider-item.component';
import '../multi-item-slider-pagination/multi-item-slider-pagination.component';

/**
 * Allows showing multiple items with arbitrary contents in a horizontal slider.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-multi-item-slider gap-size="l" group-size="3">
 *   <zui-multi-item-slider-item>
 *     <img src="/some/image/source-1" alt="Image 1" />
 *   </zui-multi-item-slider-item>
 *   <zui-multi-item-slider-item>
 *     <img src="/some/image/source-2" alt="Image 2" />
 *   </zui-multi-item-slider-item>
 *   <zui-multi-item-slider-item>
 *     <div>
 *       <h2>Mixed content</h2>
 *       <p>is possible as well</p>
 *     </div>
 *   </zui-multi-item-slider-item>
 * </zui-multi-item-slider>
 * ```
 *
 * @slot default - Default slot accepts zui-multi-item-slider-item elements to be shown in columns
 *
 * @cssprop --zui-multi-item-slider-animation-duration - Duration of the item animation
 * @cssprop --zui-multi-item-slider-animation-duration-pagination - Duration of the used pagination dot animation
 * @cssprop --zui-multi-item-slider-animation-easing - Easing timing function of the item animation
 * @cssprop --zui-multi-item-slider-pagination-spacing - Distance of the slider items to the pagination
 * @cssprop --zui-multi-item-slider-item-gap-size - Gap size of the items, defaults to `var(--zui-space-medium)` and can be `var(--zui-space-small)`, `var(--zui-space-large)` or a `px` value (e.g. `16px`)
 */
@customElement('zui-multi-item-slider')
export class MultiItemSlider extends BaseElement {
  /* eslint-disable @typescript-eslint/naming-convention */
  static readonly MULTI_ITEM_SLIDER_ANIMATION_DURATION = '--zui-multi-item-slider-animation-duration';
  static readonly MULTI_ITEM_SLIDER_ANIMATION_EASING = '--zui-multi-item-slider-animation-easing';
  static readonly MULTI_ITEM_SLIDER_GROUP_SIZE_CSS_PROPERTY = '---zui-multi-item-slider-group-size';
  /* eslint-enable @typescript-eslint/naming-convention */

  static readonly styles: CSSResultArray = [hostStyles, multiItemSliderStyles];

  /**
   * Amount of visible elements
   */
  @property({ reflect: true, attribute: 'group-size', type: Number })
  groupSize = 4;

  /**
   * Allows hiding the pagination
   */
  @property({ reflect: true, attribute: 'hide-pagination', type: Boolean })
  hidePagination = false;

  private get _isAnimating(): boolean {
    return this._currentAnimation?.playState === 'running';
  }

  private get _pageCount(): number {
    return Math.ceil(this._itemRefs?.length / this.groupSize);
  }

  private get _selectedPageIndex(): number {
    const targetPage = Math.floor(this._selectedItemIndex / this.groupSize);
    const maxPage = Math.floor(this._itemRefs?.length / this.groupSize);
    return Math.min(targetPage, maxPage);
  }

  @query('.items')
  private readonly _itemsRef: HTMLDivElement;

  @queryAssignedNodes('', true, 'zui-multi-item-slider-item')
  private readonly _itemRefs: MultiItemSliderItem[];

  @state()
  private _isSnapping = true;

  // the currently first visible item index
  @state()
  private _selectedItemIndex = 0;

  // stores currently running animation context
  private _currentAnimation?: Animation;

  // after resizing we might have to align the scroll offset
  private readonly _resizeObserver = new ResizeObserver(debounce(this._handleResize.bind(this), 200));

  // while scrolling we want to update the selected page
  private readonly _scrollListener = debounce(this._handleScroll.bind(this), 20);

  // clean up once removed from DOM
  disconnectedCallback(): void {
    this._resizeObserver.disconnect();
    super.disconnectedCallback();
  }

  private _handleSlotChange(): void {
    // the amount of slotted items may have been changed,
    // thus we have to check for altered page count
    this.requestUpdate();
  }

  private _handlePageSelected({ detail: { value: pageIndex } }: CustomEvent<{ value: number }>): void {
    // update current indices
    const targetItemIndex = pageIndex * this.groupSize;
    const overhangItems = this._itemRefs.length % this.groupSize;
    const overhangAdjust = overhangItems > 0 ? this.groupSize - overhangItems : 0;
    const maxPageIndex = this._pageCount - 1;
    const maxItemIndex = maxPageIndex * this.groupSize - overhangAdjust;
    const nextItemIndex = Math.min(targetItemIndex, maxItemIndex);

    // update state
    this._selectedItemIndex = nextItemIndex;

    // animated scrolling
    this._animateToItem(this._selectedItemIndex);
  }

  // is called on every scroll event nevertheless its caused by the user or by an animation
  private _handleScroll(): void {
    // do not align selected item if animation is in progress
    if (!this._isAnimating) {
      // determine selected item from closest offset
      this._selectedItemIndex = this._getClosestItemIndexToOffset(this._itemsRef.scrollLeft);
    }
  }

  private _handleResize(): void {
    // abort eventually running scroll animations
    this._currentAnimation?.cancel();

    // scroll back to the selected item
    const selectedItem = this._itemRefs[this._selectedItemIndex];
    this._itemsRef.scrollLeft = this._getItemOffset(selectedItem);
  }

  // is called only if the user interacts to scroll the items
  private _handleInteractiveScroll(): void {
    // abort eventually running scroll animations
    this._currentAnimation?.cancel();
    // allow snapping
    this._isSnapping = true;
  }

  // animates to a specific page
  private _animateToItem(index: number): Promise<Animation> {
    const to = this._getItemOffset(this._itemRefs[index]);
    const from = this._itemsRef.scrollLeft;
    const delta = to - from;

    // stop eventually running animations
    this._currentAnimation?.cancel();

    // prevent interruptions from css snapping
    this._isSnapping = false;

    // scroll to item at selected page
    this._currentAnimation = animateWithCSS(
      `var(${MultiItemSlider.MULTI_ITEM_SLIDER_ANIMATION_EASING})`,
      `var(${MultiItemSlider.MULTI_ITEM_SLIDER_ANIMATION_DURATION})`,
      (step) => (this._itemsRef.scrollLeft = from + delta * step),
      { host: this, autostart: true }
    );

    return this._currentAnimation.finished;
  }

  // delivers the index of the item closest to the given offset
  // s. https://stackoverflow.com/a/19277804
  private _getClosestItemIndexToOffset(offset: number): number {
    const itemOffsets = this._itemRefs.map((item) => this._getItemOffset(item));
    const closestOffset = itemOffsets.reduce((a, b) => (Math.abs(b - offset) < Math.abs(a - offset) ? b : a));
    return itemOffsets.indexOf(closestOffset);
  }

  // as we can not just use `offsetLeft` (the offset parent isn't available through
  // shadow DOM), we have to come up with some magic calculating it by ourselves
  private _getItemOffset(item: MultiItemSliderItem): number {
    const parentOffsetLeft = this._itemsRef.getBoundingClientRect().left;
    const parentScrollLeft = this._itemsRef.scrollLeft;
    const offsetLeft = item.getBoundingClientRect().left - parentOffsetLeft + parentScrollLeft;
    return Math.floor(offsetLeft);
  }

  // start watching for resizes once initialized
  protected firstUpdated(changedProperties: PropertyValues): void {
    super.firstUpdated(changedProperties);
    this._resizeObserver.observe(this._itemsRef);
  }

  protected render(): TemplateResult {
    return html`
      <div
        class="${classMap({ items: true, snapping: this._isSnapping })}"
        style="${styleMap({ [MultiItemSlider.MULTI_ITEM_SLIDER_GROUP_SIZE_CSS_PROPERTY]: `${this.groupSize}` })}"
        @touchstart="${this._handleInteractiveScroll}"
        @wheel="${this._handleInteractiveScroll}"
        @scroll="${this._scrollListener}"
      >
        <slot @slotchange="${this._handleSlotChange}"></slot>
      </div>

      ${!this.hidePagination && this._pageCount > 1
        ? html`
            <nav>
              <zui-multi-item-slider-pagination
                selected-item-index="${this._selectedPageIndex}"
                item-count="${this._pageCount}"
                @multi-item-slider-pagination-page-selected="${this._handlePageSelected}"
              ></zui-multi-item-slider-pagination>
            </nav>
          `
        : nothing}
    `;
  }
}
