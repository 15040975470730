import {
  css,
  customElement,
  property,
  PropertyValues,
  query,
  queryAssignedNodes,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { html } from 'lit-html';

import type { Position } from '../../types';
import type { Scrollbar, ScrollbarEvent } from '../../components/scrollbar/scrollbar.component';

import { BaseElement } from '../../components/base/BaseElement';

import style from './scrollable.directive.scss';

const SCROLLABLE_DIRECTIVE_STYLES = css`
  ${unsafeCSS(style)}
`;
const SCROLLING_TIMEOUT = 1000;

/**
 * The scrollable directive is a feature component with limited UI implementation. It uses the low-level UI component `zui-scrollbar`
 * internally.
 * The `background` and `hitarea` are passed through directly, visit the docs of the `zui-scrollbar` for futher informations.
 *
 * ## Functionality
 * This component is meant to be _wrapped around_ the scroll target to apply the custom ZUi scrollbars and behavior. It will
 * not break the browsers default scroll behavior but replaces the native scrollbars with custom implemented `zui-scrollbar`
 * UI components. Those components are managed to visualize the available scroll space and the scroll progress.
 *
 * ## Features
 * - it automatically detects if scrollbars are necessary at all and toggles them for each axis accordingly
 * - adds a corner square between two scrollbars to conduct scrollbar appearance and capture wheel events there as well
 * - listens to wheel events on scrollbars to simulate mouse wheel scrolling on target by keeping the custom scrollbar drag\
 *   and click behavior
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2745)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102414)
 *
 *
 * @example
 *
 * ### Basic usage
 * ```HTML
 * <zui-scrollable-directive style="--zui-scrollable-max-height: 400px; width: 100%;">
 *   <p>Lorem ipsum dolor</p>
 *   <p>sit amet,</p>
 *   <p>consetetur sadipscing</p>
 *   <p>elitr, sed...</p>
 * </zui-scrollable-directive>
 * ```
 *
 * <br />
 *
 * ### Size constraints on `<zui-scrollable-directive>`
 *
 * You can constrain both the scrollable's width and its height with to a fixed size or a maximum size (responsive).
 * Both absolute and relative units are possible, and you can choose between custom and standard css properties.
 *
 * If you want to use reponsive width, don't forget to override the default `width: auto` with `width: -moz-fit-content; width: fit-content`.
 *
 * |           |        |                                        | custom css props                      | standard css props   |
 * | --------- | ------ | -------------------------------------- | ------------------------------------- | -------------------- |
 * | reponsive | height | absolute                               | `--zui-scrollable-max-height: 400px;` | `max-height: 400px;` |
 * |           |        | relative (to parent with fixed height) | `--zui-scrollable-max-height: 100%;`  | `max-height: 100%;`  |
 * |           |        |                                        |                                       |                      |
 * |           | width  | absolute                               | `--zui-scrollable-max-width: 400px;`  | `max-width: 400px;`  |
 * |           |        | relative (to parent with fixed width)  | `--zui-scrollable-max-width: 100%`    | `max-width: 100%;`   |
 * |           |        |                                        |                                       |                      |
 * | fixed     | height | absolute                               | `--zui-scrollable-height: 400px;`     | `height: 400px;`     |
 * |           |        | relative (to parent with fixed height) | `--zui-scrollable-height: 100%;`      | `height: 100%;`      |
 * |           |        |                                        |                                       |                      |
 * |           | width  | absolute                               | `--zui-scrollable-width: 400px;`      | `width: 400px;`      |
 * |           |        | relative (to parent with fixed width)  | `--zui-scrollable-width: 100%;`       | `width: 100%;`       |
 *
 *
 *
 * Besides these basic constraints, you can of course use `<zui-scrollable-directive>` as a **CSS Flex Item** (don't forget `min-width:0;` or `min-height:0;` respectively !!) ...
 *
 * ```HTML
 * <div style="width: 300px; height: 300px; display: flex; flex-flow: column;">
 *   <h2>Heading</h2>
 *   <zui-scrollable-directive style="flex-basis: 0; flex-grow: 1; min-height: 0;" >
 *     <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
 *   </zui-scrollable-directive>
 * </div>
 * ```
 *
 * ... or as a **CSS Grid Item** (don't forget `min-width:0;min-height:0;`) ...
 *
 * ```HTML
 * <div style="width: 300px; height: 300px; display: grid;">
 *   <h2>Heading</h2>
 *   <zui-scrollable-directive style="min-width: 0; min-height: 0;">
 *     <p style="width: 500px;">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
 *   </zui-scrollable-directive>
 * </div>
 * ```
 *
 * <br />
 *
 * ### Relative slot content height
 * If the `<zui-scrollable-directive>` has a fixed height in some way, you can set relative heights on your slot content, as well.
 *
 * ```HTML
 * <zui-scrollable-directive
 *   style="--zui-scrollable-height: 400px;"
 * >
 *   <div style="height: 100%; background-color: teal"></div>
 *   <div style="height: 100%; background-color: navy"></div>
 * </zui-scrollable-directive>
 * ```
 * @cssprop --zui-scrollable-corner-size - sets the dimensions of the quadratic corner between the scrollbars
 * @cssprop --zui-scrollable-height - sets the height of the scrollable directive content explicitly
 * @cssprop --zui-scrollable-width - sets the width of the scrollable directive content explicitly
 * @cssprop --zui-scrollable-max-height - limits the height of the scroll wrapper until scrollbars appear
 * @cssprop --zui-scrollable-max-width - limits the width of the scroll wrapper until scrollbars appear
 */
@customElement('zui-scrollable-directive')
export class ScrollableDirective extends BaseElement {
  static readonly styles = [SCROLLABLE_DIRECTIVE_STYLES];

  /**
   * use a solid background on interactions
   */
  @property({ reflect: true, type: String })
  background: 'hidden' | 'visible' = 'hidden';

  /**
   * enlarge the hitarea (at least if vertical) _but not the background_
   */
  @property({ reflect: true, type: String })
  hitarea: 'enlarged' | 'minimal' = 'enlarged';

  @query('.wrapper')
  private readonly _wrapperRef: HTMLElement;

  @query('zui-scrollbar[orientation=horizontal]')
  private readonly _scrollbarHorizontalRef: Scrollbar | null;

  @query('zui-scrollbar[orientation=vertical]')
  private readonly _scrollbarVerticalRef: Scrollbar | null;

  @queryAssignedNodes(undefined, true, '*')
  private readonly _assignedNodes: Element[];

  @state()
  private _cornerColliding = false;

  @state()
  private _showHorizontal = false;

  @state()
  private _showVertical = false;

  // watches slotted contents to be resized
  private readonly _observer = new ResizeObserver(this._updateScrollbarSizes.bind(this));

  // stores the latest wrapper scroll
  private readonly _wrapperScrollOffsets: Position = { x: 0, y: 0 };

  private _scrollHorizontalTimeout: number | undefined = undefined;

  private _scrollVerticalTimeout: number | undefined = undefined;

  // clean up
  disconnectedCallback(): void {
    // stop observing
    this._observer.disconnect();

    // clear timeouts
    window.clearTimeout(this._scrollHorizontalTimeout);
    window.clearTimeout(this._scrollVerticalTimeout);

    // destroy ␡
    super.disconnectedCallback();
  }

  // set the scroll offset of the wrapper from scrollbars
  private _updateScroll({ detail: { offset, orientation } }: ScrollbarEvent): void {
    if (orientation === 'horizontal') {
      this._wrapperRef.scrollLeft = (this._wrapperRef.scrollWidth * offset) / 100;
    } else {
      this._wrapperRef.scrollTop = (this._wrapperRef.scrollHeight * offset) / 100;
    }
  }

  // pass through wheel events on scrollbars
  private _updateWheel({ deltaX, deltaY }: WheelEvent): void {
    this._wrapperRef.scrollLeft += deltaX;
    this._wrapperRef.scrollTop += deltaY;
  }

  // derive scrollbar handle sizes from available scroll space
  // eslint-disable-next-line max-statements
  private _updateScrollbarSizes(): void {
    // gather dimensions
    const { clientHeight, clientWidth, scrollHeight, scrollWidth } = this._wrapperRef;

    // update scrollbar visibility
    this._showHorizontal = scrollWidth > clientWidth;
    this._showVertical = scrollHeight > clientHeight;

    // update handle sizes
    if (this._showHorizontal) {
      const horizontalSize = (clientWidth / scrollWidth) * 100;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._scrollbarHorizontalRef!.style.setProperty('---zui-scrollbar-handle-size', `${horizontalSize}%`);
    }
    if (this._showVertical) {
      const verticalSize = (clientHeight / scrollHeight) * 100;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._scrollbarVerticalRef!.style.setProperty('---zui-scrollbar-handle-size', `${verticalSize}%`);
    }

    // align positions
    this._updateScrollbarPositions();
  }

  // set scrollbar handle positions from current scroll offset
  private _updateScrollbarPositions(): void {
    const { clientHeight, clientWidth, scrollHeight, scrollWidth, scrollLeft, scrollTop } = this._wrapperRef;

    if (this._showHorizontal) {
      const horizontalOffset = (scrollLeft / (scrollWidth - clientWidth)) * 100;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._scrollbarHorizontalRef!.style.setProperty('---zui-scrollbar-handle-offset', `${horizontalOffset}%`);
    }
    if (this._showVertical) {
      const verticalOffset = (scrollTop / (scrollHeight - clientHeight)) * 100;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._scrollbarVerticalRef!.style.setProperty('---zui-scrollbar-handle-offset', `${verticalOffset}%`);
    }
  }

  // toggle scrollbar tracks depening if currently scrolled
  private _updateScrollDirection(): void {
    // we need a reference to the scroll container
    // get current scroll offset
    const toggleScrollDirection = (ref: Scrollbar, timeout?: number): number => {
      ref.scrolling = true;
      // no need to check for existance
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/clearTimeout#notes
      window.clearTimeout(timeout);
      return window.setTimeout(() => {
        // remove flag from scrollbar
        ref.scrolling = false;
      }, SCROLLING_TIMEOUT);
    };

    // horizontal scrollbar
    if (this._wrapperScrollOffsets.x !== this._wrapperRef.scrollLeft) {
      this._scrollHorizontalTimeout = toggleScrollDirection(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this._scrollbarHorizontalRef!,
        this._scrollHorizontalTimeout
      );
    }

    // vertical scrollbar
    if (this._wrapperScrollOffsets.y !== this._wrapperRef.scrollTop) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._scrollVerticalTimeout = toggleScrollDirection(this._scrollbarVerticalRef!, this._scrollVerticalTimeout);
    }

    // store the current offset
    this._wrapperScrollOffsets.x = this._wrapperRef.scrollLeft;
    this._wrapperScrollOffsets.y = this._wrapperRef.scrollTop;
  }

  private _handleSlotChange(): void {
    // observe slotted elements
    this._assignedNodes.forEach((element) => this._observer.observe(element));

    // check for changes
    requestAnimationFrame(() => this._updateScrollbarSizes());
  }

  private _handleScroll(): void {
    this._updateScrollbarPositions();
    this._updateScrollDirection();
  }

  private _handleCornerEnter(): void {
    this._cornerColliding = true;
  }

  private _handleCornerLeave(): void {
    this._cornerColliding = false;
  }

  // initialize listeners as DOM is now initialized
  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    // track sizes
    this._observer.observe(this._wrapperRef);
  }

  protected render(): TemplateResult {
    return html`
      <div class="wrapper" part="wrapper" @scroll="${this._handleScroll}">
        <slot @slotchange="${this._handleSlotChange}"></slot>
      </div>

      <zui-scrollbar
        background="${this.background}"
        hitarea="${this.hitarea}"
        orientation="horizontal"
        part="scrollbar-horizontal"
        ?colliding="${this._cornerColliding}"
        ?disabled="${!this._showHorizontal}"
        ?multiple="${this._showVertical}"
        @scrollbar="${this._updateScroll}"
        @wheel="${this._updateWheel}"
      ></zui-scrollbar>
      <zui-scrollbar
        background="${this.background}"
        hitarea="${this.hitarea}"
        orientation="vertical"
        part="scrollbar-vertical"
        ?colliding="${this._cornerColliding}"
        ?disabled="${!this._showVertical}"
        ?multiple="${this._showHorizontal}"
        @scrollbar="${this._updateScroll}"
        @wheel="${this._updateWheel}"
      ></zui-scrollbar>
      <span
        class="corner"
        @mouseenter="${this._handleCornerEnter}"
        @mouseleave="${this._handleCornerLeave}"
        @wheel="${this._updateWheel}"
      ></span>
    `;
  }
}

// this is an alias for the new renamed <zui-scrollable-directive>; it will be removed
// in the next major release and has to be in this file, due to side-effect of customElement registering
// FIXME: remove in next major release
// eslint-disable-next-line jsdoc/require-example
/**
 * **Deprecated**. This component is equivalent to `zui-scrollable` but with a different name.
 * This is here for compatibility reasons but shouldn't be used anymore.
 * Instead use `zui-scrollable-directive`.
 *
 * This component will be removed in the future.
 *
 * @deprecated
 * @private
 */
@customElement('zui-scrollable')
export class Scrollable extends ScrollableDirective {
  connectedCallback(): void {
    super.connectedCallback();
    console.warn(
      'The usage of <zui-scrollable> has been deprecated and it will be removed in the next major release! It has been renamed to <zui-scrollable-directive>'
    );
  }
}
