import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  css,
  unsafeCSS,
  query,
  PropertyValues,
} from 'lit-element';
import { hostStyles } from '../../host.styles';
import style from './textarea.component.scss';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { event } from '../../decorators/event.decorator';
import { ScrollableDirective } from '../../directives/scrollable/scrollable.directive';
import { RealBaseElement } from '../base/BaseElement';
import { FormDataHandlingMixin } from '../../mixins/form-participation/form-data-handling.mixin';
import { FormValidationMixin } from '../../mixins/form-participation/form-validation.mixin';
import { FormEnabledElement, FormValidationElement } from '../../mixins/form-participation/form-participation.types';
import { numberUndefinedConverter } from '../../utils/component.utils';

const textareaStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The zui-textarea element represents a multi-line plain-text editing control,
 * useful when you want to allow users to enter a sizeable amount of free-form
 * text, for example a textarea on a review or feedback form.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2731)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-textarea name="fu_bar" placeholder="Message (optional)"></zui-textarea>
 * ```
 *
 * @fires change - when the value was changed and committed (i.e. by pressing leaving the focus) by user input.
 * @fires input - when the value was changed by user input.
 * @cssprop --zui-textarea-height - sets the height of textarea
 * @cssprop --zui-textarea-width - sets the width of textarea
 * @cssprop --zui-textarea-textarea-height - sets the height of the textarea
 */
@customElement('zui-textarea')
export class Textarea
  extends FormValidationMixin(FormDataHandlingMixin(RealBaseElement))
  implements FormValidationElement<FormEnabledElement> {
  static get styles(): CSSResultArray {
    return [hostStyles, textareaStyles];
  }

  /**
   * placeholder that is shown if no value is set
   */
  @property({ reflect: true })
  placeholder: string;

  /**
   * value of the textarea
   */
  @property({ reflect: true })
  value = '';

  /**
   * Whether the textarea is required or not
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * Sets the minlength of the value of the textarea
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  minlength: number | undefined;

  /**
   * Sets the maxlength of the value of the textarea
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  maxlength: number | undefined;

  /**
   * @private
   */
  @event({
    eventName: 'input',
    // same configuration as the original 'input' event on <textarea>
    bubbles: true,
    cancelable: false,
    composed: true,
  })
  emitInputEvent(): void {
    // input event is bubbling from internal input element.
    // this method is only here to signal the existence of the input event
  }

  /**
   * @private
   */
  @event({
    eventName: 'change',
    // same configuration as the original 'change' event on <textarea>
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  @query('zui-scrollable-directive')
  private _scrollableDirective: ScrollableDirective;

  @query('textarea')
  private _textarea: HTMLTextAreaElement;

  // watches for resize events
  private readonly _resizeObserver = new ResizeObserver(() => this._handleResize());

  constructor() {
    super();
    this.addValidator({
      type: 'valueMissing',
      validator: () => this.required === false || this._textarea.validity.valueMissing !== true,
      validatesOnProperties: ['required'],
    });
    this.addValidator({
      type: 'tooShort',
      validator: () => (this.minlength && this.value ? this.value.length >= this.minlength : true),
      validatesOnProperties: ['minlength'],
    });
    this.addValidator({
      type: 'tooLong',
      validator: () =>
        (this.maxlength || this.maxlength === 0) && this.value ? this.value.length <= this.maxlength : true,
      validatesOnProperties: ['maxlength'],
    });
  }

  // start observing resize
  connectedCallback(): void {
    super.connectedCallback();
    this._resizeObserver.observe(this);
  }

  // stop observers
  disconnectedCallback(): void {
    this._resizeObserver.disconnect();
    super.disconnectedCallback();
  }

  // react to resize (or offset parent toggle aka. `display: none`)
  private _handleResize(): void {
    this._resizeTextarea();
  }

  /**
   * Ensures that the internal textarea is set to its full auto-height, without any overflow.
   * This is required because the textarea does not support `height: auto` natively.
   *
   * Implementation inspired by: https://stackoverflow.com/a/24676492
   */
  private _resizeTextarea(): void {
    // let textarea shrink to 'auto' height, because without it 'scrollHeight' might actually be ceiled to the last height value instead of reflecting the true scroll height
    this._textarea.style.setProperty('--zui-textarea-textarea-height', 'auto');

    // explicitly set the height of the internal textarea to its scroll height (it should never overflow)
    // this is required because native `height:auto` does not work for textareas
    const scrollHeight = this._textarea.scrollHeight;
    // implement min-height manually (required because css `min-height:100%` does not work in Safari)
    const textareaHeight = Math.max(this.offsetHeight, scrollHeight);
    this._textarea.style.setProperty('--zui-textarea-textarea-height', `${textareaHeight}px`);
  }

  /**
   * Handle the input event from the internal textarea.
   * * Set the outer 'value' property based on the internal textarea value.
   * * The InputEvent does NOT need to be refired, as it bubbles.
   * * Ensure that the InputEvent is not prevented here.
   */
  private _handleTextareaInput(): void {
    // set the outer 'value' property based on the internal textarea value
    this.value = this._textarea.value;
  }

  /**
   * Handle the 'change' event from the internal textarea.
   * * Re-emit the change event from the internal textarea, because it is `composed: false` natively.
   */
  private _handleTextareaChange(): void {
    // re-emit the change event from the internal textarea
    this.emitChangeEvent();
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    if (changedProperties.has('value') || changedProperties.has('placeholder')) {
      this._resizeTextarea();
    }
  }

  protected render(): TemplateResult {
    return html`
      <div id="container">
        <zui-scrollable-directive background="visible" hitarea="enlarged">
          <textarea
            ?disabled=${this.disabled}
            ?readonly=${this.readonly}
            ?required=${this.required}
            maxlength=${ifDefined(this.maxlength)}
            minlength=${ifDefined(this.minlength)}
            name=${this.name}
            placeholder=${ifDefined(this.placeholder)}
            @input=${this._handleTextareaInput}
            @change=${this._handleTextareaChange}
            .value=${ifDefined(this.value)}
          ></textarea>
        </zui-scrollable-directive>
      </div>
      <div id="bottom-line"></div>
    `;
  }
}

// this is an alias for the new renamed <zui-textarea>; it will be removed
// in the next major release and has to be in this file, due to side-effect of customElement registering
// FIXME: remove in next major release
// eslint-disable-next-line jsdoc/require-example
/**
 * **Deprecated**. This component is equivalent to `zui-textarea` but with a different name.
 * This is here for compatibility reasons but shouldn't be used anymore.
 * Instead use `zui-textarea`.
 *
 * This component will be removed in the future.
 *
 * @deprecated
 * @private
 */
@customElement('zui-comment')
export class Comment extends Textarea {
  connectedCallback(): void {
    super.connectedCallback();
    console.warn(
      'The usage of <zui-comment> has been deprecated and it will be removed in the next major release! It has been renamed to <zui-textarea>'
    );
  }
}
