import { css, customElement, property, TemplateResult, unsafeCSS } from 'lit-element';
import { html } from 'lit-html';

import { BaseElement } from '../../base/BaseElement';
import type { Checkbox } from '../../checkbox/checkbox.component';
import type { MenuItem } from '../../menu/menu-item/menu-item.component';

import { hostStyles } from '../../../host.styles';
import style from './select-all.component.scss';

const SELECT_ALL_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * A grouping UI component which uses a `zui-menu-item` wrapping an `zui-checkbox` along with a `zui-menu-divider`
 * to wrap the select-all UI requirements.
 *
 * ## Functionality
 * It proxies the `focus` method of HTMLElement to pass-through focus to the embedded list item.
 * The used menu item skips focus by having an explicit [negative tabindex]:
 * > A negative value (usually tabindex="-1") means that the element is not reachable via sequential keyboard navigation, but could be
 * > focused with JavaScript or visually by clicking with the mouse. It's mostly useful to create accessible widgets with JavaScript.
 * > A negative value is useful when you have off-screen content that appears on a specific event. The user won't be able to focus any
 * > element with a negative tabindex using the keyboard, but a script can do so by calling the focus() method.
 * The checkbox value can optionally be set and is passed-through directly, further informations can be found at the `zui-checkbox`
 * documentation.
 * An optional label can be provided through the _default slot_.
 * As native click and change events are bubbling up the tree, those can be listened on this element as usual.
 *
 * @example
 * ```HTML
 * <zui-select-all>Select all</zui-select-all>
 * ```
 *
 * @slot - default slot for an optional checkbox label
 *
 * [negative tabindex]: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
 */
@customElement('zui-select-all')
export class SelectAll extends BaseElement {
  static readonly styles = [hostStyles, SELECT_ALL_STYLES];

  /**
   * the value is passed through to the embedded checkbox
   */
  @property({ reflect: true })
  value: Checkbox['value'] = false;

  /**
   * convenient getter to retrieve a simple boolean (which helps to simply toggle between states)
   */
  get selected(): boolean {
    return this.value !== false;
  }

  /**
   * forwards the focus to the embedded list item
   */
  focus(): void {
    // TODO: use focus-delegation
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    (this.shadowRoot!.querySelector('zui-menu-item') as MenuItem).focus();
  }

  protected render(): TemplateResult {
    return html`
      <zui-menu-item emphasis="default" tabindex="-1">
        <zui-checkbox enable-mixed value="${this.value}">
          <slot></slot>
        </zui-checkbox>
      </zui-menu-item>
      <zui-menu-divider skip></zui-menu-divider>
    `;
  }
}
