import { DatePickerInputPart } from '../date-picker-input-part/date-picker-input-part.component';

export const addLeadingZeros = (value: number, length: number): string => {
  return `${value}`.padStart(length, '0');
};

export const constrainInputValue = (value: number, min: number, max: number): number => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};

const getSiblingInput = (
  element: DatePickerInputPart | HTMLSpanElement,
  siblingKey: 'previousElementSibling' | 'nextElementSibling'
): DatePickerInputPart | undefined => {
  const sibling = element[siblingKey];

  if (sibling === null) {
    return undefined;
  }

  if (sibling instanceof DatePickerInputPart) {
    return sibling;
  }

  if (sibling instanceof HTMLSpanElement) {
    return getSiblingInput(sibling, siblingKey);
  }

  return undefined;
};

export const getPreviousInput = (element: DatePickerInputPart): DatePickerInputPart | undefined =>
  getSiblingInput(element, 'previousElementSibling');
export const getNextInput = (element: DatePickerInputPart): DatePickerInputPart | undefined =>
  getSiblingInput(element, 'nextElementSibling');
