import { css, CSSResultArray, customElement, html, property, state, TemplateResult, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import styles from './slider-custom.component.scss';
import { SliderBaseClass } from '../slider-base.class';
import { EventWithTarget } from '../../../types';
import { query } from 'lit-element/lib/decorators';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { event } from '../../../decorators/event.decorator';
import { countDecimals } from '../../../utils/format.utils';
import { getCursorPosition } from '../../../utils/dom.utils';
import { clamp, numberUndefinedConverter } from '../../../utils/component.utils';
import { getNextValidSliderValue, isValidStep } from '../slider.utils';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin';
import { styleMap } from 'lit-html/directives/style-map';

const sliderEnhancedStyles = css`
  ${unsafeCSS(styles)}
`;

type Knob = 'a' | 'b';
type Direction = 'left' | 'right';
type RangeValue = [number, number];

/**
 * The custom slider represents a custom made slider, behaving like a native input (range) element, including a
 * styled track and a configurable active line. Beside of this, the custom slider supports a dual-knob range slider
 * mode, which is triggered by an internal attribute and two, comma separated, values. Also it's possible to set
 * semi-transparent ghost handle with a fixed position. This component is meant to to be used in the zui-slider wrapping parent component.
 *
 * @example
 *
 * ```html
 *  <zui-slider-custom
 *    min="0"
 *    max="10"
 *    value="2,4"
 *    step="1"
 *    dual-knobs
 *    ghost-handle="3"
 *    >
 *    </zui-slider-custom>
 * ```
 *
 * @fires input - The input event is fired when the value of the has received input via keyboard or mouse
 * @fires change - The change event is fired when the user changes the value via keyboard or mouse
 * @cssprop --zui-slider-width - The width of the slider
 * @cssprop --zui-slider-padding - Defines a custom offset for the slider
 *
 */
@customElement('zui-slider-custom')
export class SliderCustom extends DelegateFocusMixin(SliderBaseClass) {
  static get styles(): CSSResultArray {
    return [hostStyles, sliderEnhancedStyles];
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  static DEFAULT_KEYBOARD_STEP_SIZE = 1;
  static DEFAULT_VALUE_DECIMAL_PLACES = 2;
  static ONE_MILLION = Math.pow(10, 6);
  // represents the default translation for both knobs, which is set in css
  static SEMICIRCLE_KNOB_A_DEFAULT_TRANSLATION = -11.83;
  static SEMICIRCLE_KNOB_B_DEFAULT_TRANSLATION = -1.17;
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * the tabindex of the slider-custom
   */
  @property({ reflect: true, type: Number })
  tabindex = 0;

  /**
   * the enabled/ disabled state of the active line
   */
  @property({ reflect: true, type: Boolean, attribute: 'active-line-disabled' })
  activeLineDisabled = false;

  /**
   * Toggle dual knob / range slider mode
   *
   */
  @property({ reflect: true, type: Boolean, attribute: 'dual-knobs' })
  dualKnobs = false;

  /**
   * If set, the ghost handle ist enabled and set to the given value
   *
   */
  @property({ reflect: true, attribute: 'ghost-handle', converter: numberUndefinedConverter })
  ghostHandle: number | undefined = undefined;

  /**
   * readonly state
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * disabled state
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * the value of the slider; begin and end are separated by a comma
   */
  @property({
    reflect: true,
  })
  value: RangeValue | number = this.min;

  /**
   * @private
   */
  @event({ eventName: 'input', bubbles: true, composed: true })
  emitInputEvent(): void {
    this.dispatchEvent(new Event('input', { bubbles: true, composed: true }));
  }

  /**
   * @private
   */
  @event({ eventName: 'change', bubbles: true, cancelable: false, composed: true })
  emitChangeEvent(): void {
    this.dispatchEvent(new Event('change', { bubbles: true, cancelable: false, composed: true }));
  }

  @query('.slider')
  private _sliderRef: HTMLDivElement;

  @query('.knob-container')
  private _knobContainerRef: HTMLDivElement;

  @query('.knob.a')
  private _knobA: HTMLDivElement;

  @query('.knob.b')
  private _knobB: HTMLDivElement;

  @state()
  private _knobAisDragged = false;

  @state()
  private _knobBisDragged = false;

  private readonly _moveKnobBound = this._moveKnob.bind(this);
  private readonly _endMoveKnobBound = this._endMoveKnob.bind(this);

  /**
   * Current active / focused knob
   */
  private _activeKnob: Knob | null;

  /**
   * Current hovered knob
   */
  private _hoveredKnob: Knob | null;

  private get _derivedStep(): SliderBaseClass['step'] {
    if (this.step === 'any') {
      return this.step;
    }

    if (isNaN(Number(this.step)) || Number(this.step) <= 0) {
      return 1;
    }

    return this.step;
  }

  private get _derivedGhostHandleValue(): number | undefined {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because TS does not like undef < number
    return this.ghostHandle < this.min || this.ghostHandle > this.max ? undefined : this.ghostHandle;
  }

  private get _showGhostHandle(): boolean {
    return this._derivedGhostHandleValue !== undefined;
  }

  private get _isOddStep(): boolean {
    return !isValidStep(this.min, this.max, this._derivedStep);
  }

  private get _decimalPlaces(): number {
    if (this._derivedStep !== 'any') {
      return countDecimals(this._derivedStep);
    }

    return SliderCustom.DEFAULT_VALUE_DECIMAL_PLACES;
  }

  private get _knobAPositionX(): number {
    return this._knobA.getBoundingClientRect().x;
  }

  private get _knobBPositionX(): number {
    // if we don't use dual knobs, we reference knob a
    return this.dualKnobs ? this._knobB.getBoundingClientRect().x : this._knobAPositionX;
  }

  private get _range(): number {
    return this.max - this.min;
  }

  private get _knobContainerLeft(): number {
    return this._knobContainerRef.getBoundingClientRect().left;
  }

  private get _knobContainerWidth(): number {
    return this._knobContainerRef.getBoundingClientRect().width;
  }

  // return percentual active line start position
  private get _derivedActiveLineStart(): number {
    switch (this.activeLineStart) {
      case 'min':
        return 0;
      case 'max':
        return 100;
      default:
        return ((clamp(this.min, this.activeLineStart, this.max) - this.min) / this._range) * 100;
    }
  }

  // return left margin for active line
  // we set an "inifite" margin to draw the active line at the very and or begin, independent from custom offset
  private get _activeLineMarginLeft(): number {
    switch (this.activeLineStart) {
      case 'min':
        return -SliderCustom.ONE_MILLION;
      case 'max':
        return 0;
      default:
        return this.activeLineStart <= this.min ? -SliderCustom.ONE_MILLION : 0;
    }
  }

  // return right margin for active line
  // we set an "inifite" margin to draw the active line at the very and or begin, independent from custom offset
  private get _activeLineMarginRight(): number {
    switch (this.activeLineStart) {
      case 'min':
        return 0;
      case 'max':
        return -SliderCustom.ONE_MILLION;
      default:
        return this.activeLineStart >= this.max ? -SliderCustom.ONE_MILLION : 0;
    }
  }

  // check for active line direction
  private get _drawActiveLineRightToLeft(): boolean {
    switch (this.activeLineStart) {
      case 'min':
        return false;
      case 'max':
        return true;
      default:
        return this._singleValue < this.activeLineStart;
    }
  }

  private get _knobAValue(): number {
    return this.dualKnobs ? this._rangeValue[0] : this._singleValue;
  }

  private get _knobAShift(): number {
    return this._getKnobShiftByValue(this._knobAValue);
  }

  private get _knobBShift(): number {
    return this._getKnobShiftByValue(this._knobBValue);
  }

  private get _knobGhostShift(): number | undefined {
    return this._derivedGhostHandleValue !== undefined
      ? this._getKnobShiftByValue(this._derivedGhostHandleValue)
      : undefined;
  }

  private get _knobBValue(): number {
    return this._rangeValue[1];
  }

  private get _singleValue(): number {
    return this.value as number;
  }

  private set _singleValue(value: number) {
    this.value = value;
  }

  private get _rangeValue(): RangeValue {
    return this.value as RangeValue;
  }

  private set _rangeValue(value: RangeValue) {
    this.value = value;
  }

  private _getKnobShiftByValue(value: number): number {
    return (100 * (clamp(this.min, value, this.max) - this.min)) / this._range;
  }

  private _emitEvents(): void {
    this.emitChangeEvent();
    this.emitInputEvent();
  }

  private _getCursorPositionX(mouseEvent: MouseEvent): number {
    const { clientX } = getCursorPosition(mouseEvent);

    return clientX;
  }

  private _checkHoveredKnobAndStartMoving(event: EventWithTarget<HTMLDivElement, MouseEvent>): void {
    switch (event.target.id) {
      case 'knob-a':
        this._hoveredKnob = 'a';
        this._activeKnob = 'a';
        break;
      case 'knob-b':
        this._hoveredKnob = 'b';
        this._activeKnob = 'b';
        break;
      default:
        this._hoveredKnob = null;
        this._activeKnob = null;
        break;
    }

    this._startMoveKnob(event);
  }

  private _startMoveKnob(mouseEvent: EventWithTarget<HTMLDivElement, MouseEvent>): void {
    window.addEventListener('mousemove', this._moveKnobBound, false);
    window.addEventListener('mouseup', this._endMoveKnobBound, false);

    window.addEventListener('touchmove', this._moveKnobBound, false);
    window.addEventListener('touchend', this._endMoveKnobBound, false);

    const distanceMouseToKnobA = Math.abs(this._getCursorPositionX(mouseEvent) - this._knobAPositionX);
    const distanceMouseToKnobB = Math.abs(this._getCursorPositionX(mouseEvent) - this._knobBPositionX);

    const distanceDifference = Math.abs(
      distanceMouseToKnobA +
        SliderCustom.SEMICIRCLE_KNOB_A_DEFAULT_TRANSLATION -
        distanceMouseToKnobB +
        SliderCustom.SEMICIRCLE_KNOB_B_DEFAULT_TRANSLATION
    );

    // Due the semicircle border radius, both knobs have a default translation to grab them at the very end - or begin
    if (this._hoveredKnob === null) {
      this._activeKnob =
        distanceMouseToKnobA + SliderCustom.SEMICIRCLE_KNOB_A_DEFAULT_TRANSLATION <=
        distanceMouseToKnobB + SliderCustom.SEMICIRCLE_KNOB_B_DEFAULT_TRANSLATION
          ? 'a'
          : 'b';
    }

    // set drag flag for to style the correct knob
    if (!this.dualKnobs || this._activeKnob === 'a') {
      this._knobAisDragged = true;
    } else if (this._activeKnob === 'b') {
      this._knobBisDragged = true;
    }

    // do not move the the a knob, if the distance between the two knobs is too small
    // knob b has a minor translation, so we can skip the distance difference check
    if (
      (this._activeKnob === 'a' && distanceDifference > Math.abs(SliderCustom.SEMICIRCLE_KNOB_A_DEFAULT_TRANSLATION)) ||
      this._activeKnob === 'b'
    ) {
      this._moveKnob(mouseEvent);
    }
  }

  private _moveKnob(mouseEvent: EventWithTarget<HTMLDivElement, MouseEvent>): void {
    // moving a knob means, that we move the mouse, starting from a certain knob
    // we have to calculate the actual value of that knob and the template rendering will do the rest for us

    const knobShift =
      clamp(0, (this._getCursorPositionX(mouseEvent) - this._knobContainerLeft) / this._knobContainerWidth, 1) * 100;

    const tempKnobValue = this._knobPositionToValue(knobShift);

    // simply update the value for the current moved knob
    if (!this.dualKnobs) {
      this._singleValue = !this._isOddStep
        ? getNextValidSliderValue(this.min, this.max, this._derivedStep, tempKnobValue)
        : tempKnobValue;
    } else {
      // for dual knobs, the temporary value of a knob is determined by the activeKnob
      let knobAValue = this._activeKnob === 'a' ? tempKnobValue : this._rangeValue[0];
      let knobBValue = this._activeKnob === 'b' ? tempKnobValue : this._rangeValue[1];
      // we match the selected value to the closest value regarding the valid steps
      // if the step is not valid, we simply pass the value along
      // TODO this needs to be corrected, after odd range support is added
      if (!this._isOddStep) {
        knobAValue = getNextValidSliderValue(this.min, this.max, this._derivedStep, knobAValue);
        knobBValue = getNextValidSliderValue(this.min, this.max, this._derivedStep, knobBValue);
      }
      this._clampAndSetRangeValue([knobAValue, knobBValue]);
    }
    this._emitEvents();
  }

  private _endMoveKnob(): void {
    window.removeEventListener('mousemove', this._moveKnobBound, false);
    window.removeEventListener('mouseup', this._endMoveKnobBound, false);

    window.removeEventListener('touchmove', this._moveKnobBound, false);
    window.removeEventListener('touchend', this._endMoveKnobBound, false);

    this._knobAisDragged = false;

    if (this.dualKnobs) {
      this._knobBisDragged = false;
    }
  }

  // we return the derived value from the knob positions, fixing it the given amount of decimal places
  private _knobPositionToValue(knobRelativePosition: number): number {
    return parseFloat((this.min + this._range * (knobRelativePosition / 100)).toFixed(this._decimalPlaces));
  }

  /**
   * clamps passed values and updates value
   *
   * @param unclampedValueKnobA value for knobA that should be clamped
   * @param unclampedValueKnobB value for knobB that should be clamped
   * @private
   */
  private _clampAndSetRangeValue([unclampedValueKnobA, unclampedValueKnobB]: RangeValue): void {
    this._rangeValue = [
      clamp(this.min, unclampedValueKnobA, this._knobBValue),
      clamp(this._knobAValue, unclampedValueKnobB, this.max),
    ];
  }

  private _advanceValueByStepInDirection = (direction: Direction): void => {
    let step = this._derivedStep === 'any' ? SliderCustom.DEFAULT_KEYBOARD_STEP_SIZE : Number(this._derivedStep);
    if (direction === 'left') {
      step = step * -1;
    }
    if (!this.dualKnobs) {
      this._singleValue = clamp(this.min, this._singleValue + step, this.max);
    } else {
      // for dual knobs advance the active knob by one step
      if (this._activeKnob === 'a') {
        this._clampAndSetRangeValue([this._knobAValue + step, this._knobBValue]);
      } else if (this._activeKnob === 'b') {
        this._clampAndSetRangeValue([this._knobAValue, this._knobBValue + step]);
      }
    }
  };

  private _handleKeyDown(event: EventWithTarget<HTMLDivElement, KeyboardEvent>): void {
    if (this.readonly || this.disabled) {
      return;
    }

    switch (event.target.id) {
      case 'knob-a':
        this._activeKnob = 'a';
        break;
      case 'knob-b':
        this._activeKnob = 'b';
        break;
    }

    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowDown': {
        this._advanceValueByStepInDirection('left');
        this._emitEvents();
        break;
      }
      case 'ArrowRight':
      case 'ArrowUp': {
        this._advanceValueByStepInDirection('right');
        this._emitEvents();
        break;
      }
    }
  }

  protected render(): TemplateResult | void {
    return html`
      <div
        zuiCaptureFocus
        class="slider"
        @touchstart="${this._checkHoveredKnobAndStartMoving}"
        @mousedown="${this._checkHoveredKnobAndStartMoving}"
        @mouseup="${this._endMoveKnob}"
        @touchend="${this._endMoveKnob}"
        style="${styleMap({
          '---zui-slider-knob-a-shift': `${this._knobAShift}%`,
          '---zui-slider-knob-b-shift': `${this._knobBShift}%`,
        })}"
      >
        <div class="range-line"></div>
        <div class="knob-container"
        >
          <div class="range-line active ${classMap({
            'right-to-left': this._drawActiveLineRightToLeft,
          })}"
               style="${styleMap({
                 '---zui-slider-active-line-start': `${this._derivedActiveLineStart}%`,
                 '---zui-slider-active-line-margin-left': `${this._activeLineMarginLeft}px`,
                 '---zui-slider-active-line-margin-right': `${this._activeLineMarginRight}px`,
               })}"></div>
          ${
            this._showGhostHandle
              ? html`<div
                  tabindex="0"
                  id="knob-ghost"
                  class="knob ghost"
                  style="${styleMap({
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    '---zui-slider-knob-ghost-shift': `${this._knobGhostShift!}%`,
                  })}"
                ></div>`
              : nothing
          }
        <div
            tabindex="0"
            id="knob-a"
            class="knob a ${classMap({
              semicircle: this.dualKnobs,
              dragging: this._knobAisDragged,
            })}"
            @keydown="${this._handleKeyDown}"
          ></div>
          ${
            this.dualKnobs
              ? html`<div
                  tabindex="0"
                  id="knob-b"
                  class="knob b semicircle ${classMap({ dragging: this._knobBisDragged })}"
                  @keydown="${this._handleKeyDown}"
                ></div>`
              : nothing
          }
        </div>
      </div>
      </div>
    `;
  }
}
