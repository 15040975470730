import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS } from 'lit-element';
import { queryAssignedNodes, state } from 'lit-element/lib/decorators';
import { classMap } from 'lit-html/directives/class-map';

import { BaseElement } from '../base/BaseElement';
import { event } from '../../decorators/event.decorator';
import '../icon-bar/icon-bar.component';

import { hostStyles } from '../../host.styles';
import style from './box.component.scss';

type EmphasisDeprecated = 'active';
type Emphasis = 'default' | 'selected' | EmphasisDeprecated;
type Hierarchy = 'first' | 'second';
type Size = 'xs' | 's' | 'm' | 'l' | 'xl';

const boxStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * A box component is used to visually group content.
 * The box provides an optional header that can contain a header text and up to three icons.
 * Additionally the header can show a close icon.
 *
 * ## Closing Behavior
 * The existing closing behavior of the box is unfavorable and will be reworked.
 * Previously, the closing worked like this:
 * When the attribute `closable` is set, the box shows a close button.
 * When this button is clicked the attribute `closed` is set and the box will be hidden.
 * However, the box is still part of the DOM tree.
 * The same happens when the `closed` attribute is set programmatically.
 *
 * This behavior has several drawbacks:
 *  * from a UI/UX perspective it's a inconsistent because you cannot get the box back to open.
 *  * it's unintuitive from a SPA developer perspective.
 *
 *  The more natural approach (and this is also the recommended approach from now on) is this:
 *  * when the close-icon is clicked, a `close` event is dispatched
 *  * besides this event, the box does nothing and stays the way it is
 *  * an SPA developer can react to this event and remove the box from the DOM/not render it anymore.
 *
 * For this reason the closing behavior the way it was before is now deprecated.
 * As an escape hatch if you need the previous behavior, you can set the new attribute `use-deprecated-close-behavior`
 * to get back the old closing mechanism. Setting the `closed` attribute will also work.
 * However: In a future release this functionality will be dropped.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/01-Desktop---Component-Library---1.1?node-id=13009%3A2752)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-box size="l" header-text="headertext" hierarchy-level="first" emphasis="default">
 *     <zui-icon-holy-placeholder slot="icon1" size="m"></zui-icon-holy-placeholder>
 *     <zui-icon-holy-placeholder slot="icon2" size="m"></zui-icon-holy-placeholder>
 *     <zui-icon-holy-placeholder slot="icon3" size="m"></zui-icon-holy-placeholder>
 *     <div>box content</div>
 * </zui-box>
 * ```
 *
 * @fires box-closed - DEPRECATED This is the event which gets fired when the box is closed
 * @fires {CustomEvent} close - emitted when the box is closable, not disabled and the close icon is clicked or the 'Enter' key is pressed on the close icon.
 * @slot - This is the default slot. It's an innerHtml of the box-element
 * @slot icon1 - This is the first icon slot.
 * @slot icon2 - This is the second icon slot.
 * @slot icon3 - This is the third icon slot.

 */
@customElement('zui-box')
export class Box extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, boxStyles];
  }

  /**
   * The header text of the box
   */
  @property({ reflect: true, type: String, attribute: 'header-text' })
  headerText = '';

  /**
   * Defines one of two possible emphasis states (default/selected)
   * The deprecated emphasis "active" was renamed to "selected"
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Defines one of two possible hierarchy levels (first/second)
   */
  @property({ reflect: true, type: String, attribute: 'hierarchy-level' })
  hierarchyLevel: Hierarchy = 'first';

  /**
   * If closable is enabled an additional button will be visible that allows the user to close the box.
   */
  @property({ reflect: true, type: Boolean })
  closable = false;

  /**
   * DEPRECATED: See paragraph on "closing behavior"
   *
   * If closed is true (e.g. via clicking the close button of the box), the box will disappear from the layout.
   * Once the box disappeared setting closed back to false will make it reappear.
   * Only works if `use-deprecated-close-behavior` is set.
   *
   * @deprecated
   */
  @property({ reflect: true, type: Boolean })
  closed = false;

  /**
   * DEPRECATED: See paragraph on "closing behavior"
   *
   * If this attribute is set the box will disappear from the layout when the close icon is pressed.
   *
   * @deprecated
   */
  @property({ reflect: true, type: Boolean, attribute: 'use-deprecated-close-behavior' })
  useDeprecatedCloseBehavior = false;

  /**
   * Controls the disabled state of the box (only if the interactive property is enabled).
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * This enables the interaction states 'disable' and 'hover'
   */
  @property({ reflect: true, type: Boolean })
  interactive = false;

  /**
   * Determines whether there is a visible bottom line
   */
  @property({ reflect: true, type: Boolean, attribute: 'no-bottom-line' })
  noBottomLine = false;

  /**
   * Defines one of five possible sizes (xs/s/m/l/xl)
   */
  @property({ reflect: true, type: String })
  size: Size = this.hasTouch ? 'm' : 'l';

  /**
   * emits a box-closed event and returns the value of .dispatchEvent
   *
   * @private
   */
  @event({
    eventName: 'box-closed',
    cancelable: true,
    bubbles: false,
    composed: false,
  })
  emitBoxClosedEvent(): boolean {
    return this.dispatchEvent(
      new CustomEvent('box-closed', {
        cancelable: true,
        bubbles: false,
        composed: false,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'close',
    bubbles: false,
    composed: false,
    cancelable: false,
  })
  emitCloseEvent(): void {
    this.dispatchEvent(
      new CustomEvent('close', {
        bubbles: false,
        composed: false,
        cancelable: false,
      })
    );
  }

  @queryAssignedNodes('icon1', true, '[zuiIcon]')
  private _assignedIconOne: HTMLElement[];

  @queryAssignedNodes('icon2', true, '[zuiIcon]')
  private _assignedIconTwo: HTMLElement[];

  @queryAssignedNodes('icon3', true, '[zuiIcon]')
  private _assignedIconThree: HTMLElement[];

  @state()
  private _hasIcons = false;

  private get _hasHeader(): boolean {
    return this.headerText?.length > 0 || this._hasIcons || this.closable;
  }

  /**
   * Handler for the close event from the close button in the icon bar, fires a box-closed event.
   *
   * @param {Event} closeEvent the close event fired by the iconbar
   */
  private _handleClose(closeEvent: Event): void {
    closeEvent.stopPropagation();

    if (this.disabled && this.interactive) {
      return;
    }
    this.emitCloseEvent();
    if (this.emitBoxClosedEvent()) {
      if (this.useDeprecatedCloseBehavior) {
        console.warn('This behavior will change in the future');
        this.closed = true;
      }
    }
  }

  private _onIconSlotChanged(): void {
    this._hasIcons =
      this._assignedIconOne.length > 0 || this._assignedIconTwo.length > 0 || this._assignedIconThree.length > 0;
  }

  /**
   * Detects if one of the deprecated emphasis states was used
   *
   * @param {Map<string,unknown>} changedProperties properties which have been changed
   */
  protected update(changedProperties: Map<string, unknown>): void {
    super.update(changedProperties);
    if (changedProperties.has('emphasis')) {
      if (this.emphasis === 'active') {
        console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-box.`);
      }
    }
  }

  protected render(): TemplateResult {
    return html`
      <section id="box" class="${classMap({ 'has-header': this._hasHeader })}">
        <header id="header">
          <zui-truncate-with-tooltip id="headertextcontainer" tooltip-trigger="hover"
            >${this.headerText}</zui-truncate-with-tooltip
          >
          <zui-icon-bar
            @close="${this._handleClose}"
            id="iconbar"
            emphasis="${this.emphasis === 'selected' ? 'highlight' : 'subtle'}"
            ?closable=${this.closable}
          >
            <slot name="icon1" slot="icon1" @slotchange="${this._onIconSlotChanged}"></slot>
            <slot name="icon2" slot="icon2" @slotchange="${this._onIconSlotChanged}"></slot>
            <slot name="icon3" slot="icon3" @slotchange="${this._onIconSlotChanged}"></slot>
          </zui-icon-bar>
        </header>
        <zui-scrollable-directive background="visible" hitarea="enlarged">
          <span id="placeholder">
            <slot id="content"></slot>
          </span>
        </zui-scrollable-directive>
      </section>
    `;
  }
}
