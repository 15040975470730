import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  queryAssignedNodes,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import style from './content-tab-highlight-item.component.scss';
import { getContentClass } from '../content-tab.utils';
import { ContentTabItem, ContentTabItemBase } from '../content-tab-base.class';

type Hierarchy = ContentTabItem['hierarchy'];

const contentTabHighlightItemStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The content tab highlight item is a content tab item for any content.
 * The content tab highlight item is intended for usage in the content tab bar component.
 *
 * The height of the item is 48px.
 * The width of the item is solely be depending on width of the given content.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=52930%3A120)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---3.1?node-id=76949%3A367659)
 *
 * @example
 * HTML:
 *
 * Text only:
 * ```html
 * <zui-content-tab-highlight-item text="My custom text" value="textItemOne">
 * </zui-content-tab-highlight-item>
 * ```
 *
 * Icon only:
 * ```html
 * <zui-content-tab-highlight-item value="iconItemOne">
 *   <zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list>
 * </zui-content-tab-highlight-item>
 *  ```
 *
 * Text and icon:
 * ```html
 * <zui-content-tab-highlight-item text="My custom text" value="textAndIconItemOne">
 *   <zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list>
 * </zui-content-tab-highlight-item>
 *  ```
 *
 * @fires content-tab-item-closed - fired the close icon of a tab was clicked. Contains the value of the tab as payload.
 * @slot icon - Here you can insert your own icon. Size of them will set everytime automatically as 'm' 16x16px
 */
@customElement('zui-content-tab-highlight-item')
export class ContentTabHighlightItem extends ContentTabItemBase {
  static get styles(): CSSResultArray {
    return [hostStyles, contentTabHighlightItemStyles];
  }

  /**
   * Defines one of two possible hierarchy level (first/second).
   */
  @property({ reflect: true, type: String })
  hierarchy: Hierarchy = 'first';

  /**
   * Emit close event
   *
   * @private
   */
  @event({
    eventName: 'content-tab-item-closed',
    bubbles: true,
    composed: false,
    cancelable: false,
  })
  emitContentTabItemClosedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('content-tab-item-closed', {
        bubbles: true,
        composed: false,
        cancelable: false,
        detail: {
          value: this.value,
        },
      })
    );
  }

  /**
   * holds state for simulating the hover effect.
   * See content-tab-default-item for an detailed description
   */
  @state()
  private _isHover = false;

  /**
   * Selects the icon slot element
   */
  @queryAssignedNodes('icon', true, '[zuiIcon]')
  private _slotIcon: HTMLElement[];

  /**
   * Set css classes based on content of the slots or removes them if slots change
   */
  private _handleIconSlotChange(): void {
    this._iconSizeUpdate();
    // we need to update (i.e. call render()) to re-calculate the new contentClass
    this.requestUpdate();
  }

  /**
   * Sets size of icon. Always as "m"
   */
  private _iconSizeUpdate(): void {
    this._slotIcon.forEach((icon) => icon.setAttribute('size', 'm'));
  }

  private _setHover(): void {
    this._isHover = true;
  }

  private _removeHover(): void {
    this._isHover = false;
  }

  protected render(): TemplateResult | void {
    return html`<button
        ?disabled=${this.disabled}
        class="${classMap({ [getContentClass(this._slotIcon, this.text)]: true, hover: this._isHover })}"
      >
        <div id="icon-slot-container">
          <slot id="icon-slot" name="icon" @slotchange=${this._handleIconSlotChange}></slot>
        </div>
        <div id="text-container">
          <div class="text-item default">${this.text}</div>
          <div class="text-item selected">${this.text}</div>
        </div>
      </button>
      ${this.closable
        ? html`<div
            id="closable-container-absolute"
            @mouseover=${this._setHover}
            @mouseleave=${this._removeHover}
            ?disabled=${this.disabled}
          >
            <zui-interactive-icon
              emphasis=${this.emphasis === 'default' ? 'subtle' : 'highlight'}
              @click="${this.emitContentTabItemClosedEvent}"
            >
              <zui-icon-close size="xs"></zui-icon-close>
            </zui-interactive-icon>
          </div>`
        : nothing}`;
  }
}
