import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  css,
  unsafeCSS,
  queryAssignedNodes,
} from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import { MenubarItem } from '../menubar-item/menubar-item';
import { event } from '../../../decorators/event.decorator';
import { nothing } from 'lit-html';
import { MenubarNavItem } from '../menubar-nav-item/menubar-nav-item.component';
import style from './menubar.component.scss';
import { MenubarItemBase } from '../menubar-item-base.class';

const menuBarStyles = css`
  ${unsafeCSS(style)}
`;

type VariantType = MenubarItemBase['variant'];
type Emphasis = MenubarItemBase['emphasis'];

// convert to lower case because .tagName returns the name in upper case
const isMenubarNavItem = (element): element is MenubarNavItem =>
  element.tagName.toLowerCase() === 'zui-menubar-nav-item';

/**
 * The menubar is used to show a sidebar menu for navigation.
 * It's the wrapper component for several components like `zui-menubar-item`, `zui-menubar-nav-item` and `zui-menubar-divider`
 * that can be used to construct the menubar.
 *
 * The Menubar can be "expandable" which means that there will be a button on the bottom of the menubar that can be clicked
 * to expand or collapse the menubar. When the menubar is expanded, both icons and labels of menubar-items will be visible,
 * while in collapsed state only the icon is visible.
 *
 * The "emphasis" attribute works a bit different compared to other ZUi Web components.
 * The menubar itself doesn't have a different styling based on the emphasis.
 * However, if an item is "selected" (by clicking it), this item will get the emphasis set. So the "emphasis" attribute of the
 * menubar defines the emphasis of the selected menubar item.
 * It is important to understand, that you may not to set the emphasis on the actual items by yourself.
 * This is done only by the menubar wrapper component!
 *
 * To "preselect" and item, use the "selected" attribute of the menubar.
 *
 * To listen for changes of the selected item done by the user, use the "change" or "input" events on the menubar.
 *
 * ## Figma
 * - [Web - Component Library](https://www.figma.com/file/z4fyXFOJCpuaNImx3K234n/❖-04-Web---Component-Library---1.4?node-id=59%3A8556)
 * - [Styleguide - Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=139%3A1467&viewport=279%2C103%2C0.15313252806663513)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menubar style="height: 500px;" emphasis="selected" variant="primary">
 * 		<zui-menubar-item value="1">
 * 			<zui-icon-holy-placeholder size="m"></zui-icon-holy-placeholder>
 * 		</zui-menubar-item>
 * 		<zui-menubar-item value="2">
 * 			<zui-icon-holy-placeholder size="m"></zui-icon-holy-placeholder>
 * 		</zui-menubar-item>
 * </zui-menubar>
 * ```
 *
 * @fires change - The event containing the changed value
 * @fires input - The event containing the changed value
 * @slot default - This is the default slot. It's an innerHtml of the menuBar element
 * @exports
 * @class MenuBar
 * @cssprop --zui-menubar-background-color - color of the menubar backgroun, derived from variant attribute by default
 * @cssprop --zui-menubar-opened-width - sets the overall width of the opened menubar explicitly
 * @cssprop --zui-menubar-width - sets the overall height of the menubar explicitly
 */
@customElement('zui-menubar')
export class Menubar extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, menuBarStyles];
  }

  /**
   * Value of the selected menu bar item
   */
  @property({ reflect: true, type: String })
  selected: string | null = null;

  /**
   * Disabled state of the menu bar
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Variant of the menu bar
   */
  @property({ reflect: true, type: String })
  variant: VariantType = 'primary';

  /**
   * Emphasis of the menu bar. This will be propagated to the currently selected menubar item.
   * The deprecated emphasis "active" and "active-primary" were renamed to "selected" and "selected-primary".
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Expandable state of the menu bar
   */
  @property({ reflect: true, type: Boolean })
  expandable = false;

  /**
   * Opened state of the menu bar
   */
  @property({ reflect: true, type: Boolean })
  opened = false;

  /**
   * Whether the expanded state is set to false on navigate
   */
  @property({ reflect: true, type: Boolean, attribute: 'auto-close' })
  autoClose = false;

  /**
   * emits an input Event
   *
   * @param detail
   * @private
   */
  @event({
    eventName: 'input',
  })
  emitInputEvent(detail: string): void {
    this.dispatchEvent(
      new CustomEvent('input', {
        // TODO: does this actually work? It is not composed, nor bubbling
        detail,
      })
    );
  }

  /**
   * emits a change Event
   *
   * @param {string} detail is the selected value
   * @memberof MenuBar
   * @private
   */
  @event({
    eventName: 'change',
  })
  emitChangeEvent(detail: string): void {
    this.dispatchEvent(
      new CustomEvent('change', {
        // TODO: does this actually work? It is not composed, nor bubbling
        detail,
      })
    );
  }

  @queryAssignedNodes('', true, '[zui-internal-is-menubar-item]')
  private _assignedMenuItems: MenubarItem[] | MenubarNavItem[];

  private _propagateValues(): void {
    this._assignedMenuItems.forEach((item) => {
      item.variant = this.variant;
      if (this.selected) {
        item.emphasis = 'default';
        if (item.value === this.selected) {
          item.emphasis = this.emphasis;
        }
      }
      item.disabled = this.disabled;
      if (isMenubarNavItem(item)) {
        item.opened = this.opened;
      }
    });
  }

  private _handleSlotChange(): void {
    this._propagateValues();
  }

  private _handleMenubarItemSelected(menuActionEvent: CustomEvent): void {
    if (this.autoClose && this.opened) {
      this.opened = false;
    }

    this.selected = menuActionEvent.detail.value;

    // TODO: this looks odd...
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.emitChangeEvent(this.selected!);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.emitInputEvent(this.selected!);
  }

  private _handleToggleMenubar(): void {
    this.opened = !this.opened;
  }

  private _menubarToggleButton(expandable: boolean): TemplateResult | typeof nothing {
    return expandable
      ? html`
          <button class="menubar-toggle" type="button" @click=${this._handleToggleMenubar}>
            ${this.opened
              ? html` <zui-icon-arrow-outline-arrow-outline-visually-centered-left
                  size="m"
                ></zui-icon-arrow-outline-arrow-outline-visually-centered-left>`
              : html`<zui-icon-arrow-outline-arrow-outline-visually-centered-right
                  size="m"
                ></zui-icon-arrow-outline-arrow-outline-visually-centered-right>`}
          </button>
        `
      : nothing;
  }

  // because we are meddling with the contents of the slot, we have to run,
  // **after** the component has rendered
  protected updated(changedProperties: Map<string, unknown>): void {
    super.updated(changedProperties);
    this._propagateValues();
  }

  protected render(): TemplateResult {
    return html`
      <nav id="menubar" class="menubar">
        <div class="menubar-items">
          <slot @slotchange=${this._handleSlotChange} @menubar-item-selected=${this._handleMenubarItemSelected}> </slot>
        </div>
        ${this._menubarToggleButton(this.expandable)}
      </nav>
    `;
  }
}

// this is kept for compatibility reasons
// eslint-disable-next-line jsdoc/require-example
/**
 * @deprecated
 * @private
 */
export class MenuBar extends Menubar {
  constructor() {
    super();
    console.warn(
      'The usage of MenuBar has been deprecated and it will be removed in the next major release! It has been renamed to Menubar'
    );
  }

  connectedCallback(): void {
    super.connectedCallback();
    console.warn(
      'The usage of MenuBar has been deprecated and it will be removed in the next major release! It has been renamed to Menubar'
    );
  }
}
