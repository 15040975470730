import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../host.styles';
import { BaseElement } from '../base/BaseElement';
import style from './popover.component.scss';

const popoverStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * A popover component is a container for arbitrary content with specific colours, margins, and a shadow.
 *
 * If you want to show and position zui-popover, for example with hover on button, you can
 * use our component: zui-tooltip-directive. Use example below.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=10464%3A2354)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102407)
 *
 * @example
 * HTML:
 *
 * ## Standard
 * ```html
 * <zui-popover></zui-popover>
 * ```
 *
 * ## Use with tooltip directive
 * ```html
 * <zui-button>
 *   Hover Me
 *   <zui-tooltip-directive anchoring="component" trigger="hover">
 *     <zui-popover></zui-popover>
 *   </zui-tooltip-directive>
 * </zui-button>
 * ```
 *
 * @slot - This is the default slot. It's an innerHtml of the popover-element
 */
@customElement('zui-popover')
export class Popover extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, popoverStyles];
  }

  protected render(): TemplateResult | void {
    return html`<slot></slot>`;
  }
}
