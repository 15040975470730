import { CSSResultArray, TemplateResult, customElement, html, property } from 'lit-element';
import { css, unsafeCSS } from 'lit-element/lib/css-tag';
import { BaseElement } from '../../base/BaseElement';
import { query } from 'lit-element/lib/decorators';
import { nothing } from 'lit-html/lib/part';
import { event } from '../../../decorators/event.decorator';
import { ifDefined } from 'lit-html/directives/if-defined';
import { getDefaultLocale } from '../../date-picker/utils/date-picker.utils';
import { DateTime } from 'luxon';
import { isDefined } from '../../../utils/component.utils';
import { getUpdatedDate, hasDayTime, timePickerInputValueConverter, applyHourCycle } from '../utils/time-picker.utils';
import { EventWithTarget } from '../../../types';
import { traverseDOMSiblingsByStepAndDirection } from '../../../utils/dom.utils';
import { PropertyValues } from 'lit-element/lib/updating-element';

import { TimePicker } from '../time-picker/time-picker.component';
import { TimePickerDayTimeToggle } from '../time-picker-day-time-toggle/time-picker-day-time-toggle.component';
import { TimePickerInputPartDayTime } from '../time-picker-input-part-day-time/time-picker-input-part-day-time.component';
import { TimePickerInputPartNumber } from '../time-picker-input-part-number/time-picker-input-part-number.component';
import '../../interactive-icon/interactive-icon.component';

interface TimePickerInputValue {
  dayTime?: TimePickerDayTimeToggle['value'];
  hour?: number;
  minute?: number;
}

import { hostStyles } from '../../../host.styles';
import styles from './time-picker-input.component.scss';

const timePickerInputComponentStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The `zui-time-picker-input` is part of the `zui-textfield-time-picker` and is not designed for single usage.
 * It shows hour and minute input parts and an additional day time input dependent on the given locale.
 * When the optional `hour-cycle` is set to a twelve hour cycle "h12" the day time input is shown and the given locale is ignored.
 * The related input placeholders can be overriden and the hour and minute input placeholder widths can be adjusted via the documented css properties.
 *
 * @example
 * html```
 * <zui-time-picker-input></zui-time-picker-input>
 * ```
 *
 * @fires CustomEvent - the `time-picker-input` event is emitted on input
 * @fires CustomEvent - the `time-picker-input-changed` event is emitted when the input value has changed
 * @fires CustomEvent - the `time-picker-input-toggle` event is emitted when the interactive icon is selected
 *
 * @cssprop --zui-time-picker-input-hour-placeholder-width - width of the hour input when no value is present - default: 20px
 * @cssprop --zui-time-picker-input-minute-placeholder-width - width of the minute input when no value is present - default: 24px
 * @cssprop --zui-time-picker-input-width - width of the input - default: 100%
 */
@customElement('zui-time-picker-input')
export class TimePickerInput extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, timePickerInputComponentStyles];
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  private static readonly MAX_12_HOURS = 12;
  private static readonly MAX_24_HOURS = 23;
  private static readonly MIN_12_HOURS = 1;
  private static readonly MIN_24_HOURS = 0;
  private static readonly MAX_MINUTES = 59;
  private static readonly MIN_MINUTES = 0;
  private static readonly MAX_CHARACTER_LENGTH = 2;
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * hour cycle
   *
   * @returns {'h12' | 'h23'} locale dependent or overridden hour cycle
   */
  @property({ reflect: true, type: String, attribute: 'hour-cycle' })
  get hourCycle(): TimePicker['hourCycle'] {
    if (!this._internalHourCycle) {
      return hasDayTime(this.locale) ? 'h12' : 'h23';
    }

    return this._internalHourCycle;
  }

  set hourCycle(value: TimePicker['hourCycle']) {
    const oldValue = this._internalHourCycle;
    this._internalHourCycle = value;

    this.requestUpdate('hourCycle', oldValue);
  }

  /**
   * whether the input is valid or not
   */
  @property({ reflect: true, type: Boolean })
  invalid = false;

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * placeholder day time
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-day-time' })
  placeholderDayTime: TimePickerDayTimeToggle['value'] = 'AM';

  /**
   * placeholder hour
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-hour' })
  placeholderHour = 'HH';

  /**
   * placeholder minute
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-minute' })
  placeholderMinute = 'MM';

  /**
   * readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * step hour
   *
   * @private
   */
  @property({ reflect: true, type: Number, attribute: 'step-hour' })
  stepHour = 1;

  /**
   * step minute
   *
   * @private
   */
  @property({ reflect: true, type: Number, attribute: 'step-minute' })
  stepMinute = 1;

  /**
   * selected value
   */
  @property({ reflect: true, type: String, converter: timePickerInputValueConverter })
  value: TimePickerInputValue | undefined;

  /**
   * default value
   */
  @property({ reflect: true, type: String, attribute: 'default-value', converter: timePickerInputValueConverter })
  defaultValue: TimePickerInputValue | undefined;

  /**
   * @param detail object
   * @param detail.dayTime day time
   * @param detail.hour hour
   * @param detail.minute minute
   *
   * @private
   */
  @event({
    eventName: 'time-picker-input-input',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputInputEvent(detail: TimePickerInputValue): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-input', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * @param value date time | undefined
   *
   * @private
   */
  @event({
    eventName: 'time-picker-input-changed',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputChangedEvent(value: DateTime | undefined): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-changed', {
        bubbles: true,
        composed: true,
        detail: {
          value: value?.toISO() ?? null,
        },
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'time-picker-input-toggle',
    bubbles: true,
    composed: true,
  })
  emitTimePickerInputToggleEvent(): void {
    this.dispatchEvent(
      new CustomEvent('time-picker-input-toggle', {
        bubbles: true,
        composed: true,
      })
    );
  }

  @query('zui-time-picker-input-part-number#hour')
  private _inputHourRef: TimePickerInputPartNumber;

  @query('zui-time-picker-input-part-number#minute')
  private _inputMinuteRef: TimePickerInputPartNumber;

  @query('zui-time-picker-input-part-day-time')
  private _inputDayTimeRef: TimePickerInputPartDayTime;

  private _internalHourCycle: TimePicker['hourCycle'];

  private _isValidNextElement = (element: HTMLElement): boolean =>
    element.tagName.toLowerCase() === 'zui-time-picker-input-part-number' ||
    element.tagName.toLowerCase() === 'zui-time-picker-input-part-day-time';

  private get _is12HourFormat(): boolean {
    return this.hourCycle === 'h12';
  }

  private get _literal(): string {
    return DateTime.now()
      .setLocale(this.locale)
      .toLocaleParts({ hour: 'numeric', minute: 'numeric' })
      .find(({ type }) => type === 'literal').value;
  }

  private get _hideLiteral(): boolean {
    // only hide the literal in readonly and no value at all
    return this.readonly && !isDefined(this.value);
  }

  private _handleFocusNextTimePickerInputPart(
    event: EventWithTarget<TimePickerInputPartNumber | TimePickerInputPartDayTime>
  ): void {
    traverseDOMSiblingsByStepAndDirection(event.target, 'next', 1, this._isValidNextElement)?.focus();
  }

  private _handleFocusPreviousTimePickerInputPart(event: EventWithTarget<TimePickerInputPartNumber>): void {
    traverseDOMSiblingsByStepAndDirection(event.target, 'previous', 1, this._isValidNextElement)?.focus();
  }

  private _handleHourCycleChange(): void {
    if (!isDefined(this.value)) {
      return;
    }

    this.value = applyHourCycle(this.value, this.hourCycle);
  }

  private _updateValue(): void {
    const hasMissingValue =
      !isDefined(this.value?.hour) ||
      !isDefined(this.value?.minute) ||
      (this._is12HourFormat && !isDefined(this.value?.dayTime));

    const updatedDate = !hasMissingValue
      ? getUpdatedDate(
          // TODO: get rid of non-null-assertion
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          { hour: this.value!.hour!, minute: this.value!.minute! },
          DateTime.now(),
          this._is12HourFormat ? 'h12' : 'h23',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.value!.dayTime!
        )
      : undefined;

    this.emitTimePickerInputChangedEvent(updatedDate);
  }

  private _handleTimePickerInputPartHourChangedEvent(): void {
    this.value = {
      ...this.value,
      hour: this._inputHourRef.value ?? undefined,
    };

    this._updateValue();
  }

  private _handleTimePickerInputPartMinuteChangedEvent(): void {
    this.value = {
      ...this.value,
      minute: this._inputMinuteRef.value ?? undefined,
    };

    this._updateValue();
  }

  private _handleTimePickerInputPartDayTimeChangedEvent(): void {
    this.value = {
      ...this.value,
      dayTime: this._inputDayTimeRef.value ?? undefined,
    };

    this._updateValue();
  }

  private _handleTimePickerInputEvent(
    event: EventWithTarget<TimePickerInputPartNumber | TimePickerInputPartDayTime, CustomEvent>
  ): void {
    this.emitTimePickerInputInputEvent({
      dayTime: event.target === this._inputDayTimeRef ? event.detail.value : this._inputDayTimeRef?.value,
      hour: event.target === this._inputHourRef ? event.detail.value : this._inputHourRef.value,
      minute: event.target === this._inputMinuteRef ? event.detail.value : this._inputMinuteRef.value,
    });
  }

  private _handleTimePickerInputPartHourKeydownEvent(
    event: EventWithTarget<TimePickerInputPartNumber, KeyboardEvent>
  ): void {
    if (this.readonly) {
      return;
    }

    switch (event.key) {
      case this._literal:
        {
          event.preventDefault();

          this._handleFocusNextTimePickerInputPart(event);
        }
        break;
      default:
        break;
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('hourCycle')) {
      this._handleHourCycleChange();
    }
  }

  protected render(): TemplateResult {
    return html`
      <div
        class="time-picker-input-parts"
        @time-picker-input-part-focus-next="${this._handleFocusNextTimePickerInputPart}"
        @time-picker-input-part-focus-previous="${this._handleFocusPreviousTimePickerInputPart}"
        @time-picker-input-part-number-input="${this._handleTimePickerInputEvent}"
      >
        <zui-time-picker-input-part-number
          ?disabled="${this.disabled}"
          ?readonly="${this.readonly}"
          id="hour"
          max="${this._is12HourFormat ? TimePickerInput.MAX_12_HOURS : TimePickerInput.MAX_24_HOURS}"
          min="${this._is12HourFormat ? TimePickerInput.MIN_12_HOURS : TimePickerInput.MIN_24_HOURS}"
          placeholder="${this.placeholderHour}"
          step="${this.stepHour}"
          value="${ifDefined(this.value?.hour)}"
          default-value="${ifDefined(this.defaultValue?.hour)}"
          @keydown="${this._handleTimePickerInputPartHourKeydownEvent}"
          @time-picker-input-part-number-changed="${this._handleTimePickerInputPartHourChangedEvent}"
        >
        </zui-time-picker-input-part-number>
        <span class="time-picker-input-part-literal" ?hidden="${this._hideLiteral}">${this._literal}</span>
        <zui-time-picker-input-part-number
          ?disabled="${this.disabled}"
          ?readonly="${this.readonly}"
          id="minute"
          max="${TimePickerInput.MAX_MINUTES}"
          min="${TimePickerInput.MIN_MINUTES}"
          placeholder="${this.placeholderMinute}"
          step="${this.stepMinute}"
          value="${ifDefined(this.value?.minute)}"
          default-value="${ifDefined(this.defaultValue?.minute)}"
          @time-picker-input-part-number-changed="${this._handleTimePickerInputPartMinuteChangedEvent}"
        >
        </zui-time-picker-input-part-number>
        ${this._is12HourFormat
          ? html`
              <zui-time-picker-input-part-day-time
                ?disabled="${this.disabled}"
                ?readonly="${this.readonly}"
                placeholder="${this.placeholderDayTime}"
                value="${ifDefined(this.value?.dayTime)}"
                @time-picker-input-part-day-time-changed="${this._handleTimePickerInputPartDayTimeChangedEvent}"
                @time-picker-input-part-day-time-input="${this._handleTimePickerInputEvent}"
              >
              </zui-time-picker-input-part-day-time>
            `
          : nothing}
      </div>
      <zui-interactive-icon
        ?disabled="${this.disabled || this.readonly}"
        @click="${this.emitTimePickerInputToggleEvent}"
      >
        <zui-icon-time-time-time size="m"></zui-icon-time-time-time>
      </zui-interactive-icon>
    `;
  }
}
