import { BaseElement } from '../base/BaseElement';
import { property } from 'lit-element';

type Step = number | 'any';

export class SliderBaseClass extends BaseElement {
  /**
   * The min value of the slider.
   */
  @property({ reflect: true, type: Number })
  min = 0;

  /**
   * The max value of the slider.
   */
  @property({ reflect: true, type: Number })
  max = 1;

  /**
   * the step value for the slider. defines what values can be selected by the user.
   */
  @property({ reflect: true })
  step: Step = 'any';

  /**
   * the start value of the active line
   * defaults to negative infinity to ensure it defaults to the very beginning of the slider
   */
  @property({ reflect: true, type: String, attribute: 'active-line-start' })
  activeLineStart: 'min' | 'max' | number = 'min';
}
