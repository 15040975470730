import { css, CSSResultArray, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import { BaseElement } from '../base/BaseElement';
import { hostStyles } from '../../host.styles';
import style from './loader-box.component.scss';
import { classMap } from 'lit-html/directives/class-map';

const loaderBoxStyles = css`
  ${unsafeCSS(style)}
`;

type Mode = 'activity' | 'progress';
type Content = 'description-only' | 'headline-only' | 'headline-with-description';

/**
 * This component combines a progress ring with several texts.
 *
 * It is only available in translucent theme.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-loader-box headline="Headline" description="Description"></zui-loader-box>
 * ```
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=45966%3A1)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---6.1?node-id=13009%3A2769)
 *
 */
@customElement('zui-loader-box')
export class LoaderBox extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, loaderBoxStyles];
  }

  /**
   * Defines headline
   */
  @property({ reflect: true, type: String })
  headline = '';

  /**
   * Defines description
   */
  @property({ reflect: true, type: String })
  description = '';

  /**
   * Defines mode
   */
  @property({ reflect: true, type: String })
  mode: Mode = 'activity';

  constructor() {
    super();

    // loader-box should always have translucent theme.
    this.theme = 'translucent';
  }

  // maps from component state _content to a css class
  private get _contentClass(): Content | '_' {
    if (this.headline && this.headline.trim() && this.description && this.description.trim()) {
      return 'headline-with-description';
    }
    if (this.headline && this.headline.trim()) {
      return 'headline-only';
    }
    if (this.description && this.description.trim()) {
      return 'description-only';
    }

    // return '_' as special css class, that is never used; but we have to return something...
    return '_';
  }

  protected render(): TemplateResult | void {
    return html`<div id="container" class="${classMap({ [this._contentClass]: true })}">
      <zui-progress-ring size="l"></zui-progress-ring>
      <div id="headline">${this.headline}</div>
      <div id="description">${this.description}</div>
    </div>`;
  }
}
