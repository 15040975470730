import {
  css,
  CSSResult,
  customElement,
  html,
  internalProperty,
  property,
  PropertyValues,
  query,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { styleMap } from 'lit-html/directives/style-map';
import { BaseElement } from '../../base/BaseElement';
import { event } from '../../../decorators/event.decorator';
import { Breakpoint } from '../../../types';
import { compareMediaBreakpoint, getBreakpointForWidth } from '../../../utils/component.utils';

import { UserMenuButton } from '../../user-menu-button/user-menu-button/user-menu-button.component';
import { Searchbar } from '../../searchbar/searchbar/searchbar.component';

import { hostStyles } from '../../../host.styles';
import styles from './headerbar.component.scss';

const headerbarStyles = css`
  ${unsafeCSS(styles)}
`;

// min width of 320px and a 24px padding on each side
const SEARCHBAR_TOTAL_WIDTH = 378;

/**
 * The Headerbar component is displayed at the top of the page.
 *
 * ## Figma
 * - [Web - Component Library](https://www.figma.com/file/z4fyXFOJCpuaNImx3K234n/❖-04-Web---Component-Library---1.4?node-id=1%3A12)
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=3279%3A57420&viewport=279%2C103%2C0.15313252806663513)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-headerbar>
 *   <zui-icon-zeiss size="xl" slot="icon"></zui-icon-zeiss>
 *   <zui-headerbar-product-name product-name="ZUi" product-sub-name="web component library" slot="productName"></zui-headerbar-product-name>
 * </zui-headerbar>
 * ```
 *
 * ```html
 * <zui-headerbar>
 *   <zui-icon-zeiss size="xl" slot="icon"></zui-icon-zeiss>
 *   <zui-headerbar-product-name product-name="ZUi" product-sub-name="web component library" slot="productName"></zui-headerbar-product-name>
 * </zui-headerbar>
 * ```
 *
 * ```html
 * <zui-headerbar show-searchbar>
 *   <zui-icon-zeiss size="xl" slot="icon"></zui-icon-zeiss>
 *   <zui-headerbar-product-name product-name="ZUi" product-sub-name="web component library" slot="productName"></zui-headerbar-product-name>
 *   <zui-searchbar placeholder="Enter your search here..." slot="searchbar"></zui-searchbar>
 *   <zui-headerbar-icon-button emphasis="default" size="m" slot="iconButtons">
 *     <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 *   </zui-headerbar-icon-button>
 *   <zui-headerbar-notification-button has-notifications emphasis="default" size="m" slot="iconButtons">
 *     <zui-icon-alarm-on slot="icon"></zui-icon-alarm-on>
 *     <zui-state-dot slot="stateDot" state="error"></zui-state-dot>
 *   </zui-headerbar-notification-button>
 *   <zui-user-menu-button size="l" slot="userMenuButton" user-name="User" user-role="Role">
 *     <zui-avatar initials="ZW" slot="avatar"></zui-avatar>
 *   </zui-user-menu-button>
 * </zui-headerbar>
 * ```
 *
 * @slot icon - slot for an icon
 * @slot productName - slot for a product name
 * @slot searchbar - slot for a searchbar
 * @slot iconButtons - slot for icon buttons
 * @slot userMenuButton - slot for a user menu button
 * @fires {CustomEvent} headerbar-searchbar-icon-selected - The headerbar-searchbar-icon-selected event is fired when the search button that is only visible for resolutions lower than 1024px is clicked
 */
@customElement('zui-headerbar')
export class Headerbar extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, headerbarStyles];
  }

  /**
   * Headerbar media
   */
  @property({ reflect: true, type: String })
  media = Breakpoint.L;

  /**
   * whether to show or hide the searchbar
   */
  @property({ reflect: true, type: Boolean, attribute: 'show-searchbar' })
  showSearchbar = false;

  /**
   * currently there is no dark theme for the headerbar! it should be always displayed with theme light!
   *
   * @private
   */
  @property({ reflect: true, type: String })
  theme = 'light';

  /**
   * Emits a custom `headerbar-searchbar-icon-selected` event
   *
   * @private
   */
  @event({ eventName: 'headerbar-searchbar-icon-selected', bubbles: true, composed: true })
  emitHeaderbarSearchbarIconSelectedEvent(): void {
    this.dispatchEvent(new CustomEvent('headerbar-searchbar-icon-selected', { bubbles: true, composed: true }));
  }

  @internalProperty()
  private _hasSearchbar = false;

  @internalProperty()
  private _width: number;

  @internalProperty()
  private _widthRight: number;

  private get _maxOfRightWidth(): number {
    return this._widthRight ?? 0;
  }

  @query('.right-aligned-content')
  private _rightAlignedContentElement: HTMLDivElement;

  @queryAssignedNodes('searchbar', true, 'zui-searchbar')
  private _assignedSearchbar: NodeListOf<Searchbar>;

  @queryAssignedNodes('userMenuButton', true, 'zui-user-menu-button')
  private _assignedUserMenuButtons: NodeListOf<UserMenuButton>;

  // the searchbar is only shown for breakpoints larger than s
  private get _isMediaLargerSmall(): boolean {
    return compareMediaBreakpoint(this.media, '>', Breakpoint.S);
  }

  private get _maxRightWidth(): string {
    return this._hasSearchbar && this._isMediaLargerSmall ? `${this._maxOfRightWidth}px` : 'auto';
  }

  private get _leftAlignedWidth(): 'max-width' | 'width' {
    return this._hasSearchbar && this._isMediaLargerSmall ? 'max-width' : 'width';
  }

  // maximum space for left aligned content results of the main width, the maximum of the right aligned content
  // and the minimum space for the searchbar
  private get _maxLeftWidth(): string {
    return this._hasSearchbar && this._isMediaLargerSmall
      ? `${this._width - this._maxOfRightWidth - SEARCHBAR_TOTAL_WIDTH}px`
      : 'auto';
  }

  private get _showSearchbar(): boolean {
    return this._isMediaLargerSmall && this._width - 2 * this._maxOfRightWidth > SEARCHBAR_TOTAL_WIDTH;
  }

  private _headerbarResizeObserver = new ResizeObserver(([{ contentRect }]) => {
    requestAnimationFrame(() => {
      this._width = contentRect.width;
      this.media = getBreakpointForWidth(contentRect.width);
    });
  });

  private _rightAlignedContentResizeObserver = new ResizeObserver(([{ contentRect }]) => {
    requestAnimationFrame(() => {
      this._widthRight = contentRect.width;
    });
  });

  disconnectedCallback(): void {
    this._headerbarResizeObserver.unobserve(this);
    this._rightAlignedContentResizeObserver.unobserve(this._rightAlignedContentElement);

    super.disconnectedCallback();
  }

  private _handleHeaderbarSearchbarIconSelected(): void {
    this.emitHeaderbarSearchbarIconSelectedEvent();
  }

  private _handleHeaderbarSearchbarSlotChange(): void {
    this._hasSearchbar = this._assignedSearchbar?.length > 0;
  }

  private _updateUserMenuButtonAvatarOnly(): void {
    if (Array.from(this._assignedUserMenuButtons).every((userMenuButton) => userMenuButton.avatarOnly)) {
      return;
    }

    const avatarOnly = compareMediaBreakpoint(this.media, '<', Breakpoint.M);

    this._assignedUserMenuButtons.forEach((userMenuButton) => (userMenuButton.avatarOnlyExternally = avatarOnly));
  }

  protected firstUpdated(): void {
    this._headerbarResizeObserver.observe(this);
    this._rightAlignedContentResizeObserver.observe(this._rightAlignedContentElement);
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('media')) {
      this._updateUserMenuButtonAvatarOnly();
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class="left-aligned" style="${styleMap({
        [this._leftAlignedWidth]: this._maxLeftWidth,
      })}">
      <div class="left-aligned-content">
            <slot name="icon"></slot>
            <slot name="productName"></slot>
          </div>
        </div>
        <div class="centered-searchbar ${classMap({ hidden: !this._hasSearchbar || !this._showSearchbar })}">
          <slot name="searchbar" @slotchange="${this._handleHeaderbarSearchbarSlotChange}"></slot>
        </div>
        <div class="right-aligned" style="min-width: ${this._maxRightWidth}">
          ${
            this._hasSearchbar && !this._showSearchbar
              ? html`
                  <zui-headerbar-icon-button
                    @click="${this._handleHeaderbarSearchbarIconSelected}"
                    emphasis="default"
                    id="icon-button-search"
                    size="m"
                  >
                    <zui-icon-search-search slot="icon"></zui-icon-search-search>
                  </zui-headerbar-icon-button>
                `
              : nothing
          }
          <div class="right-aligned-content">
            <div class="slotted-buttons">
              <slot name="iconButtons"></slot>
            </div>
            <slot @slotchange="${this._updateUserMenuButtonAvatarOnly}" name="userMenuButton"></slot>
          </div>
        </div>
      </div>
    `;
  }
}
