import { css, CSSResultArray, customElement, html, property, query, TemplateResult, unsafeCSS } from 'lit-element';
import { event } from '../../decorators/event.decorator';
import { FormValidationMixin } from '../../mixins/form-participation/form-validation.mixin';
import { FormDataHandlingMixin } from '../../mixins/form-participation/form-data-handling.mixin';
import { RealBaseElement } from '../base/BaseElement';
import { FormEnabledElement, FormValidationElement } from '../../mixins/form-participation/form-participation.types';
import { DelegateFocusMixin } from '../../mixins/visual-focus/delegate-focus.mixin';

import { hostStyles } from '../../host.styles';
import style from './checkbox.component.scss';

const checkboxStyles = css`
  ${unsafeCSS(style)}
`;

type SelectionState = boolean | '' | 'mixed';

/**
 * Checkboxes allow the user to select one or more items from a set.
 * They can be used to turn an option on or off.
 *
 * In contrast to the standard HTML checkbox it has an optional "mixed" mode (enabled by the attribute
 * {@link enableMixed}). In mixed mode there are 3 states: `false`, `true` and `mixed`. For this  reason, the
 * {@link value} attribute is not of type `boolean` but instead includes the `"mixed"` as possible value in addition to
 * `true` and `false`. Therefore the {@link value} attribute may not be used like a boolean attribute.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2720)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23637%3A457366)
 *
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-checkbox value="true" name="terms" label="Sign me up for the newsletter"></zui-checkbox>
 *
 * <zui-checkbox name="terms">
 *  <span>I agree to the <a href="/Product/TermsOfUse" target="_blank">terms of use</a></span>
 * </zui-checkbox>
 * ```
 *
 * @fires change - The event that fires when user has changed <code>value</code> by clicking the checkbox
 * @fires input - The event that fires when <code>value</code> has been changed
 * @slot default - This is the default slot. It's an alternative to the `label` attribute.
 */
@customElement('zui-checkbox')
export class Checkbox
  extends FormValidationMixin(FormDataHandlingMixin(DelegateFocusMixin(RealBaseElement)))
  implements FormValidationElement<FormEnabledElement> {
  static get styles(): CSSResultArray {
    return [hostStyles, checkboxStyles];
  }

  /**
   * This enables the selection state *mixed*
   */
  @property({ reflect: true, type: Boolean, attribute: 'enable-mixed' })
  enableMixed = false;

  /**
   * The label of the checkbox
   */
  @property({ reflect: true, type: String })
  label: string;

  /**
   * Whether the checkbox is required or not
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * The tabindex of the checkbox
   */
  @property({ reflect: true, type: Number })
  tabindex = 0;

  /**
   * The value of the checkbox
   * (included in the HTTP request on form submission)
   * The two supported values are *false* and *true*.
   * A third value *'mixed'* is possible when the <code>enableMixed</code>
   * property has the value *true*.
   *
   * The value of the checkbox is not and should not be used like boolean attribute.
   * A value of this parameter should be passed explicitly: <code>value="true"</code>
   *
   * @returns value
   */
  @property({
    reflect: true,
    type: String,
    converter: {
      fromAttribute(value: string | null): SelectionState {
        let propValue: SelectionState;
        switch (value) {
          case 'true':
            propValue = true;
            break;
          case 'false':
            propValue = false;
            break;
          case 'mixed':
            propValue = 'mixed';
            break;
          default:
            propValue = false;
            break;
        }
        return propValue;
      },
      toAttribute(value: SelectionState): string {
        if (value === '') {
          return 'false';
        } else {
          return String(value);
        }
      },
    },
  })
  value: SelectionState = false;

  /**
   * emits a change Event
   *
   * @private
   */
  @event({
    eventName: 'change',
    bubbles: true,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        // we mimic the default behavior of checkboxes in the ShadowDOM
        // change events are not composed
        composed: false,
      })
    );
  }

  /**
   * emits an input Event
   *
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    composed: true,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      //standard HTML <input type=checkbox> only emits type Event instead of InputEvent
      new Event('input', {
        bubbles: true,
        composed: true,
      })
    );
  }

  @query('div[role="checkbox"]')
  private _checkboxDivRef: HTMLDivElement;

  /**
   * This returns the state value for the rendering
   *
   * @returns the actual display value
   */
  // TODO: instead of returning undefined, re-work structure, that all branches return specific values, i.e. get rid of if-else-if
  private get _displayValue(): 'false' | 'true' | 'mixed' | undefined {
    if (this.value === null || this.value === '' || this.value === false) {
      return 'false';
    } else if (this.value === true) {
      return 'true';
    } else if (this.value === 'mixed') {
      return this.enableMixed ? 'mixed' : 'true';
    }
  }

  constructor() {
    super();

    this.addValidator({
      type: 'valueMissing',
      validator: () => {
        const hasMixedValue = this.required === true ? this.value === true || this.value === 'mixed' : true;
        const hasValue = this.required === true ? this.value === true : true;

        return this.enableMixed ? hasMixedValue : hasValue;
      },
      validatesOnProperties: ['required'],
    });
  }

  /**
   * Handler for the click event.
   */
  private _handleClick(event: Event): void {
    if (!this.disabled && !this.readonly) {
      this._updateValue();
    } else {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  private _handleKeyUp({ code }: KeyboardEvent): void {
    switch (code) {
      case 'Space':
        this._checkboxDivRef.click();
        break;
    }
  }

  /**
   * Handler the state change of the checkbox. Once the user clicks/uses the spacebar on the
   * checkbox it changes its value (state):
   * false -> true -> false
   * or if the third state is enabled by setting <code>enableMixed</code> to true:
   * false -> mixed -> true -> false
   */
  private _updateValue(): void {
    if (this.enableMixed) {
      if (this.value === 'mixed') {
        this.value = true;
      } else {
        this.value = this.value ? false : 'mixed';
      }
    } else {
      this.value = !this.value;
    }
    this.emitInputEvent();
    this.emitChangeEvent();
  }

  protected render(): TemplateResult | void {
    return html`
      <div
        ?zuiCaptureFocus=${!this.disabled}
        class="checkbox-and-label-wrapper ${`value-${this._displayValue}`}"
        aria-labelledby="label"
        aria-checked="${this._displayValue}"
        role="checkbox"
        tabindex="0"
        @click=${this._handleClick}
        @keyup=${this._handleKeyUp}
      >
        <div class="checkbox-container">
          <svg id="checkbox" xmlns="http://www.w3.org/2000/svg">
            <rect id="box" x="0.5" y="0.5" rx="2.5" />
            <path
              id="checkmark"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.89458 7.43867L4.88892 8.44434L6.39742 9.95284L7.40308 	10.9585L7.40308 10.9585L11.4257 6.93584L10.4201 5.93018L7.40308 8.94717L5.89458 7.43867Z"
            />
            <rect id="mixed-icon" x="4" y="4" rx="1" />
          </svg>
        </div>
        <div class="label-container"
          ><label id="label">
            <slot>${this.label}</slot>
          </label></div
        >
      </div>
    `;
  }
}
