import { property, PropertyValues } from 'lit-element';
import { BaseElement } from './BaseElement';

// prettier-ignore
type IconSize =           's8' | 's12' | 's16' | 's24' | 's32' | 's48' | 's64' | 's72' | 's80';
/**
 * @deprecated
 */
// prettier-ignore
type IconSizeDeprecated = 'xs' | 's'   | 'm'   | 'l'   | 'xl'  | '2xl' | '3xl' | never | '4xl';

export abstract class IconBaseElement<TLegacySize = never> extends BaseElement {
  /* eslint-disable @typescript-eslint/naming-convention */
  static readonly MAP_ICON_SIZE_TO_DEPRECATED: Record<IconSize, IconSizeDeprecated> = {
    s8: 'xs',
    s12: 's',
    s16: 'm',
    s24: 'l',
    s32: 'xl',
    s48: '2xl',
    s64: '3xl',
    // TODO: this is cheating
    s72: '3xl',
    s80: '4xl',
  };
  static readonly VALID_ICON_SIZES = Object.keys(IconBaseElement.MAP_ICON_SIZE_TO_DEPRECATED);
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * helper property to export deprecated type
   *
   * @private
   * @deprecated
   */
  sizeDeprecated?: IconSizeDeprecated;

  /**
   * tagging property used to use *[zuiIcon] as a querySelector for zuiIcons
   *
   * @private
   * @deprecated The new icons use 'zui-icon' as tagging attribute. Change when we switch to the new icons.
   */
  @property({ reflect: true, attribute: 'zuiIcon', type: Boolean })
  readonly isIconDeprecated = true;

  /**
   * tagging property used to use *[zui-icon] as a querySelector for zuiIcons
   *
   * @private
   */
  @property({ reflect: true, attribute: 'zui-icon', type: Boolean })
  readonly isIcon = true;

  /**
   * Size of icon
   */
  @property({ reflect: true, attribute: 'size', type: String })
  size: IconSize | TLegacySize;

  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);
    // generic deprecation warning; TODO: has to be removed, once there are no deprecations anymorez
    if (changedProperties.has('size')) {
      if (!IconBaseElement.VALID_ICON_SIZES.includes(this.size as IconSize)) {
        console.warn(
          `Deprecated/unsupported size: ${
            this.size
          } was used on ${this.tagName.toLowerCase()}! Use string-based-unit (e.g. s16...) instead.`
        );
      }
    }
  }
}
