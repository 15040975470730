import { css, customElement, property, query, queryAssignedNodes, TemplateResult, unsafeCSS } from 'lit-element';
import { html } from 'lit-html';

import style from './slider-scale.component.scss';
import { hostStyles } from '../../../host.styles';
import { countDecimals, format } from '../../../utils/format.utils';
import { classMap } from 'lit-html/directives/class-map';
import { state } from 'lit-element/lib/decorators';
import { SliderBaseClass } from '../slider-base.class';
import { SliderTickLabel } from '../slider-tick-label/slider-tick-label.component';
import { Decimal } from 'decimal.js';

type Orientation = 'horizontal' | 'vertical';

const sliderStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The slider scale includes all the logic and styling for the scale and should always be used along the
 * zui-slder-basic component in the wrapping zui-slider component.
 *
 * **Important note 1**: The slider only works properly if the step value is  integer divisible with regard to the
 *  minimum / maximum value
 *
 * **Important note 2**: The usage of the label format can be looked up here: https://github.com/alexei/sprintf.js/
 *  some examples:
 *
 * - "%s" for strings
 * - "%.2f" for floats with a certain amount of decimal places
 * - "%f" for pure floats
 * - "+%.2f%%" for having a "+" as prefix and "%" as suffix
 *
 * @example
 *
 * ```html
 *  <zui-slider-scale
 *    min="0"
 *    max="10"
 *    tick-interval="0.5"
 *    label-interval="1"
 *    label-format="$ %.2f"
 *    readonly
 *    >
 *      <zui-slider-tick-label>one</<zui-slider-tick-label>
 *      <zui-slider-tick-label>two</<zui-slider-tick-label>
 *    </zui-slider-scale>
 * ```
 *
 * @cssprop --zui-slider-padding- Defines a custom offset for the scale (set it without an unit!)
 * @cssprop --zui-slider-min-padding - Defines a custom offset for the left-handed mininum area for the scale (set it without an unit!)
 * @cssprop --zui-slider-max-padding - Defines a custom offset for the right-handed maximum area for the scale (set it without an unit!)
 * @slot - The default slot, used for the custom tick labels
 */
@customElement('zui-slider-scale')
export class SliderScale extends SliderBaseClass {
  static readonly styles = [hostStyles, sliderStyles];

  /**
   * the tick interval for the slider
   */
  @property({ reflect: true, type: Number, attribute: 'tick-interval' })
  tickInterval = 0;

  /**
   * the label interval for the slider
   */
  @property({ reflect: true, type: Number, attribute: 'label-interval' })
  labelInterval = 0;

  /**
   * the orientation can be either horizontal or vertical; horizontal by default
   */
  @property({ reflect: true, type: String })
  orientation: Orientation = 'horizontal';

  /**
   * the format template of the label. Use https://github.com/alexei/sprintf.js/ as a reference.
   */
  @property({ reflect: true, type: String, attribute: 'label-format' })
  labelFormat: string;

  @queryAssignedNodes('', true, 'zui-slider-tick-label')
  private _customTickLabelElements: SliderTickLabel[];

  // returns the format string template, used for the labels
  private get _derivedLabelFormat(): string {
    // when there is no label format set at all, set it to a float number with the label interval given decimal places
    if (this.labelFormat === undefined) {
      return `%.${countDecimals(this.labelInterval)}f`;
    }

    return this.labelFormat;
  }

  @state()
  private _customLabels: string[] = [];

  // returns the final array of labels, containing as much custom labels as slotted
  private get _derivedTickLabelArray(): string[] {
    return this._calcTickAndLabelArray(this.labelInterval).map((label, labelIndex) =>
      this._customLabels[labelIndex]
        ? this._getFormattedLabel(this._customLabels[labelIndex], label)
        : this._getFormattedLabel(this._derivedLabelFormat, label)
    );
  }

  private _getFormattedLabel(template, value): string {
    let label = value;
    try {
      label = format(template, value);
    } catch (error) {
      console.warn('error formatting template string: ', error);
    }
    return label;
  }

  /**
   * Creates an array of generic ticks and labels
   *
   * @param interval the interval that is used for calculation
   *
   * @returns {Array} array of ticks and labels
   */
  private _calcTickAndLabelArray(interval: number): number[] {
    if (interval === 0) {
      return [];
    }

    const normalizedRange = this.max - this.min;

    if (!Number.isInteger(normalizedRange / Number(this.tickInterval))) {
      console.warn('Tick interval is not integer divisible with regard to the min-max range!');
    }

    // we have to add an extra step for the zero
    const length = Math.floor(normalizedRange / Number(interval)) + 1;
    return Array.from({ length }, (v, label) => {
      // we calculate with Decimals.js: decimalLabel = this.min + label * this.labelInterval;
      const decimalLabel = new Decimal(label).mul(this.labelInterval).add(this.min).toNumber();

      return decimalLabel;
    });
  }

  private _handleSlotChange(): void {
    this._customLabels = this._customTickLabelElements.map((label) => (label.textContent ? label.textContent : ''));
  }

  protected render(): TemplateResult {
    return html` <div class="scale-container">
      <div id="tick-container">
        ${this._calcTickAndLabelArray(this.tickInterval).map(() => html`<div class="tick"></div>`)}
      </div>
      <div id="label-container">
        <slot @slotchange="${this._handleSlotChange}"></slot>
        ${this._derivedTickLabelArray.map((tickLabel, currentIndex, tickLabels) => {
          // we're using an invisible helper tick to align the label properly and add the
          // decimal places in the template, which makes the handling easier in this case

          const isFirstLabel = currentIndex === 0;
          const isLastLabel = currentIndex === tickLabels.length - 1;
          // the required font will exceed the track after more than four chars
          const longLabel = tickLabel ? tickLabel.length >= 4 : false;

          return html` <div
            class="${classMap({
              tick: true,
              first: isFirstLabel && longLabel,
              last: isLastLabel && longLabel,
            })}"
            style="---zui-slider-tick-width: 0;"
          >
            <span
              class="${classMap({
                label: true,
                first: isFirstLabel && longLabel,
                last: isLastLabel && longLabel,
              })}"
            >
              ${tickLabel}
            </span>
          </div>`;
        })}
      </div>
    </div>`;
  }
}
