import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  css,
  unsafeCSS,
  PropertyValues,
  queryAll,
} from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import styles from './date-picker-month-picker.component.scss';
import {
  getCalendarMonthsForSelectedYear,
  getDateTimesFromJsDates,
  getDefaultLocale,
  getStartOfMonthName,
  isoDateConverter,
  isSameMonth,
  isSameYear,
  someIsSameMonth,
  someIsSameYear,
} from '../utils/date-picker.utils';
import { DateTime } from 'luxon';
import { event } from '../../../decorators/event.decorator';

import { GridCell } from '../../picker/grid-cell/grid-cell.component';
import '../../picker/header-cell/header-cell.component';
import '../../picker/picker-grid/picker-grid.component';
import '../../picker/picker-header/picker-header.component';

const datePickerMonthPickerStyles = css`
  ${unsafeCSS(styles)}
`;

interface DatePickerMonthPickerYearRange {
  endOfYear: Date;
  startOfYear: Date;
}

type Emphasis = 'default' | 'selected' | 'subtle';

/**
 * The date picker month picker is a feature component that should be used inside the date picker component for selecting months.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-month-picker
 *   locale="en-US"
 *   max="2022-12-31T23:59:59.999+01:00"
 *   min="2020-01-01T00:00:00.000+01:00">
 * </zui-date-picker-month-picker>
 * ```
 * @fires {CustomEvent} month-picker-month-selected - emits the selected month
 * @fires {CustomEvent} monthPickerMonthSelected - (Deprecated) emits the selected month
 * @fires {CustomEvent} month-picker-next-year-selected - emits the start and end of next selected year
 * @fires {CustomEvent} monthPickerNextYearSelected - (Deprecated) emits the start and end of next selected year
 * @fires {CustomEvent} month-picker-previous-year-selected - emits the start and end of previous selected year
 * @fires {CustomEvent} monthPickerPreviousYearSelected - (Deprecated) emits the start and end of previous selected year
 * @fires {CustomEvent} month-picker-year-selected - emits the selected year
 * @fires {CustomEvent} monthPickerYearSelected - (Deprecated) emits the selected year
 */
@customElement('zui-date-picker-month-picker')
export class DatePickerMonthPicker extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, datePickerMonthPickerStyles];
  }

  /**
   * whether the year select is disabled or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'year-select-disabled' })
  yearSelectDisabled = false;

  /**
   * disabled months
   */
  @property({ type: Array, attribute: false })
  disabledMonths: Date[] = [];

  /**
   * disabled years
   */
  @property({ type: Array, attribute: false })
  disabledYears: Date[] = [];

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * max date
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  max: Date | null = null;

  private get _maxDateDT(): DateTime | undefined {
    return this.max ? DateTime.fromJSDate(this.max) : undefined;
  }

  // TODO: remove in version 2.0
  /**
   * (Deprecated) maxDate use max instead
   *
   * @returns Date max date
   *
   * @deprecated use max instead
   */
  @property({ reflect: true, type: String, attribute: 'max-date', converter: isoDateConverter })
  get maxDate(): Date | null {
    return this.max;
  }

  set maxDate(value: Date | null) {
    console.warn('Deprecated property maxDate used. Use max instead.');

    this.max = value;
  }

  /**
   * min date
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  min: Date | null = null;

  private get _minDateDT(): DateTime | undefined {
    return this.min ? DateTime.fromJSDate(this.min) : undefined;
  }

  // TODO: remove in version 2.0
  /**
   * (Deprecated) minDate use min instead
   *
   * @returns Date min date
   *
   * @deprecated use min instead
   */
  @property({ reflect: true, type: String, attribute: 'min-date', converter: isoDateConverter })
  get minDate(): Date | null {
    return this.min;
  }
  set minDate(value: Date | null) {
    console.warn('Deprecated property minDate used. Use min instead.');

    this.min = value;
  }

  /**
   * selected month
   */
  @property({ reflect: true, type: String, attribute: 'selected-month', converter: isoDateConverter })
  selectedMonth: Date | null = null;

  private get _selectedMonthDT(): DateTime | undefined {
    return this.selectedMonth ? DateTime.fromJSDate(this.selectedMonth) : undefined;
  }

  /**
   * current year
   *
   * @returns current year
   */
  @property({ reflect: true, type: String, attribute: 'current-year', converter: isoDateConverter })
  get currentYear(): Date {
    if (!this._internalCurrentYear) {
      return this.selectedMonth ?? DateTime.now().toJSDate();
    }

    return this._internalCurrentYear;
  }

  set currentYear(year: Date) {
    const oldValue = this._internalCurrentYear;
    this._internalCurrentYear = year;
    this.requestUpdate('currentYear', oldValue);
  }

  private get _currentYearDT(): DateTime {
    return DateTime.fromJSDate(this.currentYear);
  }

  /**
   * Emits a custom month-picker-month-selected event when a month is selected
   *
   * @param detail object with value
   * @param detail.value the selected month
   *
   * @private
   */
  @event({
    eventName: 'month-picker-month-selected',
    bubbles: true,
    composed: true,
  })
  emitMonthPickerMonthSelectedEvent(detail: { value: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('monthPickerMonthSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('month-picker-month-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom month-picker-year-selected event when a year is selected
   *
   * @param detail object with value
   * @param detail.value the selected year
   *
   * @private
   */
  @event({
    eventName: 'month-picker-year-selected',
    bubbles: true,
    composed: true,
  })
  emitMonthPickerYearSelectedEvent(detail: { value: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('monthPickerYearSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('month-picker-year-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom month-picker-previous-year-selected event when the previous year is selected
   *
   * @param detail object with startOfYear and endOfYear
   * @param detail.startOfYear the start of the previous selected year
   * @param detail.endOfYear the end of the previous selected year
   *
   * @private
   */
  @event({
    eventName: 'month-picker-previous-year-selected',
    bubbles: true,
    composed: true,
  })
  emitMonthPickerPreviousYearSelectedEvent(detail: DatePickerMonthPickerYearRange): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('monthPickerPreviousYearSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('month-picker-previous-year-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom month-picker-next-year-selected event when the next year is selected
   *
   * @param detail object with startOfYear and endOfYear
   * @param detail.startOfYear the start of the next selected year
   * @param detail.endOfYear the end of the next selected year
   *
   * @private
   */
  @event({
    eventName: 'month-picker-next-year-selected',
    bubbles: true,
    composed: true,
  })
  emitMonthPickerNextYearSelectedEvent(detail: DatePickerMonthPickerYearRange): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('monthPickerNextYearSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('month-picker-next-year-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  @queryAll('zui-picker-grid-cell')
  private _pickerGridCells: GridCell[];

  private _internalCurrentYear: Date;

  private get _gridCellMonths(): DateTime[] {
    return getCalendarMonthsForSelectedYear(this._currentYearDT);
  }

  private get _disabledGridCellConditions(): ((date: DateTime) => boolean)[] {
    const disabledMonths = getDateTimesFromJsDates(this.disabledMonths);
    const disabledYears = getDateTimesFromJsDates(this.disabledYears);

    return [
      // minDate, if set
      (date): boolean => (this._minDateDT ? date.toMillis() < this._minDateDT.toMillis() : false),
      // maxDate, if set
      (date): boolean => (this._maxDateDT ? date.toMillis() > this._maxDateDT.toMillis() : false),
      // disabledMonths
      (date): boolean => someIsSameMonth(date, disabledMonths),
      // disabledYears
      (date): boolean => someIsSameYear(date, disabledYears),
    ];
  }

  private get _gridCellFocusConditions(): (() => boolean)[] {
    return [
      (): boolean => (this._selectedMonthDT ? isSameMonth(this._selectedMonthDT, this._currentYearDT) : false),
      (): boolean => (this._selectedMonthDT ? isSameYear(this._selectedMonthDT, this._currentYearDT) : false),
    ];
  }

  private get _gridCellSelectedConditions(): ((date: DateTime) => boolean)[] {
    return [
      (date): boolean => (this._selectedMonthDT ? isSameMonth(date, this._selectedMonthDT) : false),
      (date): boolean => isSameYear(date, this._currentYearDT),
    ];
  }

  private get _gridCellSubtleConditions(): ((date: DateTime) => boolean)[] {
    return [(date): boolean => !isSameYear(date, this._currentYearDT)];
  }

  private _isGridCellDisabled(date: DateTime): boolean {
    return this._disabledGridCellConditions.some((predicate) => predicate(date));
  }

  private _canFocusGridCell(): boolean {
    return this._gridCellFocusConditions.every((predicate) => predicate());
  }

  private _getGridCellEmphasis(date: DateTime): Emphasis {
    const isSelected = this._gridCellSelectedConditions.every((predicate) => predicate(date));
    const isSubtle = this._gridCellSubtleConditions.every((predicate) => predicate(date));

    return isSelected ? 'selected' : isSubtle ? 'subtle' : 'default';
  }

  private _handleMonthSelected({ detail }: CustomEvent<{ selected: GridCell; value: string }>): void {
    const { selected, value } = detail;

    if (selected.disabled) {
      return;
    }

    this.selectedMonth = new Date(value);
    this.currentYear = this.selectedMonth;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.emitMonthPickerMonthSelectedEvent({ value: this._selectedMonthDT!.startOf('month').toJSDate() });
  }

  private _handleYearSelected(): void {
    this.emitMonthPickerYearSelectedEvent({ value: this._currentYearDT.startOf('year').toJSDate() });
  }

  private _handlePreviousYearSelected(): void {
    this.currentYear = this._currentYearDT.minus({ year: 1 }).toJSDate();

    this.emitMonthPickerPreviousYearSelectedEvent({
      startOfYear: this._currentYearDT.startOf('year').toJSDate(),
      endOfYear: this._currentYearDT.endOf('year').toJSDate(),
    });
  }

  private _handleNextYearSelected(): void {
    this.currentYear = this._currentYearDT.plus({ year: 1 }).toJSDate();

    this.emitMonthPickerNextYearSelectedEvent({
      startOfYear: this._currentYearDT.startOf('year').toJSDate(),
      endOfYear: this._currentYearDT.endOf('year').toJSDate(),
    });
  }

  private _updateFocus(): void {
    if (!this._canFocusGridCell()) {
      return;
    }

    // TODO: this needs to be reworked to make it more readable
    const pickerGridCell = Array.from(this._pickerGridCells).find((pickerGridCell) =>
      this._selectedMonthDT ? isSameMonth(DateTime.fromISO(pickerGridCell.value), this._selectedMonthDT) : false
    );

    pickerGridCell?.focus();
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('selectedMonth')) {
      this._updateFocus();
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-picker-header
        ?disabled="${this.yearSelectDisabled}"
        value="${this._currentYearDT.year}"
        @picker-header-current-selected="${this._handleYearSelected}"
        @picker-header-next-selected="${this._handleNextYearSelected}"
        @picker-header-previous-selected="${this._handlePreviousYearSelected}"
      >
        <zui-interactive-icon slot="icon-left">
          <zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-icon-arrow-outline-arrow-outline-actually-centred-left>
        </zui-interactive-icon>
        <zui-interactive-icon slot="icon-right">
          <zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-icon-arrow-outline-arrow-outline-actually-centred-right>
        </zui-interactive-icon>
      </zui-picker-header>

      <zui-picker-grid class="picker-grid" columns="4" @picker-grid-cell-selected="${this._handleMonthSelected}">
        ${this._gridCellMonths.map((dateTime) => {
          const disabled = this._isGridCellDisabled(dateTime);
          const emphasis = this._getGridCellEmphasis(dateTime);

          return html`
            <zui-picker-grid-cell
              ?disabled="${disabled}"
              emphasis="${emphasis}"
              slot="pickerGridCells"
              style="--zui-picker-grid-cell-width: 56px; --zui-picker-grid-cell-height: 56px"
              value="${dateTime.toISO()}"
            >
              ${getStartOfMonthName(dateTime.year, dateTime.month, this.locale, 'short')}
            </zui-picker-grid-cell>
          `;
        })}
      </zui-picker-grid>
    `;
  }
}
