import { LitElement } from 'lit-element';
import { Constructor, query } from 'lit-element/lib/decorators';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const DelegateFocusMixin = <T extends Constructor<LitElement>>(
  superClass: T,
  { selector = '*[zuiCaptureFocus]' } = {}
): Constructor<LitElement> & T => {
  class FocusableComponentClass extends superClass {
    @query(selector)
    private _focusableElement: HTMLElement | null;

    connectedCallback(): void {
      super.connectedCallback();

      this.addEventListener('focus', this.focusCallback);
    }

    disconnectedCallback(): void {
      this.removeEventListener('focus', this.focusCallback);

      super.disconnectedCallback();
    }

    focus(): void {
      this.focusCallback();
    }

    focusCallback(): void {
      this._focusableElement?.focus();
    }
  }

  return FocusableComponentClass as Constructor<LitElement> & T;
};
