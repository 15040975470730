import { CSSResultArray, TemplateResult, customElement, html, css, unsafeCSS, property } from 'lit-element';
import { queryAssignedNodes } from 'lit-element/lib/decorators';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { RealBaseElement } from '../../base/BaseElement';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import { emptyStringToNullWrapperConverter, stringUndefinedConverter } from '../../../utils/component.utils';

import { ToggleBarButton } from '../toggle-bar-button/toggle-bar-button.component';

import style from './toggle-bar.component.scss';

const toggleBarStyles = css`
  ${unsafeCSS(style)}
`;

type EmphasisDeprecated = 'default' | 'active' | 'active-primary';
type Emphasis = 'selected' | 'selected-primary' | EmphasisDeprecated;
type ButtonEmphasis = ToggleBarButton['emphasis'];
type Size = 's' | 'l';

const mapEmphasisToButtonEmphasis: Record<Emphasis, { buttonEmphasis: ButtonEmphasis }> = {
  selected: { buttonEmphasis: 'highlight' },
  'selected-primary': { buttonEmphasis: 'primary-highlight' },
  default: { buttonEmphasis: 'default' },
  active: { buttonEmphasis: 'highlight' },
  'active-primary': { buttonEmphasis: 'primary-highlight' },
};

/**
 * The toggle bar is a collection of buttons that behave like a group of radio buttons.
 * Only one button can be active at a time. The activated button is highlighted by setting its emphasis.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.2?node-id=13009%3A2767)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23651%3A480653)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-toggle-bar emphasis="selected-primary" size="l" value="one">
 *   <zui-toggle-bar-button value="one">One</zui-toggle-bar-button>
 *   <zui-toggle-bar-button value="two">Two</zui-toggle-bar-button>
 *   <zui-toggle-bar-button value="three">Three</zui-toggle-bar-button>
 * </zui-toggle-bar>
 * ```
 *
 * ```html
 * <zui-toggle-bar emphasis="selected-primary" size="l" value="one">
 *   <zui-toggle-bar-button value="one"><zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder></zui-toggle-bar-button>
 *   <zui-toggle-bar-button value="two"><zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder></zui-toggle-bar-button>
 *   <zui-toggle-bar-button value="three"><zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder></zui-toggle-bar-button>
 * </zui-toggle-bar>
 * ```
 *
 * @slot - default slot for zui-toggle-bar-button's
 *
 * @fires {Event} change - emits when the toggle bar value has changed
 * @fires {Event} input - emits when the toggle bar value has changed
 */
@customElement('zui-toggle-bar')
export class ToggleBar
  extends FormValidationMixin(FormDataHandlingMixin(DelegateFocusMixin(RealBaseElement)))
  implements FormValidationElement<FormEnabledElement> {
  static get styles(): CSSResultArray {
    return [hostStyles, toggleBarStyles];
  }

  /**
   * emphasis that is used for the selected button state
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'selected';

  /**
   * size
   */
  @property({ reflect: true, type: String })
  size: Size = 's';

  /**
   * value of the current selected button
   */
  @property({ reflect: true, converter: emptyStringToNullWrapperConverter(stringUndefinedConverter) })
  value: string | undefined;

  /**
   * enables the deselection of the buttons
   */
  @property({ reflect: true, type: Boolean, attribute: 'enable-deselection' })
  enableDeselection = false;

  /**
   * Whether the toggle bar is required or not
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * Emits a change event
   *
   * @private
   */
  @event({
    eventName: 'change',
    bubbles: true,
    composed: false,
    cancelable: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        composed: false,
        cancelable: false,
      })
    );
  }

  /**
   * Emits an input event
   *
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    composed: true,
    cancelable: false,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new Event('input', {
        bubbles: true,
        composed: true,
        cancelable: false,
      })
    );
  }

  @queryAssignedNodes('', true, 'zui-toggle-bar-button')
  private _assignedToggleBarButtons: ToggleBarButton[];

  private _propagateDisabled = false;
  private _propagateReadonly = false;

  constructor() {
    super();

    this.addValidator({
      type: 'valueMissing',
      validator: () => {
        return this.required === true
          ? this.value !== undefined &&
              // if we are required, only a value that can be found within buttons is valid
              this._assignedToggleBarButtons.find((toggleBarButton) => toggleBarButton.value === this.value) !==
                undefined
          : true;
      },
      validatesOnProperties: ['required'],
    });
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.addEventListener('toggle-bar-button-selected', this._handleToggleBarButtonSelected);
  }

  disconnectedCallback(): void {
    this.removeEventListener('toggle-bar-button-selected', this._handleToggleBarButtonSelected);

    super.disconnectedCallback();
  }

  private _propagateState(): void {
    this._assignedToggleBarButtons.forEach((button) => {
      if (this._propagateDisabled) {
        button.disabled = this.disabled;
      }
      if (this._propagateReadonly) {
        button.readonly = this.readonly;
      }
      if (button.value === this.value) {
        button.emphasis = mapEmphasisToButtonEmphasis[this.emphasis].buttonEmphasis;
      } else {
        button.emphasis = 'default';
      }
      button.size = this.size;
    });
  }

  private _handleToggleBarButtonSelected(event: CustomEvent<{ value: string }>): void {
    event.stopPropagation();

    this.value = this.enableDeselection && this.value == event.detail.value ? undefined : event.detail.value;

    this.emitChangeEvent();
    this.emitInputEvent();
  }

  private _handleSlotchange(): void {
    this._propagateState();
    // missing options might turn it invalid
    this.checkValidity();
  }

  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);
    // if disabled is true it should always be propagated
    if (
      this.disabled === true ||
      // or from true to false, we need to propagate it
      (changedProperties.get('disabled') === true && this.disabled === false)
    ) {
      this._propagateDisabled = true;
    }
    // if readonly is true it should always be propagated
    if (
      this.readonly === true ||
      // or from true to false, we need to propagate it
      (changedProperties.get('readonly') === true && this.readonly === false)
    ) {
      this._propagateReadonly = true;
    }
    if (changedProperties.has('emphasis')) {
      if (this.emphasis === 'default' || this.emphasis === 'active' || this.emphasis === 'active-primary') {
        console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-button.`);
      }
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    this._propagateState();
  }

  protected render(): TemplateResult {
    // TODO: add focus delegation, if needed
    return html`<slot @slotchange="${this._handleSlotchange}"></slot>`;
  }
}
