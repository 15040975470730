import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  css,
  unsafeCSS,
  queryAssignedNodes,
} from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { nothing } from 'lit-html';
import { BaseElement } from '../base/BaseElement';

import { hostStyles } from '../../host.styles';
import style from './about-screen.component.scss';

export const aboutScreenStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The About Screen component allows reading the licence agreement, but not to accept it.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=24931%3A950224)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=63324%3A486073)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-about-screen software-name="software name" software-version="software version" copyright-claim="copyright claim" fixed-licence-title="fixed licence title">
 *    <zui-icon-seal slot="icon"></zui-icon-seal>
 *    <p>licence content<p>
 *    <div slot="footer-content">footer content</div>
 * </zui-about-screen>
 * ```
 * @slot - The default slot. This is for the licence content.
 * @slot footer-content - This is the slot for the footer content.
 * @slot icon - This is the slot for the icon on top of the licence. If a zui-icon-zeiss icon gets placed inside, the color sheme of the about screen gets inverted because of branding rules.
 */
@customElement('zui-about-screen')
export class AboutScreen extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, aboutScreenStyles];
  }

  /**
   * Allows to set the software name which gets displayed in the header section of the about screen
   */
  @property({ reflect: true, attribute: 'software-name', type: String })
  softwareName = '';
  /**
   * Allows to set an optional software version which gets displayed in the header section of the about screen
   */
  @property({ reflect: true, attribute: 'software-version', type: String })
  softwareVersion: string;
  /**
   * Allows to set an optional copyright claim which gets displayed in the header section of the about screen
   */
  @property({ reflect: true, attribute: 'copyright-claim', type: String })
  copyrightClaim: string;

  /**
   * Allows to set an optional fixed licence title which gets displayed on top of the licence section and doesn't scroll
   */
  @property({ reflect: true, attribute: 'fixed-licence-title', type: String })
  fixedLicenceTitle: string;

  @queryAssignedNodes('icon', true, '[zuiIcon]')
  private _assignedIcons: HTMLElement[];

  @queryAssignedNodes('icon', true, 'zui-icon-zeiss')
  private _zeissIcons: HTMLElement[];

  @queryAssignedNodes('footer-content', true)
  private _assignedFooter: Node[];

  /**
   * Handles when an icon gets placed inside the icon slot and will add a size.
   */
  private _handleIconSlotchange(): void {
    this._setIconSize();
    this.requestUpdate();
  }

  /**
   * Handles the slotchange event of the footer-content slot and adds a class for additional padding when the slot gets used.
   */
  private _handleFooterSlotchange(): void {
    this.requestUpdate();
  }

  /**
   * Checks if the icon slot is filled with a zui-icon-zeiss
   *
   * @returns boolean is true if there is a zui-icon-zeiss
   */
  private get _isZeissIconInIconSlot(): boolean {
    return this._zeissIcons?.length > 0;
  }

  /**
   * Checks if the footer-content slot is used
   *
   * @returns boolean is true if footer is used
   */
  private get _isFooterUsed(): boolean {
    return this._assignedFooter?.length > 0;
  }

  /**
   * Checks if software version or copyright claim is set
   *
   * @returns boolean is true if software version or copyright claim is type of string
   */
  private get _isInfoContainerUsed(): boolean {
    return typeof this.softwareVersion === 'string' || typeof this.copyrightClaim === 'string';
  }

  /**
   * Set size of icon in the icon slot to '4xl'.
   */
  private _setIconSize(): void {
    this._assignedIcons.forEach((icon) => icon.setAttribute('size', '4xl'));
  }

  protected render(): TemplateResult | void {
    return html`
      <div
        id="container"
        class="${classMap({ 'zeiss-icon': this._isZeissIconInIconSlot, 'footer-used': this._isFooterUsed })}"
      >
        <section id="header">
          <section id="icon-container">
            <slot name="icon" @slotchange="${this._handleIconSlotchange}"></slot>
          </section>
          <section id="title-container">${this.softwareName}</section>
          ${this._isInfoContainerUsed
            ? html`<section id="info-container">
                <div id="info-software-version">${this.softwareVersion}</div>
                <div id="info-copyright-claim">${this.copyrightClaim}</div>
              </section>`
            : nothing}
        </section>
        <section id="licence">
          <section id="licence-title-container">${this.fixedLicenceTitle}</section>
          <zui-scrollable-directive style="flex-basis: 0; --zui-scrollable-width: 592px; flex-grow: 1; min-height: 0;">
            <div id="licence-container">
              <slot id="content"></slot>
            </div>
          </zui-scrollable-directive>
        </section>
        <section id="footer">
          <slot id="footer-content" name="footer-content" @slotchange="${this._handleFooterSlotchange}"></slot>
        </section>
      </div>
    `;
  }
}
