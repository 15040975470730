import { css, customElement, html, property, TemplateResult, unsafeCSS } from 'lit-element';
import { queryAll, state } from 'lit-element/lib/decorators';
import { BaseElement } from '../../base/BaseElement';
import { EventWithTarget } from '../../../types';
import { event } from '../../../decorators/event.decorator';
import { atEnd, atStart, getPaginationDots } from '../utils/pagination.utils';

import { PaginationDot } from '../pagination-dot/pagination-dot.component';

import { hostStyles } from '../../../host.styles';
import style from './pagination-dot-bar.component.scss';

const paginationDotBarStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The zui-pagination-dot-bar shows a list of zui-pagination-dot's. The number of shown dots is defined by item-count and the selection by selected-item-index.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=62193%3A344226)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=32074%3A520216)
 *
 *
 * @example
 * ```HTML
 * <zui-pagination-dot-bar item-count="7" selected-item-index="2"></zui-pagination-dot-bar>
 * ```
 * @cssprop --zui-pagination-dot-bar-transition-timing - animation duration of the used dots
 * @fires {CustomEvent<{ value: number }>} pagination-dot-bar-pagination-dot-selected - emits when a pagination dot is selected
 */
@customElement('zui-pagination-dot-bar')
export class PaginationDotBar extends BaseElement {
  static readonly styles = [hostStyles, paginationDotBarStyles];

  /**
   * the total number of items
   */
  @property({ reflect: true, type: Number, attribute: 'item-count' })
  itemCount = 0;

  /**
   * index of the selected item
   */
  @property({ reflect: true, type: Number, attribute: 'selected-item-index' })
  selectedItemIndex: number;

  /**
   * Emits a custom pagination-dot-bar-pagination-dot-selected event when a pagination dot is selected
   *
   * @param detail object with value
   * @param detail.value zero based index of the selected pagination dot
   *
   * @private
   */
  @event({
    eventName: 'pagination-dot-bar-pagination-dot-selected',
    bubbles: true,
    composed: true,
  })
  emitPaginationDotBarPaginationDotSelectedEvent(detail: { value: number }): void {
    this.dispatchEvent(
      new CustomEvent('pagination-dot-bar-pagination-dot-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  // todo: works for odd numbers but should be checked again when requirements change and max items are configurable
  @state()
  private _maxItems = 5;

  @queryAll('zui-pagination-dot')
  private _dotsRef: NodeListOf<PaginationDot>;

  private _handlePaginationDotSlelected({ target }: EventWithTarget<PaginationDot>): void {
    this.selectedItemIndex = parseInt(target.id, 10);

    this.emitPaginationDotBarPaginationDotSelectedEvent({ value: this.selectedItemIndex });
  }

  // we are directly manipulation the DOM, because LitHTML does cache its DOM node
  // direct DOM manipulation won't trigger a re-render
  protected updated(changedProps: Map<string, unknown>): void {
    if (changedProps.has('selectedItemIndex')) {
      const selectedDot = Array.from(this._dotsRef).find((dot) => parseInt(dot.id, 10) === this.selectedItemIndex);
      selectedDot?.setAttribute(PaginationDot.internalAnimateSelectedAttribute, '');
    }
  }

  protected render(): TemplateResult {
    return html`${getPaginationDots(this.itemCount, this.selectedItemIndex, this._maxItems).map(
      (paginationDotIndex, index) => {
        const isSelected = paginationDotIndex === this.selectedItemIndex;

        const isFirst = index === 0;
        const isLast = index === this._maxItems - 1;
        const showSmall =
          (isFirst && !atStart(this.itemCount, this.selectedItemIndex, this._maxItems)) ||
          (isLast && !atEnd(this.itemCount, this.selectedItemIndex, this._maxItems));

        return html`
          <zui-pagination-dot
            id="${paginationDotIndex}"
            emphasis="${isSelected ? 'selected' : 'default'}"
            size="${showSmall ? 's' : 'm'}"
            @click="${this._handlePaginationDotSlelected}"
          >
          </zui-pagination-dot>
        `;
      }
    )}`;
  }
}
