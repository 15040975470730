import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  css,
  unsafeCSS,
  queryAssignedNodes,
  state,
  query,
} from 'lit-element';
import { nothing } from 'lit-html';
import { hostStyles } from '../../host.styles';
import { BaseElement } from '../base/BaseElement';
import styles from './tag.component.scss';
import '../tooltip/tooltip.component';

const tagStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * A Tag is used to label and categorize items and support recognition and navigation.
 * Tags can be used to navigate to items linked with the same Tag.
 *
 *  * ## Figma
 * - [Styleguide – Web](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=8492%3A90165)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-tag text="ZUi web component library">
 *  <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 * </zui-tag>
 * ```
 *
 * @slot icon - slot for an icon
 */
@customElement('zui-tag')
export class Tag extends BaseElement {
  /**
   * This disables the Tag
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Value of the Tag
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Text to be shown for Tag
   */
  @property({ reflect: true, type: String })
  text: string;

  /**
   * This makes the Tag non-interactive
   */
  @property({ reflect: true, type: Boolean, attribute: 'non-interactive' })
  nonInteractive = false;

  static get styles(): CSSResultArray {
    return [hostStyles, tagStyles];
  }

  @query('.text-container')
  private _textContainerRef: HTMLSpanElement;

  @queryAssignedNodes('icon', true, '[zuiIcon]')
  private _iconSlotNodes: HTMLElement[];

  /**
   * This internal property is used to signal that the tooltip
   * should be set.
   */
  @state()
  private _activeEllipsis = false;

  private _updateIconSize(): void {
    this._iconSlotNodes.forEach((icon) => icon.setAttribute('size', 's'));
  }

  private _checkActiveEllipsis(): void {
    // compares the visible width with the whole width of the text div
    // if the whole width is larger than the max-width an tooltip is shown
    this._activeEllipsis = this._textContainerRef.offsetWidth < this._textContainerRef.scrollWidth;
  }

  protected updated(changedProperties: Map<string | number | symbol, unknown>): void {
    if (changedProperties.has('text')) {
      requestAnimationFrame(() => this._checkActiveEllipsis());
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-div class="tooltip-container">
        <button ?disabled=${this.disabled}>
          <span class="text-container">${this.text}</span>
          <slot name="icon" @slotchange="${this._updateIconSize}" slot="icon"></slot>
        </button>
        ${this._activeEllipsis
          ? html`
              <zui-tooltip-directive anchoring="cursor" trigger="hover,focus">
                <zui-tooltip>${this.text}</zui-tooltip>
              </zui-tooltip-directive>
            `
          : nothing}
      </zui-div>
    `;
  }
}
