import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  css,
  unsafeCSS,
  property,
  PropertyValues,
} from 'lit-element';
import { nothing } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { hostStyles } from '../../../host.styles';
import { DateTime } from 'luxon';
import { BaseElement } from '../../base/BaseElement';
import styles from './date-picker-input.component.scss';
import { getDefaultLocale, isoDateConverter } from '../utils/date-picker.utils';
import { event } from '../../../decorators/event.decorator';
import { numberUndefinedConverter } from '../../../utils/component.utils';
import { ifDefined } from 'lit-html/directives/if-defined';

import '../date-picker-input-part/date-picker-input-part.component';
import '../../interactive-icon/interactive-icon.component';

const datePickerInputStyles = css`
  ${unsafeCSS(styles)}
`;

const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
};

type DatePickerInputDateTimeFormatPart = Omit<Intl.DateTimeFormatPart, 'value'> & { value: null | number | string };

/**
 * The date picker input shows an input with an interactive calendar icon.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-input
 *   locale="en-US"
 *   placeholderDay="DD"
 *   placeholderMonth="MM"
 *   placeholderYear="YYYY"
 * >
 * </zui-date-picker-input>
 * ```
 *
 * @fires {CustomEvent} date-picker-input-calendar-selected - emits when the interactive icon is selected
 * @fires {CustomEvent} datePickerInputCalendarSelected - (Deprecated) emits when the interactive icon is selected
 * @fires {CustomEvent} date-picker-input-changed - emits when the input has changed
 * @fires {CustomEvent} date-picker-input-focused - emits when one of the input parts is focused
 *
 * @cssprop --zui-date-picker-input-width - size of the input
 * @cssprop --zui-date-picker-input-day-placeholder-width - override default day input placeholder width that is optimized for DD
 * @cssprop --zui-date-picker-input-month-placeholder-width - override default month input placeholder width that is optimized for MM
 * @cssprop --zui-date-picker-input-year-placeholder-width - override default year input placeholder width that is optimized for YYYY
 */
@customElement('zui-date-picker-input')
export class DatePickerInput extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, datePickerInputStyles];
  }

  /**
   * whether the calendar is opened or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'calendar-opened' })
  calendarOpened = false;

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  // todo: remove in version 2.0
  /**
   * (Deprecated) focused - was only used for styling purposes and is replaced with :focus-within
   *
   * @deprecated
   */
  @property({ reflect: true, type: Boolean })
  focused = false;

  /**
   * input part day value that is used when no selected value is present
   */
  @property({ reflect: true, type: Number, attribute: 'input-part-day-value', converter: numberUndefinedConverter })
  inputPartDayValue: number | undefined;

  /**
   * input part month value that is used when no selected value is present
   */
  @property({ reflect: true, type: Number, attribute: 'input-part-month-value', converter: numberUndefinedConverter })
  inputPartMonthValue: number | undefined;

  /**
   * input part year value that is used when no selected value is present
   */
  @property({ reflect: true, type: Number, attribute: 'input-part-year-value', converter: numberUndefinedConverter })
  inputPartYearValue: number | undefined;

  /**
   * whether the input is valid or not
   */
  @property({ reflect: true, type: Boolean })
  invalid = false;

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * placeholder day
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-day' })
  placeholderDay = 'DD';

  /**
   * placeholder month
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-month' })
  placeholderMonth = 'MM';

  /**
   * placeholder year
   */
  @property({ reflect: true, type: String, attribute: 'placeholder-year' })
  placeholderYear = 'YYYY';

  /**
   * readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * selected date
   */
  @property({ reflect: true, type: String, attribute: 'selected-date', converter: isoDateConverter })
  selectedDate: Date | null = null;

  private get _selectedDateDT(): DateTime | undefined {
    return this.selectedDate && DateTime.fromJSDate(this.selectedDate).isValid
      ? DateTime.fromJSDate(this.selectedDate)
      : undefined;
  }

  /**
   * Emits a custom date-picker-input-calendar-selected event when the calendar icon is selected
   *
   * @private
   */
  @event({
    eventName: 'date-picker-input-calendar-selected',
    bubbles: true,
    composed: true,
  })
  emitDatePickerInputCalendarSelectedEvent(): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('datePickerInputCalendarSelected', {
        bubbles: true,
        composed: true,
      })
    );

    this.dispatchEvent(
      new CustomEvent('date-picker-input-calendar-selected', {
        bubbles: true,
        composed: true,
      })
    );
  }

  /**
   * Emits a custom date-picker-input-changed event when the input value changed
   *
   * @param detail detail
   * @param detail.value value
   * @param detail.error optional error that contains input parts
   *
   * @private
   */
  @event({
    eventName: 'date-picker-input-changed',
    bubbles: true,
    composed: true,
  })
  emitDatePickerInputChangedEvent(detail: {
    value: Date | null;
    error?: Record<'day' | 'month' | 'year', number | null>;
  }): void {
    this.dispatchEvent(
      new CustomEvent('date-picker-input-changed', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom date-picker-input-focused event when one of the input parts is focused
   *
   * @private
   */
  @event({
    eventName: 'date-picker-input-focused',
    bubbles: true,
    composed: true,
  })
  emitDatePickerInputFocusedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('date-picker-input-focused', {
        bubbles: true,
        composed: true,
      })
    );
  }

  private get _dateTimeFormatParts(): DatePickerInputDateTimeFormatPart[] {
    return DateTime.now().toLocaleParts({ locale: this.locale, ...dateTimeFormatOptions });
  }

  private get _inputParts(): DatePickerInputDateTimeFormatPart[] {
    return this._selectedDateDT?.isValid
      ? this._selectedDateDT
          .toLocaleParts({ locale: this.locale, ...dateTimeFormatOptions })
          .map((part: Intl.DateTimeFormatPart) =>
            part.type === 'literal' ? part : { ...part, value: parseInt(part.value) }
          )
      : this._dateTimeFormatParts.map((part) => {
          return {
            ...part,
            value: part.type === 'literal' ? part.value : null,
          };
        });
  }

  private get _validLiterals(): string {
    const literals = this._dateTimeFormatParts.filter(({ type }) => type === 'literal').map(({ value }) => value);

    return [...new Set(literals)].join(',');
  }

  private _handleDatePickerInputPartFocusEvent(): void {
    this.emitDatePickerInputFocusedEvent();
  }

  private _handleDatePickerInputPartValueChangedEvent({
    detail,
  }: CustomEvent<{ type: string; value: number | null }>): void {
    const { type, value } = detail;

    switch (type) {
      case 'day':
        this.inputPartDayValue = value ?? undefined;
        break;
      case 'month':
        this.inputPartMonthValue = value ?? undefined;
        break;
      case 'year':
        this.inputPartYearValue = value ?? undefined;
        break;
    }

    // updating a part means, that we have to merge the currently
    // selectedDate with our updated part; if there is a selectedDate
    const dateTimeValue: Record<'day' | 'month' | 'year', number | null> = {
      day: type === 'day' ? value : this._selectedDateDT ? this._selectedDateDT.day : this.inputPartDayValue ?? null,
      month:
        type === 'month' ? value : this._selectedDateDT ? this._selectedDateDT.month : this.inputPartMonthValue ?? null,
      year:
        type === 'year' ? value : this._selectedDateDT ? this._selectedDateDT.year : this.inputPartYearValue ?? null,
    };

    // if we have all parts, commit date, otherwise set selectedDate to null
    const isPartMissing = Object.values(dateTimeValue).includes(null);
    const isValid = !isPartMissing
      ? DateTime.fromObject({ ...(dateTimeValue as Record<'day' | 'month' | 'year', number>) }).isValid
      : false;
    // FIXME: this is a potential bug
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.selectedDate = !isPartMissing ? DateTime.fromObject({ ...dateTimeValue }).toJSDate() : null;

    this.emitDatePickerInputChangedEvent({
      value: this.selectedDate,
      error: !isValid ? dateTimeValue : undefined,
    });
  }

  private _handleShowCalendar(): void {
    this.emitDatePickerInputCalendarSelectedEvent();
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    // todo: remove when not needed anymore
    if (changedProperties.has('focused') && this.focused) {
      console.warn('Deprecated focused property use detected. This will be removed in the next major version.');
    }
  }

  protected render(): TemplateResult {
    return html`
      <div class="input-parts">
        ${repeat(this._inputParts, ({ type, value }) => {
          switch (type) {
            case 'day':
              return html`<zui-date-picker-input-part
                ?disabled="${this.disabled}"
                ?readonly="${this.readonly}"
                input-part-type="day"
                literals=${this._validLiterals}
                max="31"
                min="1"
                placeholder="${this.placeholderDay}"
                style="
                  ---zui-date-picker-input-part-input-width: var(---zui-date-picker-input-day-input-width);
                  --zui-date-picker-input-part-placeholder-width: var(--zui-date-picker-input-day-placeholder-width)
                "
                value="${ifDefined(this._selectedDateDT?.day ?? this.inputPartDayValue)}"
                @date-picker-input-part-value-changed=${this._handleDatePickerInputPartValueChangedEvent}
                @focus=${this._handleDatePickerInputPartFocusEvent}
              >
              </zui-date-picker-input-part>`;
            case 'month':
              return html`<zui-date-picker-input-part
                ?disabled="${this.disabled}"
                ?readonly="${this.readonly}"
                input-part-type="month"
                literals=${this._validLiterals}
                max="12"
                min="1"
                placeholder="${this.placeholderMonth}"
                style="
                  ---zui-date-picker-input-part-input-width: var(---zui-date-picker-input-month-input-width);
                  --zui-date-picker-input-part-placeholder-width: var(--zui-date-picker-input-month-placeholder-width)
                "
                value="${ifDefined(this._selectedDateDT?.month ?? this.inputPartMonthValue)}"
                @date-picker-input-part-value-changed=${this._handleDatePickerInputPartValueChangedEvent}
                @focus=${this._handleDatePickerInputPartFocusEvent}
              >
              </zui-date-picker-input-part>`;
            case 'year':
              return html`<zui-date-picker-input-part
                ?disabled="${this.disabled}"
                ?readonly="${this.readonly}"
                input-part-type="year"
                literals=${this._validLiterals}
                max="9999"
                min="1"
                placeholder="${this.placeholderYear}"
                style="
                  ---zui-date-picker-input-part-input-width: var(---zui-date-picker-input-year-input-width);
                  --zui-date-picker-input-part-placeholder-width: var(--zui-date-picker-input-year-placeholder-width)
                "
                value="${ifDefined(this._selectedDateDT?.year ?? this.inputPartYearValue)}"
                @date-picker-input-part-value-changed=${this._handleDatePickerInputPartValueChangedEvent}
                @focus=${this._handleDatePickerInputPartFocusEvent}
              >
              </zui-date-picker-input-part>`;
            case 'literal':
              return html`<span class="literal">${value}</span>`;
            default:
              return nothing;
          }
        })}
      </div>
      <zui-interactive-icon
        ?disabled="${this.disabled || this.readonly}"
        emphasis="default"
        @click=${this._handleShowCalendar}
      >
        <zui-icon-time-time-date-calender size="m"></zui-icon-time-time-date-calender>
      </zui-interactive-icon>
    `;
  }
}
