import { getCssPropertyFromElement } from './component.utils';

/**
 * Callback function with latest step value as float starting at 0
 * and ending with 1. Elastic or bouncing timing functions may peek
 * below 0 or above 1 while animating though...
 */
type StepFn = (step: number) => void;

/**
 * Animation settings, like the element to inject the dummy
 * into, or if the animation should autostart
 */
type AnimationOptions = {
  /**
   * Starts the animation immediately
   */
  autostart: boolean;

  /**
   * Defines the host element to inject the dummy into - helpful
   * when using custom properties or other scope dependent (or
   * inherited) properties
   */
  host: HTMLElement;
};

// Attention: initially I tried to animate `opacity`, which did well, until we
// need negative values (in e.g. bouncing easing functions). Thus we need a
// property that allows negative floating values, like `letter-spacing` 🤔
// https://stackoverflow.com/a/45392255/1146207
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_animated_properties
//
// Right now `@property` rules https://caniuse.com/mdn-css_at-rules_property
// or `registerProperty` are experimental, but once available in Webkit and
// Gecko one should animate a custom property instead...
// https://developer.mozilla.org/en-US/docs/Web/API/CSS/RegisterProperty
//
// Make sure to use the camelCased name (not the hyphen-case), as we use them
// for animation keyframes, see:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Animations_API/Keyframe_Formats#attributes
const ANIMATE_PROPERTY = 'letterSpacing';

// creates a temporary dummy element
const prepareTempElement = (host: HTMLElement): HTMLSpanElement => {
  // prepare element
  const temp = document.createElement('span');

  // do not interfer with application
  temp.style.setProperty('position', 'absolute');
  temp.style.setProperty('visibility', 'hidden');
  temp.style.setProperty('z-index', '-9999');

  // add the element and deliver it
  host.appendChild(temp);
  return temp;
};

// check for animation steps
const watchAnimation = (stepFn: StepFn, temp: HTMLElement, animation: Animation): void => {
  // read the current value and parse it
  const computed = getComputedStyle(temp)[ANIMATE_PROPERTY];
  const value = computed === 'normal' ? '0px' : computed;
  const step = parseFloat(value) / 100;

  // go on if animation is not finished
  if (animation.playState === 'running') {
    // call the step function and advance further
    stepFn(step);
    requestAnimationFrame(() => watchAnimation(stepFn, temp, animation));
  }
};

// checks for custom properties and parses their values (not the fallbacks!) if necessary
const revealPotentialCustomProperty = (value: string, context: HTMLElement): string => {
  const pattern = /^var\(([\w-]+)(?:,.*)?\)$/i;
  // check if we have a custom property here
  if (pattern.test(value)) {
    // unwrap the property name and read its value
    return getCssPropertyFromElement(context, value.replace(pattern, '$1'));
  }
  // go with existing value
  return value;
};

// parses the given duration in milliseconds to be used with the web animations API
const parseDuration = (duration: number | string, context: HTMLElement): number => {
  // is numeric already, treat as milliseconds
  if (typeof duration === 'number') {
    return duration;
  }
  // can either be custom property or CSS time `.3s` or `200ms`
  const time = revealPotentialCustomProperty(duration.trim(), context);
  return parseFloat(time) * (time.endsWith('ms') ? 1 : 1000);
};

/**
 * Animates a custom step function with a given duration and a css easing function.
 * Both, duration and easing function can be custom properties.
 * Internally, we use the Web Animations API and by doing so we deliver the
 * `Animation` object as result in reponse. Thus, all features of this API can be
 * consumed directly.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Animations_API
 * @see https://stackblitz.com/edit/animate-js-from-css-easing
 *
 * @example `animateWithCSS('linear', 200, step => element.scrollLeft = 200 * step)`
 * @example `animateWithCSS('step(5)', '3s', console.log, { autostart: true })`
 * @example `animateWithCSS('--my-timing-fn', '--my-duration', () => null, { host: myElement })`
 *
 * @param easing css easing declaration, e.g. `linear`, `ease-in-out`, an
 *  timing function (https://developer.mozilla.org/en-US/docs/Web/CSS/easing-function),
 *  or a custom property
 * @param duration animation duration in milliseconds or a string representing either a
 *  time (https://developer.mozilla.org/en-US/docs/Web/CSS/time), or a custom property
 * @param stepFn callback function which is called on every animation step
 *  with the current progress as a value starting at 0 and ending at 1
 * @param options additional options
 * @param options.host optional element to inject the dummy into
 * @param options.autostart optional, if the animation should autostart
 * @returns Animation
 */
export const animateWithCSS = (
  easing: string,
  duration: number | string,
  stepFn: StepFn,
  { host = document.body, autostart = false }: Partial<AnimationOptions> = {}
): Animation => {
  // prepare a temporary dummy element
  const temp = prepareTempElement(host);

  // prepare the animation
  const animation = temp.animate(
    {
      // we simply go from 0 to 100 to easily
      // derive percentage values later
      [ANIMATE_PROPERTY]: ['0px', '100px'],
    },
    {
      duration: parseDuration(duration, host),
      easing: revealPotentialCustomProperty(easing, host),
      fill: 'forwards',
    }
  );

  // prepare for clean-up once stopped
  animation.addEventListener('cancel', () => temp.remove(), false);
  animation.addEventListener(
    'finish',
    () => {
      // call last step to ensure completeness
      // -> this causes a step call _after_ the animation has finished
      stepFn(1);
      temp.remove();
    },
    false
  );

  // monkey-patch play method to hook-up watcher -
  // unfortunately we have no event to listen to :(
  const play = animation.play;
  animation.play = (): void => {
    watchAnimation(stepFn, temp, animation);
    play.call(animation);
  };

  // start the animation right now
  if (autostart) {
    animation.play();
  }

  // deliver the result
  return animation;
};
