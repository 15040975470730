import { LitElement } from 'lit-element';
import { queryAll } from 'lit-element/lib/decorators';
import { EventWithTarget } from '../../types';
import { Constructor } from '../../utils/util.types';

const KEYBOARD_FOCUSED_ATTRIBUTE = 'zui-keyboard-focused';
const MOUSE_FOCUSED_ATTRIBUTE = 'zui-mouse-focused';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const FocusDifferentiationMixin = <T extends Constructor<LitElement>>(
  superClass: T,
  {
    /**
     * By default the input source is not further determined once the targets are focused.
     * This option allows to adapt the attributes while the focus remains on the targets.
     */
    adaptWhileFocused = false,
    /**
     * Selector for specific focus listener targets to be used.
     * Optionally, their ids will be used to provide explicit
     * attributes on the host to be used as styling targets.
     */
    selector = '*[zui-differentiate-focus]',
  } = {}
): Constructor & T => {
  class FocusDifferentiationClass extends superClass {
    @queryAll(selector)
    private _taggedDifferentiableElements!: NodeListOf<HTMLElement>;

    // flags whether a keyboard or the mouse is responsible
    private _keyboardActive = true;

    connectedCallback(): void {
      super.connectedCallback();

      // listen globally to determine input source
      window.addEventListener('keydown', this._handleGlobalKeyDownEvent);
      window.addEventListener('mousedown', this._handleGlobalMouseDownEvent);

      // add focus listeners
      this._addFocusListeners();
    }

    disconnectedCallback(): void {
      // remove global listeners
      window.removeEventListener('keydown', this._handleGlobalKeyDownEvent);
      window.removeEventListener('mousedown', this._handleGlobalMouseDownEvent);

      // stop listening to elements focus
      this._removeFocusListeners();

      super.disconnectedCallback();
    }

    /**
     * Convenient getter to retrieve the target elements
     *
     * @returns elements
     */
    private get _differentiableElements(): HTMLElement[] {
      // use the custom tagged elements or the host as fallback
      return this._taggedDifferentiableElements.length > 0 ? Array.from(this._taggedDifferentiableElements) : [this];
    }

    private get _isDisabled(): boolean {
      return this.hasAttribute('disabed');
    }

    private get _eventAttribute(): string {
      return this._keyboardActive ? KEYBOARD_FOCUSED_ATTRIBUTE : MOUSE_FOCUSED_ATTRIBUTE;
    }

    private get _alternateEventAttribute(): string {
      return this._keyboardActive ? MOUSE_FOCUSED_ATTRIBUTE : KEYBOARD_FOCUSED_ATTRIBUTE;
    }

    private _addFocusListeners(): void {
      this._differentiableElements.forEach((element) => {
        if (adaptWhileFocused) {
          element.addEventListener('keydown', this._handleKeyDownEvent);
          element.addEventListener('mousedown', this._handleMouseDownEvent);
        }
        element.addEventListener('focusin', this._handleFocusInEvent);
        element.addEventListener('focusout', this._handleFocusOutEvent);
      });
    }

    private _removeFocusListeners(): void {
      this._differentiableElements.forEach((element) => {
        element.removeEventListener('keydown', this._handleKeyDownEvent);
        element.removeEventListener('mousedown', this._handleMouseDownEvent);
        element.removeEventListener('focusin', this._handleFocusInEvent);
        element.removeEventListener('focusout', this._handleFocusOutEvent);
      });
    }

    private _prepareUniqueAttribute(id: string): string {
      // check for missing id
      if (id === '') {
        return this._eventAttribute;
      }
      // concatenate result
      return `${this._eventAttribute}-${id}`;
    }

    // removes all focus event attributes of the opposite event source
    private _cleanUpAlternateFocusEventAttributes(): void {
      this.getAttributeNames()
        .filter((name) => name.startsWith(this._alternateEventAttribute))
        .forEach((name) => this.removeAttribute(name));
    }

    private _handleFocusInEvent = ({ target }: EventWithTarget<Element>): void => {
      if (this._isDisabled) {
        return;
      }

      // clean up eventually left over other focus source attribute
      this._cleanUpAlternateFocusEventAttributes();
      // set generic host attribute
      this.setAttribute(this._eventAttribute, '');
      // set individual host attributes depending on existance of element ids
      if (target.id !== '') {
        this.setAttribute(this._prepareUniqueAttribute(target.id), '');
      }
    };

    private _handleFocusOutEvent = ({ target }: EventWithTarget<Element>): void => {
      if (this._isDisabled) {
        return;
      }

      // clean up eventually left over other focus source attribute
      this._cleanUpAlternateFocusEventAttributes();
      // remove individual host attributes depending on existance of element ids
      if (target.id !== '') {
        this.removeAttribute(this._prepareUniqueAttribute(target.id));
      }
      // remove generic host attribute but only if no specific attribute is left
      if (this.getAttributeNames().filter((name) => name.startsWith(this._eventAttribute)).length < 2) {
        this.removeAttribute(this._eventAttribute);
      }
    };

    private _handleKeyDownEvent = (event: EventWithTarget<Element>): void => {
      if (!this._isDisabled && adaptWhileFocused) {
        // set the latest event source from keyboard
        this._keyboardActive = true;
        // trigger focus handler manually
        this._handleFocusInEvent(event);
      }
    };

    private _handleMouseDownEvent = (event: EventWithTarget<Element>): void => {
      if (!this._isDisabled && adaptWhileFocused) {
        // set the latest event source to mouse
        this._keyboardActive = false;
        // trigger focus handler manually
        this._handleFocusInEvent(event);
      }
    };

    private _handleGlobalKeyDownEvent = (): void => {
      if (!this._isDisabled) {
        // set the latest event source from keyboard
        this._keyboardActive = true;
      }
    };

    private _handleGlobalMouseDownEvent = (): void => {
      if (!this._isDisabled) {
        // sourced from a mouse event, followups!
        this._keyboardActive = false;
      }
    };

    protected updated(changedProperties: Map<string, unknown>): void {
      super.updated(changedProperties);

      // re-apply focus listeners
      this._removeFocusListeners();
      this._addFocusListeners();
    }
  }

  return FocusDifferentiationClass as Constructor & T;
};
