import {
  CSSResult,
  TemplateResult,
  css,
  customElement,
  html,
  property,
  queryAssignedNodes,
  unsafeCSS,
  PropertyValues,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import styles from './headerbar-icon-button.component.scss';
import { hostStyles } from '../../../host.styles';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { InteractiveIcon } from '../../interactive-icon/interactive-icon.component';

type Emphasis = 'subtle' | 'default' | 'selected';
type InteractiveIconEmphasis = InteractiveIcon['emphasis'];
type Size = 'm' | 'l';

const interactiveIconEmphasis: Record<Emphasis, InteractiveIconEmphasis> = {
  subtle: 'subtle',
  default: 'default',
  selected: 'highlight',
};

const iconButtonStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * HeaderbarIconButton component displays a slotted icon.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-headerbar-icon-button emphasis="default" size="m">
 *   <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 * </zui-headerbar-icon-button>
 * ```
 *
 * @slot icon - Slot for an icon
 */
@customElement('zui-headerbar-icon-button')
export class HeaderbarIconButton extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, iconButtonStyles];
  }

  /**
   * Disabled state of the IconButton
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Emphasis of the IconButton
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Aria label of the IconButton button
   */
  @property({ type: String })
  label = undefined;

  /**
   * Size of the IconButton
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  @queryAssignedNodes('icon', true, '[zuiIcon]')
  private _assignedIcons: HTMLElement[];

  private _updateIconSize(): void {
    this._assignedIcons.forEach((element) => element.setAttribute('size', this.size === 'l' ? 'l' : 'm'));
  }

  private _handleIconSlotchange(): void {
    this._updateIconSize();
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('size')) {
      this._updateIconSize();
    }
  }

  protected render(): TemplateResult {
    return html`
      <button
        ?disabled="${this.disabled}"
        aria-label="${ifDefined(this.label)}"
        tabindex="${ifDefined(!this.disabled ? 0 : undefined)}"
        type="button"
      >
        <zui-interactive-icon
          ?disabled="${this.disabled}"
          class="interactive-icon"
          emphasis="${interactiveIconEmphasis[this.emphasis]}"
        >
          <slot name="icon" @slotchange="${this._handleIconSlotchange}"></slot>
        </zui-interactive-icon>
      </button>
    `;
  }
}
