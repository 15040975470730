import { css, customElement, property, TemplateResult, unsafeCSS } from 'lit-element';
import { html } from 'lit-html';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';

import style from './slider-range.component.scss';
import { hostStyles } from '../../../host.styles';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { EventWithTarget } from '../../../types';
import type { SliderCustom } from '../slider-custom/slider-custom.component';

// import all the components we need
import '../slider-scale/slider-scale.component';
import '../slider-custom/slider-custom.component';
import { SliderBaseClass } from '../slider-base.class';
import { event } from '../../../decorators/event.decorator';
import { isValidStep, isValidRangeValue, sliderRangeValueConverter } from '../slider.utils';

const sliderStyles = css`
  ${unsafeCSS(style)}
`;

type RangeValue = [number, number];

/**
 * The slider-range is a form element that is used to select a number range from a range of values.
 * The full range is defined by a min and max attribute.
 * The selected range (`value`) is an array of two values for the start and end value of the range.
 *
 *  As the component uses `<zui-slider-custom>`, this component is similar in most of the behavior and styling to
 *  `<zui-slider-range>`,  with the difference that `<zui-slider>` parametrizes `<zui-slider-custom>` to have only
 *  thumb, while `<zui-slider-range>` delivers a two thumb `<zui-slider-custom>`.
 *
 * It's possible to have ticks and labels to visualize the selectable values.
 *
 * This is a wrapper for the custom slider and the scale.
 * The custom slider represents a completely custom made slider, which enables features like multi-thumb,
 * ghost-handle, odd scales etc.
 *
 * @example
 *
 * ```html
 *  <zui-slider-range
 *    min="0"
 *    max="10"
 *    value="2,4"
 *    step="1"
 *    tick-interval="0.5"
 *    label-interval="1"
 *    label-format="%.2f"
 *    readonly
 *    >
 *      <zui-slider-tick-label>one</<zui-slider-tick-label>
 *      <zui-slider-tick-label>two</<zui-slider-tick-label>
 *    </zui-slider-range>
 * ```
 *
 * @cssprop --zui-slider-width - The width of the slider (and scale)
 * @cssprop --zui-slider-padding- Defines a custom offset for the slider and scale (set it without an unit!)
 * @cssprop --zui-slider-min-padding - Defines a custom offset for the left-handed mininum area for the scale (set it without an unit!)
 * @cssprop --zui-slider-max-padding - Defines a custom offset for the right-handed maximum area for the scale (set it without an unit!)
 * @fires change - The change event is fired when the value has changed
 * @fires input - The input event is fired when the value of the has received input
 * @slot - The default slot, used for the custom tick labels
 */
@customElement('zui-slider-range')
export class SliderRange
  extends FormValidationMixin(FormDataHandlingMixin(SliderBaseClass))
  implements FormValidationElement<FormEnabledElement> {
  static readonly styles = [hostStyles, sliderStyles];

  /**
   * the tabindex of the slider-range
   */
  @property({ reflect: true, type: Number })
  tabindex = 0;

  /**
   * the tick interval for the slider-range
   */
  @property({ reflect: true, type: Number, attribute: 'tick-interval' })
  tickInterval = 0;

  /**
   * the label interval for the slider-range
   */
  @property({ reflect: true, type: Number, attribute: 'label-interval' })
  labelInterval = 0;

  /**
   * the format template of the label. Use https://github.com/alexei/sprintf.js/ as a reference.
   */
  @property({ reflect: true, type: String, attribute: 'label-format' })
  labelFormat: string;

  /**
   * the enabled/ disabled state of the active line
   */
  @property({ reflect: true, type: Boolean, attribute: 'active-line-disabled' })
  activeLineDisabled = false;

  /**
   * the value of the slider; begin and end are separated by a comma
   */
  @property({
    reflect: true,
    converter: sliderRangeValueConverter,
  })
  value: RangeValue = [this.min, this.max];

  /**
   * @private
   */
  @event({ eventName: 'change', bubbles: true, cancelable: false, composed: true })
  emitChangeEvent(): void {
    this.dispatchEvent(new Event('change', { bubbles: true, cancelable: false, composed: true }));
  }

  /**
   * @private
   */
  @event({ eventName: 'input', bubbles: true, cancelable: false, composed: true })
  emitInputEvent(): void {
    // input event is bubbling from the internal input element
  }

  connectedCallback(): void {
    super.connectedCallback();

    this.addValidator({ validator: this._overflowValidator, type: 'rangeOverflow' });
    this.addValidator({ validator: this._underflowValidator, type: 'rangeUnderflow' });
    this.addValidator({ validator: this._stepMismatchValidator, type: 'stepMismatch' });

    this.setDefaultValidityMessages({ rangeOverflow: 'The given value is greater than max.' });
    this.setDefaultValidityMessages({ rangeUnderflow: 'The given value is less than min.' });
    this.setDefaultValidityMessages({ stepMismatch: 'The given value does not match the step size.' });
  }

  private _syncValue({ target: { value } }: EventWithTarget<SliderCustom>): void {
    this.value = value as RangeValue;
  }

  private _overflowValidator = (): boolean => {
    return this.value.every((value) => value <= this.max);
  };

  private _underflowValidator = (): boolean => {
    return this.value.every((value) => value >= this.min);
  };

  private _stepMismatchValidator = (): boolean => {
    if (this.step === 'any') {
      return true;
    }

    return isValidStep(this.min, this.max, this.step) && isValidRangeValue(this.min, this.value, this.step);
  };

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);

    if (changedProperties.has('min') || changedProperties.has('max') || changedProperties.has('step')) {
      this.checkValidity();
    }
  }

  protected render(): TemplateResult {
    return html` <div class="main-container">
      <zui-slider-scale
        min="${this.min}"
        max="${this.max}"
        label-format="${ifDefined(this.labelFormat)}"
        label-interval="${this.labelInterval}"
        tick-interval="${this.tickInterval}"
        ?readonly="${this.readonly}"
        ?disabled="${this.disabled}"
      >
        <slot></slot>
      </zui-slider-scale>
      <zui-slider-custom
        zuiFormControl
        min="${this.min}"
        max="${this.max}"
        step="${this.step}"
        .value="${this.value}"
        active-line-start="${this.activeLineStart}"
        ?active-line-disabled="${this.activeLineDisabled}"
        ?readonly="${this.readonly}"
        ?disabled="${this.disabled}"
        @input="${this._syncValue}"
        dual-knobs
      ></zui-slider-custom>
    </div>`;
  }
}
