import { CSSResultArray, TemplateResult, customElement, html, property, state, css, unsafeCSS } from 'lit-element';
import { nothing } from 'lit-html';
import { event } from '../../decorators/event.decorator';
import '../icon-bar/icon-bar.component';

import { BaseElement } from '../base/BaseElement';

import { hostStyles } from '../../host.styles';
import style from './dialog-box.component.scss';

const RESIZE_WIDTH = 800;

type PrimaryButtonType = 'accept' | 'cancel';
type ConfirmPosition = 'left' | 'right' | 'auto';

export const dialogBoxStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The Dialogbox has a primary and a secondary button, a header, a slot for content and up to tree icons.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2768)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102392)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-dialogbox></zui-dialogbox>
 * ```
 *
 * @fires accept - The event which gets fired when the accept button gets clicked
 * @fires cancel - The event which gets fired when the cancel button gets clicked
 * @fires close - The event which gets fired when the close button gets clicked
 * @slot default - This is the default slot. It's an innerHtml of the dialogBox-element
 */
@customElement('zui-dialogbox')
export class DialogBox extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, dialogBoxStyles];
  }

  /**
   * This disables the dialogBox
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * This sets the header of the dialog box
   */
  @property({ reflect: true, type: String, attribute: 'header-text' })
  headerText: string | null;

  /**
   * This sets the text of the accept Label
   */
  @property({ reflect: true, type: String, attribute: 'accept-label' })
  acceptLabel = 'OK';

  /**
   * This sets the text of the cancel Label
   */
  @property({ reflect: true, type: String, attribute: 'cancel-label' })
  cancelLabel = 'Cancel';

  /**
   * This sets the primary button
   */
  @property({ reflect: true, type: String, attribute: 'primary-button' })
  primaryButton: PrimaryButtonType = 'accept';

  /**
   * This disables the accept button
   */
  @property({ reflect: true, type: Boolean, attribute: 'disable-accept' })
  disableAccept = false;

  /**
   * This disables the cancel button
   */
  @property({ reflect: true, type: Boolean, attribute: 'disable-cancel' })
  disableCancel = false;

  /**
   * Optional tooltip for the accept button
   */
  @property({ reflect: true, type: String, attribute: 'accept-tooltip' })
  acceptTooltip?: string;

  /**
   * Optional tooltip for the cancel button
   */
  @property({ reflect: true, type: String, attribute: 'cancel-tooltip' })
  cancelTooltip?: string;

  /**
   * If closable is enabled an additional button will be visible that throws a close event when it is clicked.
   */
  @property({ reflect: true, type: Boolean })
  closable = false;

  /**
   * This sets the position of the primary button
   */
  @property({ reflect: true, type: String, attribute: 'confirm-position' })
  confirmPosition: ConfirmPosition = 'auto';

  /**
   * emits an accept Event
   *
   * @param {DialogBox} detail is the payload
   * @private
   */
  @event({
    eventName: 'accept',
    bubbles: true,
    composed: true,
  })
  emitAcceptEvent(detail: this): void {
    this.dispatchEvent(
      new CustomEvent('accept', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * emits a cancel Event
   *
   * @param {DialogBox} detail is the payload
   * @private
   */
  @event({
    eventName: 'cancel',
    bubbles: true,
    composed: true,
  })
  emitCancelEvent(detail: this): void {
    this.dispatchEvent(
      new CustomEvent('cancel', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Close event is emitted by iconbar and bubbles through the dialogbox.
   * This method is here to signal the existence of this event via the event decorator
   *
   * @private
   */
  @event({
    eventName: 'close',
    bubbles: true,
    composed: true,
    cancelable: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  emitCloseEvent(): void {}
  /**
   * This internal property is used to signal that the 'button-border-left' attribute
   * should be set. This leads to the styling that buttons will have a fixed size and on the left side
   * of the buttons there will be a visual space so that the buttons will be right-aligned.
   */
  @state()
  private _buttonBorderLeft = false;

  private _widthObserver: ResizeObserver = new ResizeObserver(([entry]) => {
    // If the width is above the threshold, a flag will be set that triggers a rendering and will
    // the styling will be changed.
    // This change will also change the height of the component, which will again trigger the resize-observer.
    // This is no problem but in some rare conditions (especially in testing it's possible that both
    // observer invocations happen within a single browser rendering frame.
    // In this case, chrome would throw an exception (ResizeObserver loop limit exceeded).
    // To prevent this, we delay the operation to the next animation frame.
    requestAnimationFrame(() => {
      const buttonContainerWidth: number = entry.contentRect.width;

      this._buttonBorderLeft = buttonContainerWidth > RESIZE_WIDTH;
    });
  });

  /**
   * disconects the resizeObserver of the button container
   */
  disconnectedCallback(): void {
    this._widthObserver.disconnect();
    super.disconnectedCallback();
  }

  /**
   * Handler for the click event on the accept button, fires a accept event.
   */
  private _handleAcceptButtonClick(): void {
    this.emitAcceptEvent(this);
  }

  /**
   * Handler for the click event on the cancel button, fires a cancel event.
   */
  private _handleCancelButtonClick(): void {
    this.emitCancelEvent(this);
  }

  /**
   * Adds a tooltip if ellipsis is active
   *
   * @param mouseEvent mouseEvent
   */
  private _onMouseOverHeader(mouseEvent: MouseEvent): void {
    const headerDiv = mouseEvent.target as HTMLDivElement;
    // compares the visible width with the whole width of the header div
    // if the whole width is larger the title of the header div gets set to the header text
    if (headerDiv.offsetWidth < headerDiv.scrollWidth) {
      headerDiv.title = this.headerText ?? '';
    } else {
      headerDiv.title = '';
    }
  }
  /**
   * Detects operation system and adds an observer for the width of the button-container
   *
   * @param changedProperties changedProperties
   */
  protected firstUpdated(changedProperties: Map<string, string | number | symbol>): void {
    super.firstUpdated(changedProperties);
    this._buttonBorderLeft = this.getBoundingClientRect().width > RESIZE_WIDTH;
    this._widthObserver.observe(this);
  }

  protected render(): TemplateResult | void {
    return html` <section id="dialog" ?button-border-left=${this._buttonBorderLeft}>
      <div id="top-border"></div>
      <header id="header">
        <div @mouseover="${this._onMouseOverHeader}" id="headertextcontainer">${this.headerText}</div>
        <zui-icon-bar emphasis="subtle" ?closable=${this.closable}>
          <slot name="icon1" slot="icon1"></slot>
          <slot name="icon2" slot="icon2"></slot>
          <slot name="icon3" slot="icon3"></slot>
        </zui-icon-bar>
      </header>
      <section id="placeholder">
        <zui-scrollable-directive>
          <slot id="content"></slot>
        </zui-scrollable-directive>
      </section>
      <footer id="footer-container">
        <span id="front-divider"></span>
        <div id="button-container">
          <zui-div>
            <button id="accept-button" @click="${this._handleAcceptButtonClick}">${this.acceptLabel}</button>
            ${this.acceptTooltip !== undefined
              ? html`
                  <zui-tooltip-directive anchoring="cursor" trigger="focus,hover">
                    <zui-tooltip>${this.acceptTooltip}</zui-tooltip>
                  </zui-tooltip-directive>
                `
              : nothing}
          </zui-div>
          <span id="divider"></span>
          <zui-div>
            <button id="cancel-button" @click="${this._handleCancelButtonClick}">${this.cancelLabel}</button>
            ${this.cancelTooltip !== undefined
              ? html`
                  <zui-tooltip-directive anchoring="cursor" trigger="focus,hover">
                    <zui-tooltip>${this.cancelTooltip}</zui-tooltip>
                  </zui-tooltip-directive>
                `
              : nothing}
          </zui-div>
        </div>
      </footer>
    </section>`;
  }
}
