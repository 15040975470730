import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import style from './content-tab-bar.component.scss';
import { PropertyValues } from 'lit-element/lib/updating-element';
import { ContentTabItem } from '../content-tab-base.class';
import { isDefined } from '../../../utils/component.utils';

import '../content-tab-default-item/content-tab-default-item.component';
import '../content-tab-highlight-item/content-tab-highlight-item.component';
import '../content-tab-image-item/content-tab-image-item.component';

type Size = ContentTabItem['size'];
type Hierarchy = ContentTabItem['hierarchy'];

const contentTabBarStyles = css`
  ${unsafeCSS(style)}
`;

const wasClickOnCloseButton = (event: Event): boolean =>
  event
    .composedPath()
    .some((element) => (element as Element).tagName && (element as Element).tagName.toLowerCase() === 'zui-icon-close');

/**
 * The content tab bar is a feature component
 * The `zui-content-tab-bar` is intended to be used with `zui-content-tab-default-item`'s, `zui-content-tab-highlight-item`'s and `zui-content-tab-image-item`'s.
 * Each item should get a value for identification. When an item is selected it's value is reflected on the `zui-content-tab-bar` and an event is emitted.
 *
 * The content tab bar may only support using items of the same type!
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=52930%3A120)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---3.1?node-id=76949%3A367659)
 *
 * @example
 * HTML:
 *
 * Example with default items
 * ```html
 * <zui-content-tab-bar>
 *   <zui-content-tab-default-item text="Text 1" size="m" value="itemOne"></zui-content-tab-default-item>
 *   <zui-content-tab-default-item text="Text 2" size="m" value="itemTwo" disabled></zui-content-tab-default-item>
 *   <zui-content-tab-default-item text="Text 3" size="m" value="itemThree"><zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list></zui-content-tab-default-item>
 *   <zui-content-tab-default-item text="Text 4" size="m" value="itemFour" closable></zui-content-tab-default-item>
 * </zui-content-tab-bar>
 * ```
 *
 * Example with highlight items
 * ```html
 * <zui-content-tab-bar>
 *   <zui-content-tab-highlight-item text="Text 1" value="itemOne"></zui-content-tab-default-item>
 *   <zui-content-tab-highlight-item text="Text 2" value="itemTwo" disabled></zui-content-tab-default-item>
 *   <zui-content-tab-highlight-item text="Text 3" value="itemThree"><zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list></zui-content-tab-default-item>
 *   <zui-content-tab-highlight-item text="Text 4" value="itemFour" closable></zui-content-tab-default-item>
 * </zui-content-tab-bar>
 * ```
 *
 * Example with image items
 * ```html
 * <zui-content-tab-bar>
 *   <zui-content-tab-image-item text="Text 1" value="itemOne"></zui-content-tab-default-item>
 *   <zui-content-tab-image-item text="Text 2" value="itemTwo" disabled></zui-content-tab-default-item>
 *   <zui-content-tab-image-item text="Text 3" value="itemThree"><zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list></zui-content-tab-default-item>
 *   <zui-content-tab-image-item text="Text 4" value="itemFour" closable></zui-content-tab-default-item>
 * </zui-content-tab-bar>
 * ```
 * @fires content-tab-item-selected - fired when the tab selection was changed. Contains the value of the tab as payload.
 * @fires content-tab-item-closed - fired the close icon of a tab was clicked. Contains the value of the tab as payload.
 */
@customElement('zui-content-tab-bar')
export class ContentTabBar extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, contentTabBarStyles];
  }

  /**
   * disabled of all items inside
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * the size of all items inside
   */
  @property({ reflect: true, type: String })
  size: Size = 's';

  /**
   * hierarchy of all items inside
   */
  @property({ reflect: true, type: String })
  hierarchy: Hierarchy = 'first';

  /**
   * Value of the current selected tab
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Emit close event
   *
   * @param {CustomEvent} closeEvent the close event fired by the click of the close icon from component
   * @private
   */
  @event({
    eventName: 'content-tab-item-closed',
    bubbles: false,
    composed: false,
    cancelable: false,
  })
  emitContentTabItemClosedEvent(closeEvent: CustomEvent): void {
    this.dispatchEvent(
      new CustomEvent('content-tab-item-closed', {
        bubbles: false,
        composed: false,
        cancelable: false,
        detail: {
          value: closeEvent.detail.value,
        },
      })
    );
  }

  /**
   * Emits a change event
   *
   * @private
   */
  @event({
    eventName: 'content-tab-item-selected',
    bubbles: false,
    cancelable: false,
    composed: false,
  })
  emitContentTabItemSelectedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('content-tab-item-selected', {
        bubbles: false,
        cancelable: false,
        composed: false,
        detail: {
          value: this.value,
        },
      })
    );
  }

  @queryAssignedNodes('', true, '[zui-internal-is-content-tab-item]')
  private _assignedTabs: ContentTabItem[];

  private _propagateDisabled = false;

  private _handleSlotchange(): void {
    this._propagateState();
  }

  private _handleClick(clickEvent): void {
    clickEvent.stopPropagation();
    clickEvent.stopImmediatePropagation();
    // When the close icon was clicked, we don't want to select the item.
    if (!wasClickOnCloseButton(clickEvent) && this.value !== clickEvent.target.value) {
      this.value = clickEvent.target.value;
      this.emitContentTabItemSelectedEvent();
    }
  }

  private _handleClose(closeEvent: CustomEvent): void {
    closeEvent.stopPropagation();
    closeEvent.stopImmediatePropagation();
    this.emitContentTabItemClosedEvent(closeEvent);
  }

  private _propagateState(): void {
    this._assignedTabs.forEach((tab) => {
      if (this._propagateDisabled) {
        tab.disabled = this.disabled;
      }

      if (isDefined(this.value)) {
        tab.emphasis = this.value === tab.value ? 'selected' : 'default';
      }

      tab.hierarchy = this.hierarchy;
      tab.size = this.size;
    });
  }

  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);

    if (this.disabled || changedProperties.get('disabled')) {
      this._propagateDisabled = true;
    }
  }

  protected updated(changedProperties: PropertyValues): void {
    super.updated(changedProperties);
    this._propagateState();
  }

  protected render(): TemplateResult | void {
    return html`<slot
      @content-tab-item-closed=${this._handleClose}
      @click="${this._handleClick}"
      @slotchange="${this._handleSlotchange}"
    ></slot>`;
  }
}
