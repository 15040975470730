import {
  css,
  customElement,
  html,
  LitElement,
  property,
  PropertyValues,
  queryAssignedNodes,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { hostStyles } from '../../host.styles';
import { preparePortal, unregisterPortal } from '../../utils/portal.utils';
import type { Portal } from '../../components/portal/portal.component';

import style from './portal.directive.scss';

export const PORTAL_DIRECTIVE_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * This directive allows projecting arbitrary html elements into a portal.
 * The targeted portal (or at least a default portal) must exist in the DOM somewhere.
 *
 * @example
 * HTML:
 * ```html
 * ```
 *
 * @slot - The default slot content will be projected into the portal.
 */
@customElement('zui-portal-directive')
export class PortalDirective extends LitElement {
  static readonly styles = [hostStyles, PORTAL_DIRECTIVE_STYLES];

  /**
   * The destination can be given to target a specific portal other then the default one.
   * It behaves like the slot attribute, but for portals.
   */
  @property({ reflect: true })
  portal?: string;

  /**
   * An optional level to be used if the portal is created dynamically.
   */
  @property({ reflect: true, type: Number })
  level?: number;

  /**
   * Allows restoring portal contents after they are destroyed.
   *
   * @deprecated Please use the clone option instead
   * @todo Remove once deprecated property is removed
   */
  @property({ reflect: true, type: Boolean })
  restore = false;

  /**
   * Clones projected contents instead of moving them.
   */
  @property({ reflect: true, type: Boolean })
  clone = false;

  /**
   * Collects all slotted elements (!) to be taken into account for projection.
   */
  @queryAssignedNodes('', true, '*')
  private _projectedElements: HTMLElement[];

  // stores the associated portal instance once attached
  private _portal?: Portal;

  /**
   * Projects the given slot elements contents to a portal.
   * **Only one element (not a text node!) can be projected!**
   */
  async projectContents(): Promise<void> {
    // as the contents are actually moved, the slot change event is triggered
    // twice, but the second time the slot is already missing its contents...
    const [element] = this._projectedElements;
    // weirdly, the slot change listener is triggered even after disconnect,
    // thus we have to skip the content projection once disconnected
    if (element !== undefined && this.isConnected) {
      // check for related portal (and create if not existing yet)
      this._portal = await preparePortal(this.portal, this.level);
      // pass through clone (and deprecated resotre) option
      this._portal.clone = this.clone;
      this._portal.restore = this.restore;
      // update the portal content with the latest slot contents
      this._portal.showContent(element);
    }
  }

  // remove portal along with directive
  disconnectedCallback(): void {
    unregisterPortal(this._portal?.name);
    super.disconnectedCallback();
  }

  /**
   * @todo Remove as well once deprecated property is removed
   * @param changedProperties Map
   */
  protected update(changedProperties: PropertyValues<this>): void {
    super.update(changedProperties);
    if (changedProperties.has('restore')) {
      console.warn('Deprecated restore: Please use the `clone` option instead.');
    }
  }

  protected render(): TemplateResult {
    return html`<slot @slotchange="${this.projectContents}"></slot>`;
  }
}
