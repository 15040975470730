import { css, customElement, property, PropertyValues, query, TemplateResult, unsafeCSS } from 'lit-element';
import { html } from 'lit-html';
import { event } from '../../../decorators/event.decorator';
import type { EventWithTarget } from '../../../types';

import style from './slider-basic.component.scss';
import { hostStyles } from '../../../host.styles';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { SliderBaseClass } from '../slider-base.class';

const sliderStyles = css`
  ${unsafeCSS(style)}
`;

// FIXME: remove in next major release
// eslint-disable-next-line jsdoc/require-example
/**
 * **Deprecated**. This component has been replaced by the `zui-slider-custom` component and is not used anymore.
 * This is here for compatibility reasons but shouldn't be used anymore.
 * Instead use `zui-slider-basic`.
 *
 * This component will be removed in the future.
 *
 * @deprecated
 * @private
 */
@customElement('zui-slider-basic')
export class SliderBasic extends SliderBaseClass {
  static readonly styles = [hostStyles, sliderStyles];

  /**
   * The name of the element for form participation.
   */
  @property({ reflect: true, type: String })
  name: string;

  /**
   * the tabindex of the slider
   */
  @property({ reflect: true, type: Number })
  tabindex = 0;

  /**
   * the start value of the active line
   * defaults to negative infinity to ensure it defaults to the very beginning of the slider
   */
  @property({ reflect: true, type: String, attribute: 'active-line-start' })
  activeLineStart: 'min' | 'max' | number = 'min';

  /**
   * the enabled/ disabled state of the active line
   */
  @property({ reflect: true, type: Boolean, attribute: 'active-line-disabled' })
  activeLineDisabled = false;

  /**
   * The elements readonly attribute.
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * The elements disabled attribute.
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * the value of the slider
   */
  @property({ reflect: true, type: Number })
  value = this.min;

  /**
   * @private
   */
  @event({ eventName: 'change', bubbles: true, cancelable: false, composed: true })
  emitChangeEvent(): void {
    this.dispatchEvent(new Event('change', { bubbles: true, cancelable: false, composed: true }));
  }

  /**
   * @private
   */
  @event({ eventName: 'input', bubbles: true, cancelable: false, composed: true })
  emitInputEvent(): void {
    // input event is bubbling from the internal input element
  }

  private get _derivedActiveLineStart(): number {
    switch (this.activeLineStart) {
      case 'max':
        return this.max;
      case 'min':
        return this.min;
      default:
        return Number(this.activeLineStart);
    }
  }

  @query('input[type=range]')
  private _inputRef: HTMLInputElement;

  // TODO: use mixin
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('focus', this._handleFocus);
  }

  disconnectedCallback(): void {
    this.removeEventListener('focus', this._handleFocus);
    super.disconnectedCallback();
  }

  /**
   * In case, the component is not disabled or in readonly-mode,
   * we emit a change event when the value of the slider was changed
   */
  private _handleChange(): void {
    this.emitChangeEvent();
  }

  private _handleInput(event: EventWithTarget<HTMLInputElement, InputEvent>): void {
    this.value = Number(event.target.value);
  }

  private _handleFocus(): void {
    this._inputRef.focus();
  }

  /**
   * Calculate the active-line width used as CSS width percentage value.
   * The width of the active-line depends on the min, max and current value.
   *
   * @param value the slider-value that is used for calculation. Typically, this will be `this.value`, but other values
   *   are possible too.
   *
   * @returns the width of the active-line
   */
  private _getActiveLineWidth(value: number): number {
    if (value >= this.max) {
      return 100;
    }

    if (value < this.min) {
      return 0;
    }

    const normalizedMax = this.max - this.min;
    const normalizedValue = value - this.min;
    const percentage = (normalizedValue * 100) / normalizedMax;

    return percentage;
  }

  protected parseValue(value: string): number {
    return Number(value);
  }

  protected serializeValue(value: number): string {
    return value ? value.toString() : '';
  }

  // sadly there is a bug / race condition in the current LitElement, that
  // prevents setting negative values for input range elements
  // FIXME: this seems to be fixed in lit -> https://tinyurl.com/fix-lit-range
  protected updated(changedProperties: PropertyValues): void {
    // thus we have to overwrite this everytime explicitly :(
    // if minMax are changed
    if (changedProperties.has('min') || changedProperties.has('max') || changedProperties.has('step')) {
      this._inputRef.value = String(this.value);
    }
  }

  protected render(): TemplateResult {
    return html` <div class="slider-container">
      <div id="track" class="line"></div>
      <div id="active-line-container" class="line">
        <div
          id="active-line-start"
          style="${this._derivedActiveLineStart > this.min ? unsafeCSS(`---zui-active-line-visibility: hidden;`) : ''}"
        ></div>
        <div id="active-line-full-width">
          <div
            id="active-line"
            style="
              ${this._getActiveLineWidth(this.value) >= this._getActiveLineWidth(this._derivedActiveLineStart)
              ? unsafeCSS(`left: calc(${this._getActiveLineWidth(this._derivedActiveLineStart)}% - 0.5px); `)
              : unsafeCSS(`right: calc(${100 - this._getActiveLineWidth(this._derivedActiveLineStart)}% - 0.5px);`)}
              ${unsafeCSS(
              `width: ${Math.abs(
                this._getActiveLineWidth(this._derivedActiveLineStart) - this._getActiveLineWidth(this.value)
              )}%;`
            )}"
          ></div>
        </div>
        <div
          id="active-line-end"
          style="${this._derivedActiveLineStart < this.max ? unsafeCSS(`---zui-active-line-visibility: hidden;`) : ''}"
        ></div>
      </div>
      <input
        name="${ifDefined(this.name)}"
        type="range"
        .value="${this.value}"
        @change="${this._handleChange}"
        min="${this.min}"
        max="${this.max}"
        step="${this.step}"
        aria-valuemin="${this.min}"
        aria-valuemax="${this.max}"
        aria-valuetext="${this.value}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        @input="${this._handleInput}"
      />
    </div>`;
  }
}
