// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { CSSResultArray, TemplateResult, customElement, html, property, query, css, unsafeCSS } from 'lit-element';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { event } from '../../../decorators/event.decorator';
import { hostStyles } from '../../../host.styles';
import { RealBaseElement } from '../../base/BaseElement';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin';
import style from './radio-button.component.scss';

const radioButtonStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * Radio buttons allow the user to select only one of a set.
 * The component has to be placed in a radio button group to create the standard radio button behaviour, of only one selected button in a group.
 *
 * Its value must be set.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2721)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=23637%3A483839)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-radio-button-group value="small">
 *   <zui-radio-button value="small">Small</zui-radio-button>
 *   <zui-radio-button value="big">Big</zui-radio-button>
 * </zui-radio-button-group>
 * ```
 *
 * @slot default - Allows passing arbitrary content as label
 * @fires input event on user inter action
 * @fires change event on input change
 */
@customElement('zui-radio-button')
export class RadioButton extends DelegateFocusMixin(RealBaseElement) {
  static get styles(): CSSResultArray {
    return [hostStyles, radioButtonStyles];
  }
  /**
   * The label of the radio button
   */
  @property({ reflect: true, type: String })
  label;

  /**
   * Checked state
   *
   * @default false
   */
  @property({ reflect: true, type: Boolean })
  checked = false;

  /**
   * disabled state
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Value of the radio button
   *
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * the tabindex of the radio button
   */
  @property({ reflect: true, type: Number })
  tabindex = 0;

  @event({
    eventName: 'input',
    bubbles: true,
    composed: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  emitInputEvent(): void {
    // this is for generation magic only, because the input event from the input is composed
  }

  @event({
    eventName: 'change',
    bubbles: true,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: false,
        composed: false,
      })
    );
  }

  @query('input[type="radio"]')
  private _inputRef: HTMLInputElement;

  private _handleChange(): void {
    // sync checked state, dispatch change
    this.checked = this._inputRef.checked;
    this.emitChangeEvent();
  }

  protected render(): TemplateResult | void {
    return html`
      <input
        type="radio"
        id="radio-button"
        .value="${this.value}"
        ?zuiCaptureFocus=${!this.disabled}
        ?disabled=${this.disabled}
        .checked=${this.checked}
        @change="${this._handleChange}"
      />
      <label for="radio-button" class="${classMap({ touch: this.hasTouch })}">
        <div>
          <slot>${this.label ? html`${this.label}` : nothing}</slot>
        </div>
      </label>
    `;
  }
}
