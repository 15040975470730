import { CSSResult, css } from 'lit-element';

// hides components which have the hidden attribute set
const VISIBILTY_CONTROL = css`
  :host {
    display: block;
  }

  :host([hidden]) {
    display: none;
  }
`;

// resets properties potentially bleeding in through inheritance
// s. original answer https://stackoverflow.com/a/30536051/1146207
// s. latest property list https://www.w3.org/TR/CSS22/propidx.html
// s. reset inherit properties https://developer.mozilla.org/en-US/docs/Web/CSS/initial
// s. summary in wiki https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_wiki/wikis/DI_ZUi-Web.wiki/23228/Deriving-inherited-CSS-properties
const RESET_INHERITANCE = css`
  :host {
    azimuth: initial;
    border-collapse: initial;
    border-spacing: initial;
    caption-side: initial;
    color: initial;
    cursor: initial;
    direction: initial;
    elevation: initial;
    empty-cells: initial;
    font-family: initial;
    font-size: initial;
    font-style: initial;
    font-variant: initial;
    font-weight: initial;
    font: initial;
    letter-spacing: initial;
    line-height: initial;
    list-style-image: initial;
    list-style-position: initial;
    list-style-type: initial;
    list-style: initial;
    orphans: initial;
    pitch-range: initial;
    pitch: initial;
    quotes: initial;
    richness: initial;
    speak-header: initial;
    speak-numeral: initial;
    speak-punctuation: initial;
    speak: initial;
    speech-rate: initial;
    stress: initial;
    text-align: initial;
    text-indent: initial;
    text-transform: initial;
    visibility: initial;
    voice-family: initial;
    volume: initial;
    white-space: initial;
    widows: initial;
    word-spacing: initial;
  }
`;

/**
 * @type {CSSResult}
 * @exports
 */
export const hostStyles: CSSResult = css`
  ${RESET_INHERITANCE}
  ${VISIBILTY_CONTROL}
    /* enforce consistent selection color for all zui components */
  ::selection {
    background-color: var(--zui-color-selection-background);
  }
`;
