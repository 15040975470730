import { CSSResult, customElement, html, property, PropertyValues, queryAll, TemplateResult } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';
import { hostStyles } from '../../../host.styles';
import { DateTime } from 'luxon';
import {
  DatePickerWeekdayEnum,
  daysOfWeekConverter,
  getCalendarDaysForSelectedMonth,
  getDateTimesFromJsDates,
  getDefaultLocale,
  getNextMonthStart,
  getPreviousMonthStart,
  getStartOfMonth,
  getStartOfMonthName,
  getWeekdays,
  hasWeekday,
  isoDateConverter,
  isSameDay,
  isSameMonth,
  isSameYear,
  someIsSameDay,
  someIsSameMonth,
  someIsSameYear,
} from '../utils/date-picker.utils';
import { event } from '../../../decorators/event.decorator';

// import needed other componets
import { GridCell } from '../../picker/grid-cell/grid-cell.component';
import '../../picker/header-cell/header-cell.component';
import '../../picker/picker-header/picker-header.component';
import '../../picker/picker-grid/picker-grid.component';

interface HeaderCellTemplateData {
  text: string;
  value: string;
}

interface GridCellTemplateData {
  content: string;
  disabled: boolean;
  emphasis: Emphasis;
  value: string;
}

type Emphasis = 'default' | 'selected' | 'subtle';

/**
 * The date picker day picker is a feature component that should be used inside the date picker component for selecting days.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-day-picker
 *   disabled-days-of-week="Saturday,Sunday"
 *   locale="en-US"
 *   max="2021-12-31T23:59:59.999+01:00"
 *   min="2021-01-01T00:00:00.000+01:00"
 *   selected-date="2021-06-15T08:00:00.000+02:00"
 *   week-start="Monday">
 * </zui-date-picker-day-picker>
 * ```
 * @fires {CustomEvent} day-picker-day-selected - emits the selected day
 * @fires {CustomEvent} dayPickerDaySelected - (Deprecated) emits the selected day
 * @fires {CustomEvent} day-picker-month-selected - emits the selected month
 * @fires {CustomEvent} dayPickerMonthSelected - (Deprecated) emits the selected month
 * @fires {CustomEvent} day-picker-next-month-selected - emits the start and end of next month
 * @fires {CustomEvent} dayPickerNextMonthSelected - (Deprecated) emits the start and end of next month
 * @fires {CustomEvent} day-picker-previous-month-selected - emits the start and end of previous month
 * @fires {CustomEvent} dayPickerPreviousMonthSelected - (Deprecated) emits the start and end of previous month
 * @fires {CustomEvent} day-picker-weekday-selected - emits the selected weekday
 * @fires {CustomEvent} dayPickerWeekdaySelected - (Deprecated) emits the selected weekday
 */
@customElement('zui-date-picker-day-picker')
export class DatePickerDayPicker extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles];
  }

  /**
   * whether the month select is disabled or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'month-select-disabled' })
  monthSelectDisabled = false;

  /**
   * max date
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  max: Date | null = null;

  // TODO: remove in version 2.0
  /**
   * (Deprecated) maxDate use max instead
   *
   * @returns Date max date
   *
   * @deprecated use max instead
   */
  @property({ reflect: true, type: String, attribute: 'max-date', converter: isoDateConverter })
  get maxDate(): Date | null {
    return this.max;
  }

  set maxDate(value: Date | null) {
    console.warn('Deprecated property maxDate used. Use max instead.');

    this.max = value;
  }

  private get _maxDateDT(): DateTime | undefined {
    return this.max ? DateTime.fromJSDate(this.max) : undefined;
  }

  /**
   * min date
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  min: Date | null = null;

  private get _minDateDT(): DateTime | undefined {
    return this.min ? DateTime.fromJSDate(this.min) : undefined;
  }

  // TODO: remove in version 2.0
  /**
   * (Deprecated) minDate use min instead
   *
   * @returns Date min date
   *
   * @deprecated use min instead
   */
  @property({ reflect: true, type: String, attribute: 'min-date', converter: isoDateConverter })
  get minDate(): Date | null {
    return this.min;
  }
  set minDate(value: Date | null) {
    console.warn('Deprecated property minDate used. Use min instead.');

    this.min = value;
  }

  /**
   * disabled dates
   */
  @property({ type: Array, attribute: false })
  disabledDates: Date[] = [];

  /**
   * disabled months
   */
  @property({ type: Array, attribute: false })
  disabledMonths: Date[] = [];

  /**
   * disabled years
   */
  @property({ type: Array, attribute: false })
  disabledYears: Date[] = [];

  /**
   * disabled days of week
   */
  @property({ reflect: true, type: String, attribute: 'disabled-days-of-week', converter: daysOfWeekConverter })
  disabledDaysOfWeek: DatePickerWeekdayEnum[] = [];

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * selected date
   */
  @property({ reflect: true, type: String, attribute: 'selected-date', converter: isoDateConverter })
  selectedDate: Date | null = null;

  private get _selectedDateDT(): DateTime | undefined {
    return this.selectedDate ? DateTime.fromJSDate(this.selectedDate) : undefined;
  }

  /**
   * current date
   *
   * @returns currentDate
   */
  @property({ reflect: true, type: String, attribute: 'current-date', converter: isoDateConverter })
  get currentDate(): Date {
    if (!this._internalCurrentDate) {
      return this.selectedDate ?? new Date();
    }

    return this._internalCurrentDate;
  }

  set currentDate(current: Date) {
    const oldValue = this._internalCurrentDate;
    this._internalCurrentDate = current;
    this.requestUpdate('currentDate', oldValue);
  }

  private get _currentDateDT(): DateTime {
    return DateTime.fromJSDate(this.currentDate);
  }

  /**
   * optional weekstart that overrides the locale
   */
  @property({ reflect: true, type: String, attribute: 'week-start' })
  weekStart: DatePickerWeekdayEnum;

  /**
   * Emits a custom day-picker-month-selected event when a month is selected
   *
   * @param detail object with value
   * @param detail.value the selected month
   *
   * @private
   */
  @event({
    eventName: 'day-picker-month-selected',
    bubbles: true,
    composed: true,
  })
  emitDayPickerMonthSelectedEvent(detail: { value: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('dayPickerMonthSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('day-picker-month-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom day-picker-next-month-selected event when the next month is selected
   *
   * @param detail object with value
   * @param detail.startOfMonth the start of next selected month
   * @param detail.endOfMonth the end of next selected month
   *
   * @private
   */
  @event({
    eventName: 'day-picker-next-month-selected',
    bubbles: true,
    composed: true,
  })
  emitDayPickerNextMonthSelectedEvent(detail: { startOfMonth: Date; endOfMonth: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('dayPickerNextMonthSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('day-picker-next-month-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom day-picker-previous-month-selected event when the previous month is selected
   *
   * @param detail object with value
   * @param detail.startOfMonth the start of previous selected month
   * @param detail.endOfMonth the end of previous selected month
   *
   * @private
   */
  @event({
    eventName: 'day-picker-previous-month-selected',
    bubbles: true,
    composed: true,
  })
  emitDayPickerPreviousMonthSelectedEvent(detail: { startOfMonth: Date; endOfMonth: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('dayPickerPreviousMonthSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('day-picker-previous-month-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom day-picker-day-selected event when a date is selected
   *
   * @param detail object with value
   * @param detail.value the selected date
   *
   * @private
   */
  @event({
    eventName: 'day-picker-day-selected',
    bubbles: true,
    composed: true,
  })
  emitDayPickerDaySelectedEvent(detail: { value: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('dayPickerDaySelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('day-picker-day-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom day-picker-weekday-selected event when a weekday is selected
   *
   * @param detail object with value
   * @param detail.value the selected weekday Mo, Tu, We, ...
   *
   * @private
   */
  @event({
    eventName: 'day-picker-weekday-selected',
    bubbles: true,
    composed: true,
  })
  emitDayPickerWeekdaySelectedEvent(detail: { value: string }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('dayPickerWeekdaySelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('day-picker-weekday-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  @queryAll('zui-picker-grid-cell')
  private _pickerGridCells: GridCell[];

  private _internalCurrentDate: Date | null = null;

  private get _pickerHeaderValue(): string {
    return `${getStartOfMonthName(this._currentDateDT.year, this._currentDateDT.month, this.locale)} ${
      this._currentDateDT.year
    }`;
  }

  private get _gridCellDates(): DateTime[] {
    const startOfMonth = getStartOfMonth(this._currentDateDT.year, this._currentDateDT.month);

    return getCalendarDaysForSelectedMonth(startOfMonth, this.locale, this.weekStart);
  }

  private get _headerCellData(): HeaderCellTemplateData[] {
    return getWeekdays(this.locale, this.weekStart);
  }

  private get _disabledGridCellConditions(): ((date: DateTime) => boolean)[] {
    const disabledDates = getDateTimesFromJsDates(this.disabledDates);
    const disabledMonths = getDateTimesFromJsDates(this.disabledMonths);
    const disabledYears = getDateTimesFromJsDates(this.disabledYears);

    return [
      // minDate, if set
      (date): boolean => (this._minDateDT ? date.toMillis() < this._minDateDT.toMillis() : false),
      // maxDate, if set
      (date): boolean => (this._maxDateDT ? date.toMillis() > this._maxDateDT.toMillis() : false),
      // disabledDays
      (date): boolean => someIsSameDay(date, disabledDates),
      // disabledMonths
      (date): boolean => someIsSameMonth(date, disabledMonths),
      // disabledYears
      (date): boolean => someIsSameYear(date, disabledYears),
      // disabledWeekDays
      (date): boolean => hasWeekday(date, this.disabledDaysOfWeek),
    ];
  }

  private get _gridCellFocusConditions(): (() => boolean)[] {
    return [
      (): boolean => (this._selectedDateDT ? isSameMonth(this._selectedDateDT, this._currentDateDT) : false),
      (): boolean => (this._selectedDateDT ? isSameYear(this._selectedDateDT, this._currentDateDT) : false),
    ];
  }

  private get _gridCellSelectedConditions(): ((date: DateTime) => boolean)[] {
    return [(date): boolean => (this._selectedDateDT ? isSameDay(date, this._selectedDateDT) : false)];
  }

  private get _gridCellSubtleConditions(): ((date: DateTime) => boolean)[] {
    const startOfMonth = getStartOfMonth(this._currentDateDT.year, this._currentDateDT.month);

    return [(date): boolean => !isSameMonth(date, startOfMonth)];
  }

  private _isGridCellDisabled(date: DateTime): boolean {
    return this._disabledGridCellConditions.some((predicate) => predicate(date));
  }

  private _canFocusGridCell(): boolean {
    return this._gridCellFocusConditions.every((predicate) => predicate());
  }

  private _getGridCellEmphasis(date: DateTime): Emphasis {
    const isSelected = this._gridCellSelectedConditions.every((predicate) => predicate(date));
    const isSubtle = this._gridCellSubtleConditions.every((predicate) => predicate(date));

    return isSelected ? 'selected' : isSubtle ? 'subtle' : 'default';
  }

  private _headerCellTemplate = ({ text, value }: HeaderCellTemplateData): TemplateResult => {
    return html`
      <zui-picker-header-cell
        slot="pickerGridHeaderCells"
        style="--zui-picker-header-cell-width: 32px; --zui-picker-header-cell-height: 56px"
        text="${text}"
        value="${value}"
      ></zui-picker-header-cell>
    `;
  };

  private _gridCellTemplate = ({ content, disabled, emphasis, value }: GridCellTemplateData): TemplateResult => {
    return html`
      <zui-picker-grid-cell
        ?disabled="${disabled}"
        emphasis="${emphasis}"
        slot="pickerGridCells"
        style="--zui-picker-grid-cell-width: 32px"
        value="${value}"
      >
        ${content}
      </zui-picker-grid-cell>
    `;
  };

  private _focusSelectedDate(): void {
    if (!this._canFocusGridCell()) {
      return;
    }

    // TODO: rework to make it more readable
    const pickerGridCellMatchingSelectedDate = Array.from(this._pickerGridCells).find((pickerGridCell) =>
      this._selectedDateDT ? isSameDay(DateTime.fromISO(pickerGridCell.value), this._selectedDateDT) : false
    );

    pickerGridCellMatchingSelectedDate?.focus();
  }

  private async _handleDaySelected({ detail }: CustomEvent<{ selected: GridCell; value: string }>): Promise<void> {
    const { selected, value } = detail;

    if (selected.disabled) {
      return;
    }

    this.selectedDate = new Date(value);

    this.emitDayPickerDaySelectedEvent({
      value: DateTime.fromISO(value).toJSDate(),
    });
  }

  private _handleMonthSelected(): void {
    this.emitDayPickerMonthSelectedEvent({ value: this.currentDate });
  }

  private _handleNextMonthSelected(): void {
    this.currentDate = getNextMonthStart(this._currentDateDT.year, this._currentDateDT.month).toJSDate();

    this.emitDayPickerNextMonthSelectedEvent({
      startOfMonth: this.currentDate,
      endOfMonth: this._currentDateDT.endOf('month').toJSDate(),
    });
  }

  private _handlePreviousMonthSelected(): void {
    this.currentDate = getPreviousMonthStart(this._currentDateDT.year, this._currentDateDT.month).toJSDate();

    this.emitDayPickerPreviousMonthSelectedEvent({
      startOfMonth: this.currentDate,
      endOfMonth: this._currentDateDT.endOf('month').toJSDate(),
    });
  }

  private _handleWeekdaySelected({ detail }: CustomEvent<{ value: string }>): void {
    this.emitDayPickerWeekdaySelectedEvent({ value: detail.value });
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('selectedDate') || changedProperties.has('currentDate')) {
      this._focusSelectedDate();
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-picker-header
        ?disabled="${this.monthSelectDisabled}"
        value="${this._pickerHeaderValue}"
        @picker-header-current-selected="${this._handleMonthSelected}"
        @picker-header-next-selected="${this._handleNextMonthSelected}"
        @picker-header-previous-selected="${this._handlePreviousMonthSelected}"
      >
        <zui-interactive-icon slot="icon-left">
          <zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-icon-arrow-outline-arrow-outline-actually-centred-left>
        </zui-interactive-icon>
        <zui-interactive-icon slot="icon-right">
          <zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-icon-arrow-outline-arrow-outline-actually-centred-right>
        </zui-interactive-icon>
      </zui-picker-header>

      <zui-picker-grid
        columns="7"
        @picker-grid-cell-selected="${this._handleDaySelected}"
        @picker-header-cell-selected="${this._handleWeekdaySelected}"
      >
        ${this._headerCellData.map(this._headerCellTemplate)}
        ${this._gridCellDates.map((dateTime) => {
          const disabled = this._isGridCellDisabled(dateTime);
          const emphasis = this._getGridCellEmphasis(dateTime);

          return this._gridCellTemplate({
            content: dateTime.day.toString(),
            disabled,
            emphasis,
            value: dateTime.toISO(),
          });
        })}
      </zui-picker-grid>
    `;
  }
}
