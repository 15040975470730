import { css, CSSResult, customElement, html, property, queryAsync, TemplateResult, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import styles from './grid-cell.component.scss';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { BaseElement } from '../../base/BaseElement';

type Emphasis = 'default' | 'selected' | 'subtle';

const gridCellStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * A rectangular styled cell UI component, which is used in the general picker widget
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-picker-grid-cell
 *  value="february"
 *  emphasis="selected"
 *  style="--zui-picker-grid-cell-width: 40px; --zui-picker-grid-cell-height: 40px"
 * >Feb</zui-picker-grid-cell>
 * ```
 *
 * @slot - default slot for text content
 * @cssprop --zui-picker-grid-cell-border - border style of the cell
 * @cssprop --zui-picker-grid-cell-height - sets the height of the cell
 * @cssprop --zui-picker-grid-cell-min-width - sets the minimum width of the cell
 */
@customElement('zui-picker-grid-cell')
export class GridCell extends BaseElement {
  static get styles(): CSSResult[] {
    return [hostStyles, gridCellStyles];
  }

  /**
   * disabled state of the grid cell
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * the value of the grid cell
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Defines one of three possible emphasis states (default/selected/subtle);
   */
  @property({ reflect: true })
  emphasis: Emphasis = 'default';

  @queryAsync('button')
  private _pickerGridCellButton: Promise<HTMLButtonElement>;

  // TODO: this should be an mixin
  async focus(options = { preventScroll: true }): Promise<void> {
    if (!this.disabled) {
      const button = await this._pickerGridCellButton;
      button.focus(options);
    }
  }

  async blur(): Promise<void> {
    const button = await this._pickerGridCellButton;
    button.blur();
  }

  // map a click to a .focus() for Firefox + Webkit
  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this._handleClick);
  }

  disconnectedCallback(): void {
    this.removeEventListener('click', this._handleClick);
    super.disconnectedCallback();
  }

  private _handleClick(): void {
    this.focus();
  }

  protected render(): TemplateResult {
    return html`<button ?disabled="${this.disabled}" value="${ifDefined(this.value)}"
      ><span><slot></slot></span
    ></button>`;
  }
}
