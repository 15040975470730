import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  css,
  unsafeCSS,
  property,
  queryAll,
  PropertyValues,
} from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import styles from './date-picker-year-picker.component.scss';
import { DateTime } from 'luxon';
import { event } from '../../../decorators/event.decorator';
import {
  getCalendarYearsForSelectedDecade,
  getDateTimesFromJsDates,
  getDefaultLocale,
  isInDecade,
  isoDateConverter,
  isSameYear,
  someIsSameYear,
} from '../utils/date-picker.utils';

import { GridCell } from '../../picker/grid-cell/grid-cell.component';
import '../../picker/header-cell/header-cell.component';
import '../../picker/picker-grid/picker-grid.component';
import '../../picker/picker-header/picker-header.component';

const datePickerYearPickerStyles = css`
  ${unsafeCSS(styles)}
`;

type Emphasis = 'default' | 'selected' | 'subtle';

/**
 * The date picker year picker is a feature component that should be used inside the date picker component for selecting years.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-year-picker
 *   locale="en-US"
 *   max="2040-12-31T23:59:59.999+01:00"
 *   min="2000-01-01T00:00:00.000+01:00">
 * </zui-date-picker-year-picker>
 * ```
 * @fires {CustomEvent} year-picker-decade-selected - emits the selected decade
 * @fires {CustomEvent} yearPickerDecadeSelected - (Deprecated) emits the selected decade
 * @fires {CustomEvent} year-picker-next-decade-selected - emits the start and end of next selected decade
 * @fires {CustomEvent} yearPickerNextDecadeSelected - (Deprecated) emits the start and end of next selected decade
 * @fires {CustomEvent} year-picker-previous-decade-selected - emits the start and end of previous selected decade
 * @fires {CustomEvent} yearPickerPreviousDecadeSelected - (Deprecated) emits the start and end of previous selected decade
 * @fires {CustomEvent} year-picker-year-selected - emits the selected year
 * @fires {CustomEvent} yearPickerYearSelected - (Deprecated) emits the selected year
 */
@customElement('zui-date-picker-year-picker')
export class DatePickerYearPicker extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, datePickerYearPickerStyles];
  }

  /**
   * whether the decade select is disabled or not
   */
  @property({ reflect: true, type: Boolean, attribute: 'decade-select-disabled' })
  decadeSelectDisabled = false;

  /**
   * disabled years
   */
  @property({ type: Array, attribute: false })
  disabledYears: Date[] = [];

  /**
   * locale
   */
  @property({ reflect: true, type: String })
  locale = getDefaultLocale();

  /**
   * max date
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  max: Date | null = null;

  private get _maxDateDT(): DateTime | undefined {
    return this.max ? DateTime.fromJSDate(this.max) : undefined;
  }

  // TODO: remove in version 2.0
  /**
   * (Deprecated) maxDate use max instead
   *
   * @returns Date max date
   *
   * @deprecated use max instead
   */
  @property({ reflect: true, type: String, attribute: 'max-date', converter: isoDateConverter })
  get maxDate(): Date | null {
    return this.max;
  }

  set maxDate(value: Date | null) {
    console.warn('Deprecated property maxDate used. Use max instead.');

    this.max = value;
  }

  /**
   * min date
   */
  @property({ reflect: true, type: String, converter: isoDateConverter })
  min: Date | null = null;

  private get _minDateDT(): DateTime | undefined {
    return this.min ? DateTime.fromJSDate(this.min) : undefined;
  }

  // TODO: remove in version 2.0
  /**
   * (Deprecated) minDate use min instead
   *
   * @returns Date min date
   *
   * @deprecated use min instead
   */
  @property({ reflect: true, type: String, attribute: 'min-date', converter: isoDateConverter })
  get minDate(): Date | null {
    return this.min;
  }
  set minDate(value: Date | null) {
    console.warn('Deprecated property minDate used. Use min instead.');

    this.min = value;
  }

  /**
   * selected year
   */
  @property({ reflect: true, type: String, attribute: 'selected-year', converter: isoDateConverter })
  selectedYear: Date | null = null;

  private get _selectedYearDT(): DateTime | undefined {
    return this.selectedYear ? DateTime.fromJSDate(this.selectedYear) : undefined;
  }

  /**
   * current decade
   *
   * @returns currentDecade
   */
  @property({ reflect: true, type: String, attribute: 'current-decade', converter: isoDateConverter })
  get currentDecade(): Date {
    if (!this._internalCurrentDecade) {
      const dateTimeNow = DateTime.now();

      return this._selectedYearDT
        ? this._selectedYearDT
            .minus({ years: this._selectedYearDT.year % 10 })
            .startOf('year')
            .toJSDate()
        : dateTimeNow
            .minus({ years: dateTimeNow.year % 10 })
            .startOf('year')
            .toJSDate();
    }

    return this._internalCurrentDecade;
  }

  set currentDecade(decade: Date) {
    const oldValue = this._internalCurrentDecade;
    this._internalCurrentDecade = decade;
    this.requestUpdate('currentDecade', oldValue);
  }

  private get _currentDecadeDT(): DateTime {
    return DateTime.fromJSDate(this.currentDecade);
  }

  /**
   * Emits a custom year-picker-decade-selected event when a decade is selected
   *
   * @param detail object with value
   * @param detail.value the selected decade
   *
   * @private
   */
  @event({
    eventName: 'year-picker-decade-selected',
    bubbles: true,
    composed: true,
  })
  emitYearPickerDecadeSelectedEvent(detail: { value: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('yearPickerDecadeSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('year-picker-decade-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom year-picker-next-decade-selected event when the next decade is selected
   *
   * @param detail object with startOfDecade and endOfDecade
   * @param detail.startOfDecade the start of the next selected decade
   * @param detail.endOfDecade the end of the next selected decade
   *
   * @private
   */
  @event({
    eventName: 'year-picker-next-decade-selected',
    bubbles: true,
    composed: true,
  })
  emitYearPickerNextDecadeSelectedEvent(detail: { startOfDecade: Date; endOfDecade: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('yearPickerNextDecadeSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('year-picker-next-decade-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom year-picker-previous-decade-selected event when the previous decade is selected
   *
   * @param detail object with startOfDecade and endOfDecade
   * @param detail.startOfDecade the start of the previous selected decade
   * @param detail.endOfDecade the end of the previous selected decade
   *
   * @private
   */
  @event({
    eventName: 'year-picker-previous-decade-selected',
    bubbles: true,
    composed: true,
  })
  emitYearPickerPreviousDecadeSelectedEvent(detail: { startOfDecade: Date; endOfDecade: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('yearPickerPreviousDecadeSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('year-picker-previous-decade-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  /**
   * Emits a custom year-picker-year-selected event when a year is selected
   *
   * @param detail object with value
   * @param detail.value the selected year
   *
   * @private
   */
  @event({
    eventName: 'year-picker-year-selected',
    bubbles: true,
    composed: true,
  })
  emitYearPickerYearSelectedEvent(detail: { value: Date }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('yearPickerYearSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('year-picker-year-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  @queryAll('zui-picker-grid-cell')
  private _pickerGridCells: GridCell[];

  private _internalCurrentDecade: Date;

  private get _gridCellYears(): DateTime[] {
    return getCalendarYearsForSelectedDecade(this._currentDecadeDT);
  }

  private _headerValue(): string {
    const years = this._gridCellYears;

    return `${years[0].year} - ${years[9].year}`;
  }

  private get _disabledGridCellConditions(): ((date: DateTime) => boolean)[] {
    const disabledYears = getDateTimesFromJsDates(this.disabledYears);

    return [
      (date): boolean => (this._minDateDT ? date.toMillis() < this._minDateDT.toMillis() : false),
      (date): boolean => (this._maxDateDT ? date.toMillis() > this._maxDateDT.toMillis() : false),
      (date): boolean => someIsSameYear(date, disabledYears),
    ];
  }

  private get _gridCellFocusConditions(): (() => boolean)[] {
    return [(): boolean => (this._selectedYearDT ? isInDecade(this._selectedYearDT, this._currentDecadeDT) : false)];
  }

  private get _gridCellSelectedConditions(): ((date: DateTime) => boolean)[] {
    return [(date): boolean => (this._selectedYearDT ? isSameYear(date, this._selectedYearDT) : false)];
  }

  private get _gridCellSubtleConditions(): ((date: DateTime) => boolean)[] {
    return [(date): boolean => !isInDecade(date, this._currentDecadeDT)];
  }

  private _isGridCellDisabled(date: DateTime): boolean {
    return this._disabledGridCellConditions.some((predicate) => predicate(date));
  }

  private _canFocusGridCell(): boolean {
    return this._gridCellFocusConditions.every((predicate) => predicate());
  }

  private _getGridCellEmphasis(date: DateTime): Emphasis {
    const isSelected = this._gridCellSelectedConditions.every((predicate) => predicate(date));
    const isSubtle = this._gridCellSubtleConditions.every((predicate) => predicate(date));

    return isSelected ? 'selected' : isSubtle ? 'subtle' : 'default';
  }

  private _handleYearSelected({ detail }: CustomEvent<{ selected: GridCell; value: string }>): void {
    const { selected, value } = detail;

    if (selected.disabled) {
      return;
    }

    this.selectedYear = DateTime.fromISO(value).startOf('year').toJSDate();

    this.emitYearPickerYearSelectedEvent({ value: this.selectedYear });
  }

  private _handleDecadeSelected(): void {
    this.emitYearPickerDecadeSelectedEvent({ value: this.currentDecade });
  }

  private _handleNextDecadeSelected(): void {
    this.currentDecade = this._currentDecadeDT.plus({ years: 10 }).toJSDate();

    this.emitYearPickerNextDecadeSelectedEvent({
      startOfDecade: this.currentDecade,
      endOfDecade: this._currentDecadeDT.plus({ years: 9 }).toJSDate(),
    });
  }

  private _handlePreviousDecadeSelected(): void {
    this.currentDecade = this._currentDecadeDT.minus({ years: 10 }).toJSDate();

    this.emitYearPickerPreviousDecadeSelectedEvent({
      startOfDecade: this.currentDecade,
      endOfDecade: this._currentDecadeDT.plus({ years: 9 }).toJSDate(),
    });
  }

  private _updateFocus(): void {
    if (!this._canFocusGridCell()) {
      return;
    }

    // TODO: this needs to be reworked into sth. more readable...
    const pickerGridCell = Array.from(this._pickerGridCells).find((pickerGridCell) =>
      this._selectedYearDT ? isSameYear(DateTime.fromISO(pickerGridCell.value), this._selectedYearDT) : false
    );

    pickerGridCell?.focus();
  }

  protected updated(changedProperties: PropertyValues): void {
    if (changedProperties.has('selectedYear')) {
      this._updateFocus();
    }
  }

  protected render(): TemplateResult {
    return html`
      <zui-picker-header
        ?disabled="${this.decadeSelectDisabled}"
        value="${this._headerValue()}"
        @picker-header-current-selected="${this._handleDecadeSelected}"
        @picker-header-next-selected="${this._handleNextDecadeSelected}"
        @picker-header-previous-selected="${this._handlePreviousDecadeSelected}"
      >
        <zui-interactive-icon slot="icon-left">
          <zui-icon-arrow-outline-arrow-outline-actually-centred-left></zui-icon-arrow-outline-arrow-outline-actually-centred-left>
        </zui-interactive-icon>
        <zui-interactive-icon slot="icon-right">
          <zui-icon-arrow-outline-arrow-outline-actually-centred-right></zui-icon-arrow-outline-arrow-outline-actually-centred-right>
        </zui-interactive-icon>
      </zui-picker-header>

      <zui-picker-grid class="picker-grid" columns="4" @picker-grid-cell-selected="${this._handleYearSelected}">
        ${this._gridCellYears.map((dateTime) => {
          const disabled = this._isGridCellDisabled(dateTime);
          const emphasis = this._getGridCellEmphasis(dateTime);

          return html`
            <zui-picker-grid-cell
              ?disabled="${disabled}"
              emphasis="${emphasis}"
              slot="pickerGridCells"
              style="--zui-picker-grid-cell-width: 56px; --zui-picker-grid-cell-height: 56px"
              value="${dateTime}"
            >
              ${dateTime.year}
            </zui-picker-grid-cell>
          `;
        })}
      </zui-picker-grid>
    `;
  }
}
