import type { IconBaseElement } from '@zeiss/zui-icons-legacy';
import { css, customElement, html, property, queryAssignedNodes, TemplateResult, unsafeCSS } from 'lit-element';
import { BaseElement } from '../../base/BaseElement';

import { hostStyles } from '../../../host.styles';
import style from './select-button.component.scss';

type Size = 's' | 'l';

const SELECT_BUTTON_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * The `zui-select-button` is a pure UI component with limited functionality. The state is given from the outside completely.
 * It's usually not used directly, but consumed from a surrounding feature component `zui-select`.
 * Only the alignment of the slotted icon is a bit more advanced behavior allowing the button to constrain the icon size to
 * `medium` if being `small`, or `large` by default. The default size, if not provided, is derived by the `hasTouch`
 * implementation of the parent `BaseElement`.
 * An optional label can be passed through the default slot. It will be cut off if too long using three d...
 *
 * @example
 * ```html
 * <zui-select-button>
 *   Select button label
 *   <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 * </zui-select-button>
 * ```
 *
 * @slot - default slot for an optional button label
 * @slot icon - optional slot to pass-in an icon whose size is automatically aligned
 * @cssprop --zui-select-button-arrow-color - arrow icon color, derived from interaction, disabled and toggle state
 * @cssprop --zui-select-button-arrow-height - vertical size of the arrow icon
 * @cssprop --zui-select-button-arrow-width - horizontal size of the arrow icon
 * @cssprop --zui-select-button-border-width - stroke width of the button border
 * @cssprop --zui-select-button-font - sets the button font family
 * @cssprop --zui-select-button-icon-spacing - padding between icons and the label
 */
@customElement('zui-select-button')
export class SelectButton extends BaseElement {
  static readonly styles = [hostStyles, SELECT_BUTTON_STYLES];

  /**
   * enforces the "role" attribute for a11y reasons
   */
  @property({ reflect: true, type: String })
  role = 'button';

  /**
   * ARIA activedescendant for this element; defaults to ''
   */
  @property({ reflect: true, attribute: 'aria-activedescendant' })
  activeDescendant = '';

  /**
   * toggles the alternative layout
   */
  @property({ reflect: true, type: Boolean })
  alternative = false;

  /**
   * toggles the open state when associated with a menu
   */
  @property({ reflect: true, type: Boolean })
  opened = false;

  /**
   * readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * marks as selected instead of default watermark appearance
   */
  @property({ reflect: true, type: Boolean })
  selected = false;

  /**
   * toggles the size explicitly, depends from touch environment initially by default if not set
   */
  @property({ reflect: true, type: String })
  size: Size = this.hasTouch ? 'l' : 's';

  /**
   * disables the "button"
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  @queryAssignedNodes('icon', false, '[slot="icon"]')
  private readonly _iconRefs: NodeListOf<IconBaseElement>;

  connectedCallback(): void {
    super.connectedCallback();
    this.addEventListener('click', this._handleClick);
  }

  disconnectedCallback(): void {
    this.removeEventListener('click', this._handleClick);
    super.disconnectedCallback();
  }

  private _handleClick(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
  }

  private _alignIcons(): void {
    this._iconRefs.forEach((iconRef) => {
      // derive icon size from item size
      iconRef.size = this.size === 's' ? 'm' : 'l';
    });
  }

  protected render(): TemplateResult {
    return html`
      <slot name="icon" @slotchange="${this._alignIcons}"></slot>
      <span class="label"><slot></slot></span>
    `;
  }
}
