import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  query,
  TemplateResult,
  unsafeCSS,
  state,
} from 'lit-element';
import { isDefined, numberUndefinedConverter, stringUndefinedConverter } from '../../utils/component.utils';

import { RealBaseElement } from '../base/BaseElement';
import { punycode } from '../../utils/punycode.utils';
import { event } from '../../decorators/event.decorator';
import { EventWithTarget } from '../../types';

import { FormDataHandlingMixin } from '../../mixins/form-participation/form-data-handling.mixin';
import {
  FormEnabledElement,
  FormValidationElement,
  ValidationResult,
} from '../../mixins/form-participation/form-participation.types';
import { FormValidationMixin } from '../../mixins/form-participation/form-validation.mixin';

import '../error-message/error-message.component';
import { nothing } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';

import { hostStyles } from '../../host.styles';
import style from './text-field.component.scss';
import { classMap } from 'lit-html/directives/class-map';
import { queryAssignedNodes } from 'lit-element/lib/decorators';

import type { InteractiveIcon } from '../interactive-icon/interactive-icon.component';

const textFieldStyles = css`
  ${unsafeCSS(style)}
`;

type FormattingCallback = (value: string) => string;
type InputType = 'text' | 'email' | 'number';

/**
 * The TextField is a text input with a bottom line which changes color depending on interaction states.
 * It can be set to readonly which hides the bottom line and prevents users from changing the text.
 *
 * ## Validation
 *
 * It is possible to add a validation function to the validationCallback.
 * The validation function gets the current value as parameter and returns a ValidationResult.
 * A ValidationResult has a boolean isValid flag which is false when the validation was negative and an optional
 * message which replaces the validation-message in the error warning.
 *
 * ## Formatting
 *
 * It is possible to add a formatting function to the formattingCallback.
 * The formatting function gets the current value as parameter and returns a formatted string.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=13009%3A2730)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=15127%3A499061)
 *
 * @example
 * Input type = text:
 *
 * ```html
 * <zui-textfield name="firstname" value="Luna" input-type="text"><zui-interactive-icon emphasis="subtle"
 *   slot=interactive-icon><zui-icon-holy-placeholder
 *   size="m"></zui-icon-holy-placeholder></zui-interactive-icon></zui-textfield>
 * ```
 *
 * Input type = email:
 *
 * ```html
 * <zui-textfield name="email" placeholder="Email" input-type="email"></zui-textfield>
 * ```
 *
 * Input type = number:
 *
 * ```html
 * <zui-textfield name="number" value="0" min="-10" max="10" step="2" input-type="number"
 *   align-rigth="true"></zui-textfield>
 * ```
 *
 *
 * Formatting callback function example:
 *
 * ```js
 * const numberFormatting = (value: string): string => (value.replace(/(.)(?=(\d{3})+$)/g, '$1.'));
 *
 * const textField = document.querySelector('zui-textfield')
 * textField.formattingCallback = numberFormatting
 * ```
 *
 * @fires change - The change event is fired when the value of the textfield has changed, similar to `<input
 *   type="text">`
 * @fires input - The input event is fired when the value of the textfield has changed, similar to `<input
 *   type="text">`
 * @fires blur - The blur event is fired when the component or arrows are leaved, similar to `<input
 *   type="text">`
 * @slot interactive-icon - This is the slot for the interactive icon.
 */
@customElement('zui-textfield')
export class TextField
  extends FormValidationMixin(FormDataHandlingMixin(RealBaseElement, { disableSubmitOnEnter: true }))
  implements FormValidationElement<FormEnabledElement> {
  /* eslint-disable @typescript-eslint/naming-convention */
  static readonly REQUIRED_ERROR_MESSAGE = 'This field is required.';
  static readonly TOO_SMALL_ERROR_MESSAGE = 'The number is too small.';
  static readonly TOO_LARGE_ERROR_MESSAGE = 'The number is too large.';
  static readonly TOO_SHORT_ERROR_MESSAGE = 'The input is too short.';
  static readonly TOO_LONG_ERROR_MESSAGE = 'The input is too long.';
  static readonly PATTERN_ERROR_MESSAGE = 'The input does not match the required pattern.';
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * The pattern attribute specifies a regular expression the form control's value should match.
   */
  @property({ reflect: true, converter: stringUndefinedConverter })
  pattern: string | undefined;

  /**
   * Defines if input is required
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * Defines min length for input
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  minlength: number | undefined;

  /**
   * Defines max length for input
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  maxlength: number | undefined;

  /**
   * input type of the text field
   */
  @property({ reflect: false, attribute: 'input-type' })
  inputType: InputType = 'text';

  /**
   * The min value of the TextField when input type = number
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  min: number | undefined;

  /**
   * The max value of the TextField when input type = number
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  max: number | undefined;

  /**
   * The steps of the TextField when input type = number
   */
  @property({ reflect: true, type: Number })
  step = 1;

  /**
   * The unit of the TextField
   */
  @property({ reflect: true, type: String })
  unit = '';

  /**
   * AlignRight sets the alignment of the text in the input to 'right'
   */
  @property({ type: Boolean, reflect: true, attribute: 'align-right' })
  alignRight = false;

  /**
   * Sets the placeholder text for the input
   */
  @property({ type: String, reflect: true })
  placeholder = '';

  /**
   * Value for the input, is always a string, even when it is of type="number"
   */
  @property({ reflect: true, converter: stringUndefinedConverter })
  value: string | undefined;

  /**
   * The formatting callback of the TextField, it allows to set a function which formats the text
   *
   * @returns {FormattingCallback} the formatting callback function
   */
  @property({ reflect: false, attribute: false })
  formattingCallback: FormattingCallback | undefined;

  /**
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new InputEvent('input', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'change',
    // use values from change event of HTML <input type=text>.
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new InputEvent('change', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'blur', // use values from blur event of HTML <input type=text>.
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitBlurEvent(): void {
    this.dispatchEvent(
      new FocusEvent('blur', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  private get _isTextInput(): boolean {
    return this.inputType === 'text' || this.inputType === 'email';
  }

  private get _formattedValue(): string | undefined {
    if (typeof this.formattingCallback === 'function') {
      return isDefined(this.value) ? this.formattingCallback(punycode.toUnicode(this.value)) : undefined;
    } else {
      return isDefined(this.value) ? punycode.toUnicode(this.value) : undefined;
    }
  }

  // this is the flag responsible for displaying the validator message under textfield
  @state()
  private _hasBeenBlurred = false;

  @query('#raw')
  private readonly _inputElement: HTMLInputElement;

  @queryAssignedNodes('interactive-icon', true, 'zui-interactive-icon')
  private _assignedInteractiveIcons: InteractiveIcon[];

  private _isArrowAsLastClicked = false;

  constructor() {
    super();
    this.addValidator({
      type: 'valueMissing',
      validator: () => this.required === false || this._inputElement.validity.valueMissing !== true,
      validatesOnProperties: ['required'],
    });

    this.addValidator({
      type: 'tooShort',
      validator: () => (this.value && this.minlength && this._isTextInput ? this.value.length >= this.minlength : true),
      validatesOnProperties: ['minlength'],
    });

    this.addValidator({
      type: 'tooLong',
      validator: () => (this.value && this.maxlength && this._isTextInput ? this.value.length <= this.maxlength : true),
      validatesOnProperties: ['maxlength'],
    });

    this.addValidator({
      type: 'rangeUnderflow',
      validator: () =>
        this.min && this.value && this.inputType === 'number' ? parseFloat(this.value) >= this.min : true,
      validatesOnProperties: ['min'],
    });

    this.addValidator({
      type: 'rangeOverflow',
      validator: () =>
        this.max && this.value && this.inputType === 'number' ? parseFloat(this.value) <= this.max : true,
      validatesOnProperties: ['max'],
    });

    this.addValidator({
      type: 'patternMismatch',
      validator: () => this.pattern === undefined || this._inputElement.validity.patternMismatch !== true,
      validatesOnProperties: ['pattern'],
    });

    this.setDefaultValidityMessages({ valueMissing: TextField.REQUIRED_ERROR_MESSAGE });
    this.setDefaultValidityMessages({ tooShort: TextField.TOO_SHORT_ERROR_MESSAGE });
    this.setDefaultValidityMessages({ tooLong: TextField.TOO_LONG_ERROR_MESSAGE });
    this.setDefaultValidityMessages({ rangeUnderflow: TextField.TOO_SMALL_ERROR_MESSAGE });
    this.setDefaultValidityMessages({ rangeOverflow: TextField.TOO_LARGE_ERROR_MESSAGE });
    this.setDefaultValidityMessages({ patternMismatch: TextField.PATTERN_ERROR_MESSAGE });
  }

  get validationState(): ValidationResult {
    console.warn('The usage of validationState is deprecated and will be removed, use validity instead.');
    // merge explicitly set validationMessage with passed validationCallback
    // if neither has set, filter out message:undefined
    return {
      ...(this.getValidityMessages()['customError'] ? { message: this.getValidityMessages()['customError'] } : {}),
      ...this.validationCallback(this.value),
    };
  }

  /**
   * Defines the styles of the base type
   *
   * @returns {CSSResultArray} stores component styles
   */
  static get styles(): CSSResultArray {
    return [hostStyles, textFieldStyles];
  }

  connectedCallback(): void {
    super.connectedCallback();

    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.addEventListener('click', this._handleOutsideClick);
  }

  disconnectedCallback(): void {
    // todo: this should be removed when a reusable solution has been implemented
    // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
    window.removeEventListener('click', this._handleOutsideClick);

    super.disconnectedCallback();
  }

  /**
   * Handle when up control gets clicked
   */
  stepUp(): void {
    this._inputElement.stepUp();
    this._alignValue();
    this._handleInput();
    this.emitInputEvent();
    this._isArrowAsLastClicked = true;
  }

  /**
   * Handle when down control gets clicked
   */
  stepDown(): void {
    this._inputElement.stepDown();
    this._alignValue();
    this._handleInput();
    this.emitInputEvent();
    this._isArrowAsLastClicked = true;
  }

  /**
   * Sets and removes the disabled attribute and the size "on icon
   */
  private _propagateIconSlotProps(): void {
    this._assignedInteractiveIcons.forEach((interactiveIcon) => {
      interactiveIcon.querySelector('[zuiIcon]')?.setAttribute('size', 'm');
      interactiveIcon.disabled = this.disabled || this.readonly;
      interactiveIcon.smallHitArea = true;
    });
  }

  private _handleIconSlotChange(): void {
    this._propagateIconSlotProps();
  }

  private _clampingNumberValue(): void {
    if (typeof this.max === 'number' && Number.parseFloat(this._inputElement.value) > this.max) {
      this._inputElement.value = this.max.toString();
    }
    if (typeof this.min === 'number' && Number.parseFloat(this._inputElement.value) < this.min) {
      this._inputElement.value = this.min.toString();
    }
  }

  /**
   * Updates the value of the text field and the formatted input
   */
  private _alignValue(): void {
    this._clampingNumberValue();
  }

  /**
   * React when the user starts typing. This is used to hide the validation message (if any is shown)
   * as soon as the user starts typing to correct the wrong value.
   * We use "input" event instead of "keydown" to prevent hiding the message when a
   * special key (CTRL, Arrow, Alt,...) is pressed.
   */
  private _handleInput(): void {
    if (this.inputType === 'number' && isNaN(this._inputElement.valueAsNumber)) {
      this.value = '';

      return;
    }

    this.value = this._inputElement.value;
    this._hasBeenBlurred = false;
  }

  /**
   * Trigger validation on blur.
   */
  private _handleBlur(): void {
    this._alignValue();
    this._hasBeenBlurred = true;
  }

  /**
   * Emit a change event when the value of the internal textfield has changed.
   * As change-events has composed=false, we need to emit a new one.
   */
  private _handleChange(): void {
    this.emitChangeEvent();
  }

  // TODO: this should be removed when a reusable solution has been implemented
  // https://dev.azure.com/ZEISSgroup/DI_ZUi-Web/_workitems/edit/500595
  private _handleOutsideClick = (event: EventWithTarget<TextField>): void => {
    const isInsideClick =
      this.isSameNode(event.target) && event.composedPath().some((path) => path instanceof TextField);

    if (!isInsideClick && this._isArrowAsLastClicked) {
      this._isArrowAsLastClicked = false;

      this.emitBlurEvent();
    }
  };

  private get _showValidationWarning(): boolean {
    return this.willValidate && this.invalid && this._hasBeenBlurred;
  }

  /**
   * Privated getter for the disabled state of the up arrow
   *
   * @returns {boolean} arrow up disabled state
   */
  private get _isUpArrowDisabled(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because we can parse undefined...
    return this.readonly || this.disabled || Number.parseFloat(this.value) >= this.max;
  }

  /**
   * Privated getter for the disabled state of the down arrow
   *
   * @returns {boolean} arrow down disabled state
   */
  private get _isDownArrowDisabled(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore because we can parse undefined...
    return this.readonly || this.disabled || Number.parseFloat(this.value) <= this.min;
  }

  protected render(): TemplateResult {
    return html`
      <div id="container" class="${classMap({ touch: this.hasTouch, invalid: this._showValidationWarning })}">
        <div id="input-container">
          <div id="input-wrapper">
            <input
              id="raw"
              zuiFormControl
              min="${ifDefined(this.min)}"
              max="${ifDefined(this.max)}"
              step="${this.step}"
              type="${this.inputType}"
              ?readonly="${this.readonly}"
              ?disabled="${this.disabled}"
              placeholder="${this.placeholder}"
              .value="${ifDefined(this.value)}"
              @blur="${this._handleBlur}"
              @input="${this._handleInput}"
              @change="${this._handleChange}"
              ?required="${this.required}"
              minlength="${ifDefined(this.minlength)}"
              maxlength="${ifDefined(this.maxlength)}"
              pattern="${ifDefined(this.pattern)}"
              title=""
            />
            <input
              id="formatted"
              tabindex="-1"
              type="text"
              ?readonly="${this.readonly}"
              ?disabled="${this.disabled}"
              placeholder="${this.placeholder}"
              .value="${ifDefined(this._formattedValue)}"
            />
          </div>
          ${this.unit ? html`<span id="unit">${this.unit}</span>` : nothing}
          <slot id="iconslot" name="interactive-icon" @slotchange="${this._handleIconSlotChange}"></slot>
          ${this.inputType === 'number' && !this.hasTouch
            ? html` <div id="arrow-container">
                <zui-interactive-icon
                  ?disabled=${this._isUpArrowDisabled}
                  emphasis="default"
                  tabindex="-1"
                  small-hit-area
                  @click=${this.stepUp}
                >
                  <zui-icon-arrow-filled-arrow-filled-up size="xs"></zui-icon-arrow-filled-arrow-filled-up>
                </zui-interactive-icon>
                <zui-interactive-icon
                  ?disabled=${this._isDownArrowDisabled}
                  emphasis="default"
                  tabindex="-1"
                  small-hit-area
                  @click=${this.stepDown}
                >
                  <zui-icon-arrow-filled-arrow-filled-down size="xs"></zui-icon-arrow-filled-arrow-filled-down>
                </zui-interactive-icon>
              </div>`
            : nothing}
        </div>
        ${this._showValidationWarning
          ? html` <zui-error-message id="error">${this.validationMessage}</zui-error-message>`
          : nothing}
      </div>
    `;
  }
}
