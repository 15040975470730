import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  query,
  state,
  css,
  unsafeCSS,
} from 'lit-element';
import { ifDefined } from 'lit-html/directives/if-defined';
import { classMap } from 'lit-html/directives/class-map';
import { BaseElement } from '../base/BaseElement';
import { event } from '../../decorators/event.decorator';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { serializeSlotContent } from '../../utils/component.utils';
import { InlineMessage } from '../inline-message/inline-message.component';
import { IconBar } from '../icon-bar/icon-bar.component';

import { hostStyles } from '../../host.styles';
import style from './toast-notification.component.scss';

const toastNotificationStyles = css`
  ${unsafeCSS(style)}
`;

const INLINE_MESSAGE_MAX_HEIGHT = 36;

/**
 * @typedef {('default' | 'active')} State
 */
type State = 'default' | 'active';

/**
 * Notifications are used to communicate conditions, indicate an event, or to show responses to user actions.
 * They are informative and require minimal user interaction.
 * Content of notifications can include authentication, information, confirmation, warnings, and error messages.
 * There is also the possibility to add additional content.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=71661%3A353359)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=24714%3A126338)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-toast-notification header-text="Title" state="default" closable>
 * 	<span>Some Text</span>
 * 	<zui-icon-info slot="icon"></zui-icon-info>
 * 	<zui-icon-holy-placeholder slot="icon1"></zui-icon-holy-placeholder>
 * 	<zui-icon-holy-placeholder slot="icon2"></zui-icon-holy-placeholder>
 * 	<zui-icon-holy-placeholder slot="icon3"></zui-icon-holy-placeholder>
 * </zui-toast-notification>
 * ```
 *
 * @fires close - event which gets fired when the toast notification is closed
 * @slot default - It's an innerHtml of the toast notification-element
 * @slot headerText - Here you can insert the header text, it will overwrite the headerText property.
 * @slot icon - icon slot for the inline message icon.
 * @slot icon1 - first icon slot in the header.
 * @slot icon2 - second icon slot in the header.
 * @slot icon3 - third icon slot in the header.
 * @slot content - additional content slot.
 * @slot primary-button - slot for the primary button, the button segment gets shown when both button slots are filled.
 * @slot secondary-button - slot for the secondary button, the button segment gets shown when both button slots are filled.
 * @cssprop --zui-toast-notification-width - sets the overall horizontal size of the toast notification
 */
@customElement('zui-toast-notification')
export class ToastNotification extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, toastNotificationStyles];
  }

  /**
   * The header text of the toast notification
   */
  @property({ reflect: true, attribute: 'header-text' })
  headerText: string;

  /**
   * The text of the toast notification
   */
  @property({ reflect: true })
  text: string;

  /**
   * Defines one of two possible states (default/active)
   */
  @property({ reflect: true })
  state: State = 'default';

  /**
   * If closable is enabled an additional button will be visible that allows the user to close the toast notification.
   */
  @property({ reflect: true, type: Boolean })
  closable = false;

  /**
   * Close event is emitted by iconbar and bubbles through the toast notification.
   * This method is here to signal the existence of this event via the event decorator
   *
   * @private
   */
  @event({
    eventName: 'close',
    bubbles: true,
    composed: true,
    cancelable: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  emitCloseEvent(): void {}

  @query('slot[name="headerText"]')
  private _headerTextSlot: HTMLSlotElement;

  @query('slot[name="icon"]')
  private _iconSlot: HTMLSlotElement;

  @query('slot[name="content"]')
  private _contentSlot: HTMLSlotElement;

  @query('slot:not([name])')
  private _defaultSlot: HTMLSlotElement;

  @query('slot[name="primary-button"]')
  private _primaryButtonSlot: HTMLSlotElement;

  @query('slot[name="secondary-button"]')
  private _secondaryButtonSlot: HTMLSlotElement;

  @query('zui-icon-bar')
  private _iconBar: IconBar;

  @query('zui-inline-message')
  private _inlineMessage: InlineMessage;

  // TODO: use nested-slots
  @state()
  private _headerTextSlotContent: string | undefined;

  @state()
  private _iconSlotContent: string | undefined;

  @state()
  private _defaultSlotContent: string | undefined;

  @state()
  private _showButtons = false;

  @state()
  private _showAdditionalContent = false;

  private _heightObserver: ResizeObserver = new ResizeObserver(([{ contentRect }]) => {
    requestAnimationFrame(() => {
      const inlineMessageHeight = contentRect.height;
      if (inlineMessageHeight <= INLINE_MESSAGE_MAX_HEIGHT) {
        this._iconBar.classList.add('icons-centered');
      } else {
        this._iconBar.classList.remove('icons-centered');
      }
    });
  });

  disconnectedCallback(): void {
    this._heightObserver.disconnect();
    super.disconnectedCallback();
  }

  /**
   * When the headerText slot has changed, we serialize its content into an
   * internal property that is used for conditional markup
   */
  private _onHeaderTextSlotChanged(): void {
    this._headerTextSlotContent = serializeSlotContent(this._headerTextSlot);
  }

  /**
   * When the default slot has changed, we serialize its content into an
   * internal property that is used for conditional markup
   */
  private _onDefaultSlotChanged(): void {
    this._defaultSlotContent = serializeSlotContent(this._defaultSlot);
  }

  /**
   * When the headerText slot has changed, we serialize its content into an
   * internal property that is used for conditional markup
   */
  private _onIconSlotChanged(): void {
    this._iconSlotContent = serializeSlotContent(this._iconSlot);
  }

  /**
   * Sets an internal class when both button slots are used to show the buttons segment
   */
  private _onButtonSlotChanged(): void {
    this._showButtons =
      this._primaryButtonSlot.assignedNodes().length > 0 && this._secondaryButtonSlot.assignedNodes().length > 0;
  }

  /**
   * Sets an internal class when there something slotted into the content slot
   */
  private _onContentSlotChange(): void {
    this._showAdditionalContent = this._contentSlot.assignedNodes().length > 0;
  }

  protected firstUpdated(): void {
    this._heightObserver.observe(this._inlineMessage);
  }

  protected render(): TemplateResult | void {
    // checks if the active class is set so that the icons can change their state accordingly
    const emphasisStyleClass = this.state === 'active' ? 'highlight' : 'subtle';
    return html`
      <div id="buttons-and-toast-container">
        <div
          id="outer-toast-container"
          class="${classMap({
            'show-additional-content': this._showAdditionalContent,
            'show-buttons': this._showButtons,
          })}"
        >
          <div id="inner-toast-container">
            <slot class="trap" @slotchange="${this._onDefaultSlotChanged}"></slot>
            <slot class="trap" name="icon" @slotchange="${this._onIconSlotChanged}"></slot>
            <slot class="trap" name="headerText" @slotchange="${this._onHeaderTextSlotChanged}"></slot>
            <zui-inline-message id="inline-message" header-text="${ifDefined(this.headerText)}">
              ${this._defaultSlotContent ? unsafeHTML(this._defaultSlotContent) : ''}
              ${this._headerTextSlotContent ? html`${unsafeHTML(this._headerTextSlotContent)}` : ''}
              ${this._iconSlotContent ? html`${unsafeHTML(this._iconSlotContent)}` : ''}
            </zui-inline-message>
            <zui-icon-bar id="iconbar" emphasis="${emphasisStyleClass}" ?closable=${this.closable}>
              <slot name="icon1" slot="icon1"></slot>
              <slot name="icon2" slot="icon2"></slot>
              <slot name="icon3" slot="icon3"></slot>
            </zui-icon-bar>
            <zui-progress-ring id="progress-ring" emphasis="highlight" size="m" mode="activity"></zui-progress-ring>
          </div>
          <div class="content">
            <slot name="content" @slotchange="${this._onContentSlotChange}"> </slot>
          </div>
        </div>

        <div id="toast-buttons">
          <slot name="primary-button" @slotchange="${this._onButtonSlotChanged}"></slot>
          <div id="seperator"></div>
          <slot name="secondary-button" @slotchange="${this._onButtonSlotChanged}"></slot>
        </div>
      </div>
    `;
  }
}
