import type { Portal } from '../components/portal/portal.component';
import { isDefined } from './component.utils';

const PORTAL_DEFAULT_NAME = 'default';

/**
 * Allows setting a new portal instance to the global scope.
 *
 * @param name - the name of the portal
 * @param portal - a reference to a portal instance
 */
export const registerPortal = (name = PORTAL_DEFAULT_NAME, portal: Portal): void => {
  // add (replace) the portal in registry
  window.zui?.portals?.set(name, portal);
};

/**
 * Removes registered portals from the global scope.
 *
 * @param name - the name of the portal
 */
export const unregisterPortal = (name = PORTAL_DEFAULT_NAME): void => {
  getPortal(name)?.remove();
  window.zui?.portals?.delete(name);
};

/**
 * Checks whether a portal of the given name exists.
 *
 * @param name - the name of the portal
 * @returns boolean - portal name is known or not
 */
export const portalExists = (name = PORTAL_DEFAULT_NAME): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return isDefined(window.zui?.portals) ? window.zui!.portals!.has(name) : false;
};

/**
 * Delivers a registered portal.
 *
 * @param name - the name of the portal
 * @returns portal - a portal element instance if found
 */
export const getPortal = (name = PORTAL_DEFAULT_NAME): Portal | undefined => {
  // nothing found
  if (!portalExists(name)) {
    return undefined;
  }

  // TODO: this looks like a race condition
  // deliver already registered portal
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return window.zui!.portals!.get(name);
};

/**
 * Creates, registers and returns a new portal instance.
 * This would not create existing portals, but return those references instead.
 *
 * @param name - the name of the portal
 * @param level - an optional depth level
 * @returns portal - a promise resolving the portal element instance
 */
export const preparePortal = async (name = PORTAL_DEFAULT_NAME, level?: number): Promise<Portal> => {
  // add the namespace and the map if missing
  if (!window.zui) {
    window.zui = {} as never;
  }
  if (!window.zui.portals) {
    window.zui.portals = new Map();
  }

  // return references of existing one
  if (portalExists(name)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return getPortal(name)!;
  }

  // deliver the created portal once rendered
  return new Promise((resolve) => {
    // create a new portal and wait for emit
    const portal = window.document.createElement('zui-portal') as Portal;
    portal.name = name;
    portal.level = level;
    portal.addEventListener('portal-ready', () => resolve(portal), { once: true, passive: true });

    // add to the body element
    window.document.body.appendChild(portal);
  });
};

/**
 * Delivers the currently projected contents from a portal.
 *
 * @param portal - name of the portal
 * @param selector - optional selector to narrow result
 * @returns content - the projected content
 */
export const getContentsFromPortal = <R extends Element>(portal: string, selector?: string): R[] => {
  let results: NodeListOf<R> | HTMLCollectionOf<R> | undefined;
  // use the given selector to narrow down result
  if (selector) {
    results = getPortal(portal)?.shadowRoot?.querySelectorAll<R>(selector);
  } else {
    results = getPortal(portal)?.shadowRoot?.children as HTMLCollectionOf<R>;
  }

  // deliver the first element if found
  return Array.from(results ?? []);
};

/**
 * Generates a simple unique id to be used e.g. as portal name.
 *
 * @returns uuid - the generated unique identifier
 */
export const generateUid = (): string => Math.random().toString(36).slice(-6);

/**
 * Recursively finds the first slotted item even if nested through multiple slots.
 *
 * @param slot - the slot to search within
 * @returns element that has been found if any
 */
export const unwrapFirstSlottedElement = (slot: HTMLSlotElement): Element | undefined => {
  const [element] = slot.assignedElements();
  // unwrap slot contents if necessary
  if (element instanceof HTMLSlotElement) {
    return unwrapFirstSlottedElement(element);
  }
  return element;
};
