import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  css,
  unsafeCSS,
  property,
  query,
  PropertyValues,
  state,
} from 'lit-element';
import { EventWithTarget } from '../../../types';
import { event } from '../../../decorators/event.decorator';
import { isDefined, numberUndefinedConverter } from '../../../utils/component.utils';
import { addLeadingZeros, constrainInputValue, getNextInput, getPreviousInput } from '../utils/date-picker-input.utils';
import { BaseElement } from '../../base/BaseElement';
import { literalConverter } from '../utils/date-picker.utils';
import { classMap } from 'lit-html/directives/class-map';

import { hostStyles } from '../../../host.styles';
import styles from './date-picker-input-part.component.scss';

const datePickerInputPartStyles = css`
  ${unsafeCSS(styles)}
`;

/**
 * The date picker input part is used inside the zui-date-picker-input for displaying the day, month or year.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-date-picker-input-part
 *  input-part-type="day"
 *  literals="."
 *  max="31"
 *  min="1"
 *  value="5"
 * >
 * </zui-date-picker-input-part>
 * ```
 *
 * @fires {CustomEvent} date-picker-input-part-value-changed - emits when the input value has changed
 *
 * @cssprop --zui-date-picker-input-part-placeholder-width - override input placeholder width
 */
@customElement('zui-date-picker-input-part')
export class DatePickerInputPart extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, datePickerInputPartStyles];
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  private static readonly DATE_PICKER_INPUT_PART_MAX_DEFAULT = 9999;
  private static readonly DATE_PICKER_INPUT_PART_MIN_DEFAULT = 1;
  /* eslint-enable @typescript-eslint/naming-convention */

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * type
   */
  @property({ reflect: true, type: String, attribute: 'input-part-type' })
  inputPartType!: string;

  /**
   * literals
   */
  @property({ reflect: true, type: String, converter: literalConverter })
  literals: string[] = [];

  /**
   * max
   */
  @property({ reflect: true, type: Number })
  max = DatePickerInputPart.DATE_PICKER_INPUT_PART_MAX_DEFAULT;

  /**
   * min
   */
  @property({ reflect: true, type: Number })
  min = DatePickerInputPart.DATE_PICKER_INPUT_PART_MIN_DEFAULT;

  /**
   * placeholder
   */
  @property({ reflect: true, type: String })
  placeholder: string | undefined;

  /**
   * readonly
   */
  @property({ reflect: true, type: Boolean })
  readonly = false;

  /**
   * value
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  get value(): number | undefined {
    return this._internalValue;
  }

  set value(value: number | undefined) {
    const oldVal = this._internalValue;
    this._internalValue = value;
    this._showPlaceholder = !isDefined(value);
    this.requestUpdate('value', oldVal);
  }

  /**
   * @param detail object
   * @param detail.type string
   * @param detail.value number or null
   * @private
   */
  @event({ eventName: 'date-picker-input-part-value-changed', bubbles: true, composed: true })
  emitInputValueChangeEvent(detail: { type: string; value: number | null }): void {
    this.dispatchEvent(
      new CustomEvent('date-picker-input-part-value-changed', { bubbles: true, composed: true, detail })
    );
  }

  @query('input')
  private _inputElement: HTMLInputElement | null;

  @state()
  private _showPlaceholder = true;

  private _validLiterals = ['.', '/'];
  private _internalValue: number | undefined = undefined;
  private _userInteraction = false;

  focus(options?: FocusOptions): void {
    this._inputElement?.focus(options);
  }

  private get _maxCharacterLength(): number {
    return this.max.toString().length;
  }

  private get _paddedValue(): string | null {
    return this.value !== undefined ? addLeadingZeros(this.value, this._maxCharacterLength) : null;
  }

  private _handleInputEvent(event: EventWithTarget<HTMLInputElement, InputEvent>): void {
    // trim input length
    if (event.target.value.length > this._maxCharacterLength) {
      event.target.value = event.target.value.slice(0, this._maxCharacterLength);
    }

    // if the current value has a length of 0, then show the placeholder
    this._showPlaceholder = event.target.value.length === 0;

    // focus next; TODO: this must be orchestrated in the parent
    if (event.inputType === 'insertText' && event.target.value.length === this._maxCharacterLength) {
      getNextInput(this)?.focus();
    }
  }

  private _handleInputChangeEvent(event: EventWithTarget<HTMLInputElement, FocusEvent>): void {
    const inputValue = event.target.value;
    if (inputValue === '') {
      // part has been resetted
      this.value = undefined;
    } else {
      // constrain value
      this.value = constrainInputValue(Number(inputValue), this.min ?? 0, this.max ?? 0);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this._inputElement!.value = this._paddedValue!;
    }
    this._userInteraction = true;
  }

  private _handleBackspaceKeydownEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    if (isNaN(parseInt(event.target.value))) {
      event.preventDefault();

      getPreviousInput(this)?.focus();
    }
  }

  private _handleLiteralInputEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    event.preventDefault();

    if (!isNaN(parseInt(event.target.value))) {
      getNextInput(this)?.focus();
    }
  }

  private _handleInputKeydownEvent(event: EventWithTarget<HTMLInputElement, KeyboardEvent>): void {
    switch (event.key) {
      case '+':
      case ',':
      case '-':
      case 'e':
        event.preventDefault();
        break;
      case 'Backspace':
        this._handleBackspaceKeydownEvent(event);
        break;
      default:
        if (this.literals.includes(event.key)) {
          this._handleLiteralInputEvent(event);
        } else if (this._validLiterals.filter((key) => !this.literals.includes(key)).includes(event.key)) {
          event.preventDefault();
        }
        break;
    }
  }

  protected updated(_changedProperties: PropertyValues): void {
    super.updated(_changedProperties);

    if (_changedProperties.has('value') && this._userInteraction) {
      this.emitInputValueChangeEvent({
        type: this.inputPartType,
        value: this.value ?? null,
      });
      this._userInteraction = false;
    }
  }

  protected render(): TemplateResult {
    return html`
      <input
        .value="${this._paddedValue}"
        ?disabled="${this.disabled}"
        ?readonly="${this.readonly}"
        class="${classMap({ 'show-placeholder': this._showPlaceholder })}"
        max="${this.max}"
        min="${this.min}"
        placeholder="${this.placeholder}"
        type="number"
        @change="${this._handleInputChangeEvent}"
        @input="${this._handleInputEvent}"
        @keydown="${this._handleInputKeydownEvent}"
      />
    `;
  }
}
