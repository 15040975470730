import { CSSResultArray, TemplateResult, customElement, html, property, unsafeCSS, css, state } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { nothing } from 'lit-html';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import style from './content-tab-image-item.component.scss';
import { ContentTabItemBase } from '../content-tab-base.class';

const contentTabImageItemStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The content tab highlight item is a content tab item for any content.
 * The content tab highlight item is intended for usage in the content tab bar component.
 *
 * The height of the item is 48px.
 * The width of the item is solely be depending on width of the given content.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=52930%3A120)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---3.1?node-id=76949%3A367659)
 *
 * @example
 * HTML:
 *
 * Text only:
 * ```html
 * <zui-content-tab-image-item text="My custom text" value="textItemOne">
 * </zui-content-tab-image-item>
 * ```
 *
 * Icon only:
 * ```html
 * <zui-content-tab-image-item value="iconItemOne">
 *   <zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list>
 * </zui-content-tab-image-item>
 *  ```
 *
 * Text and icon:
 * ```html
 * <zui-content-tab-image-item text="My custom text" value="textAndIconItemOne">
 *   <zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list>
 * </zui-content-tab-image-item>
 *  ```
 *
 * @fires content-tab-item-closed - fired the close icon of a tab was clicked. Contains the value of the tab as payload.
 */
@customElement('zui-content-tab-image-item')
export class ContentTabImageItem extends ContentTabItemBase {
  static get styles(): CSSResultArray {
    return [hostStyles, contentTabImageItemStyles];
  }

  /**
   * Emit close event
   *
   * @private
   */
  @event({
    eventName: 'content-tab-item-closed',
    bubbles: true,
    composed: false,
    cancelable: false,
  })
  emitContentTabItemClosedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('content-tab-item-closed', {
        bubbles: true,
        composed: false,
        cancelable: false,
        detail: {
          value: this.value,
        },
      })
    );
  }

  /**
   * holds state for simulating the hover effect.
   * See content-tab-default-item for an detailed description.
   */
  @state()
  private _isHover = false;

  private _setHover(): void {
    this._isHover = true;
  }

  private _removeHover(): void {
    this._isHover = false;
  }

  protected render(): TemplateResult | void {
    return html`<button ?disabled=${this.disabled} class="${classMap({ hover: this._isHover })}">
        <div id="text-container">${this.text}</div>
        <div id="closable-container"></div>
      </button>
      <div id="closable-container-absolute" @mouseover=${this._setHover} @mouseleave=${this._removeHover}>
        ${this.closable
          ? html`<zui-interactive-icon @click="${this.emitContentTabItemClosedEvent}" ?disabled=${this.disabled}>
              <zui-icon-close size="xs"></zui-icon-close>
            </zui-interactive-icon>`
          : nothing}</div
      >`;
  }
}
