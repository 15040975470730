import {
  css,
  customElement,
  TemplateResult,
  unsafeCSS,
  property,
  query,
  state,
  ComplexAttributeConverter,
} from 'lit-element';
import { html, nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { ifDefined } from 'lit-html/directives/if-defined';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';

import { deprecatedListWrapperConverter, getStringArrayConverter } from '../../utils/component.utils';
import { getProjectedText, hasOverflow } from '../../utils/dom.utils';
import { BaseElement } from '../base/BaseElement';

import type { TooltipDirective } from '../../directives/tooltip/tooltip.directive';

import { hostStyles } from '../../host.styles';
import style from './truncate-with-tooltip.component.scss';

import '../../directives/tooltip/tooltip.directive';
import '../div/div.component';
import '../tooltip/tooltip.component';

const TRUNCATE_WITH_TOOLTIP_STYLES = css`
  ${unsafeCSS(style)}
`;

/**
 * Convenient component to truncate the given contents and to show a tooltip.
 *
 * @example truncate text only content and show tooltip on hover
 * ```html
 * <zui-button>
 *   <zui-truncate-with-tooltip tooltip-trigger="hover focus">
 *     <strong>This text</strong> will be <em>cut off</em> if the surrounding button becomes <code>too narrow</code>.
 *   </zui-truncate-with-tooltip>
 * </zui-button>
 * ```
 *
 * @example truncate content and preserve markup in tooltip
 * ```html
 * <zui-button>
 *   <zui-truncate-with-tooltip preserve-markup>
 *     <strong>This text</strong> will be <em>cut off</em> if the surrounding button becomes <code>too narrow</code>.
 *   </zui-truncate-with-tooltip>
 * </zui-button>
 * ```
 *
 * @example pass a custom tooltip to be used instead
 * ```html
 * <zui-button>
 *   <zui-truncate-with-tooltip tooltip-trigger="hover focus">
 *     <strong>This text</strong> will be <em>cut off</em> if the surrounding button becomes <code>too narrow</code>.
 *     <zui-tooltip slot="tooltip"><strong>This</strong> is shown as a custom tooltip.</zui-tooltip>
 *   </zui-truncate-with-tooltip>
 * </zui-button>
 * ```
 *
 * @slot default - The default slot contents will be truncated using text ellipsis.
 * @slot tooltip - Allows passing a custom tooltip to be shown.
 */
@customElement('zui-truncate-with-tooltip')
export class TruncateWithTooltip extends BaseElement {
  // deliberately do not import the hostStyles, because this would reset the slotted contents style
  // and because this is a directive it will be used within other components, i.e. it should not
  // affect any slotted content at all
  static readonly styles = [TRUNCATE_WITH_TOOLTIP_STYLES];

  /**
   * Wether to preserve html contents in expanded
   * tooltip or to show a text representation only.
   */
  @property({ reflect: true, attribute: 'preserve-markup', type: Boolean })
  preserveMarkup = false;

  /**
   * Anchoring of the tooltip, passed through.
   * See tooltip docs for more information.
   */
  @property({ reflect: true, attribute: 'tooltip-anchoring', type: String })
  tooltipAnchoring: TooltipDirective['anchoring'] = 'cursor';

  /**
   * Trigger of the tooltip, passed through.
   * See tooltip docs for more information.
   */
  @property({
    reflect: true,
    attribute: 'tooltip-trigger',
    converter: deprecatedListWrapperConverter(
      getStringArrayConverter<string>() as Required<ComplexAttributeConverter<string[]>>
    ),
  })
  tooltipTrigger: TooltipDirective['trigger'] = ['click', 'focus'];

  /**
   * Allows specifing a parent element selector to be used as interactive element for the tooltip trigger.
   * It _should not_ be `zui-div` as it is used internally and may result in unexpected behaviour.
   */
  @property({ reflect: true, attribute: 'interactive-element-selector', type: String })
  interactiveElementSelector?: string;

  @query('slot:not([name])')
  private readonly _truncateSlotRef: HTMLSlotElement;

  @query('zui-div')
  private readonly _truncateRef: HTMLElement;

  @state()
  private _isTruncated = false;

  // watch component size changes
  private readonly _truncationObserver = new ResizeObserver(() => this._checkTruncation());

  disconnectedCallback(): void {
    this._truncationObserver.disconnect();
    super.disconnectedCallback();
  }

  private _checkTruncation(): void {
    // check if there is some overflow goin'on
    this._isTruncated = hasOverflow(this._truncateRef);
  }

  protected firstUpdated(): void {
    this._checkTruncation();
    this._truncationObserver.observe(this._truncateRef);
  }

  protected render(): TemplateResult {
    return html`
      <zui-div class="${classMap({ truncated: this._isTruncated })}">
        <slot></slot>

        ${this._isTruncated
          ? html`
              <zui-tooltip-directive
                anchoring="${this.tooltipAnchoring}"
                level="1000"
                trigger="${this.tooltipTrigger.toString()}"
                trigger-host-selector="${ifDefined(this.interactiveElementSelector)}"
              >
                <slot name="tooltip">
                  <zui-tooltip>${unsafeHTML(getProjectedText(this._truncateSlotRef, this.preserveMarkup))}</zui-tooltip>
                </slot>
              </zui-tooltip-directive>
            `
          : nothing}
      </zui-div>
    `;
  }
}
