import {
  CSSResultArray,
  customElement,
  property,
  TemplateResult,
  svg,
  css,
  unsafeCSS,
  PropertyValues,
  html,
} from 'lit-element';

import { hostStyles } from '../../host.styles';
import { IconBaseElement } from '../base/icon-base-element.class';
import style from './progress-ring.component.scss';
import { styleMap } from 'lit-html/directives/style-map';
import { nothing } from 'lit-html/lib/part';
import { ifDefined } from 'lit-html/directives/if-defined';

const progressRingStyles = css`
  ${unsafeCSS(style)}
`;

export type Mode = 'activity' | 'progress';
type Size = SizeDeprecated | IconBaseElement['size'];
type Emphasis = 'default' | 'highlight' | 'primary-highlight' | EmphasisDeprecated;

/**
 * @deprecated
 */
type SizeDeprecated = 's' | 'm' | 'l';
/**
 * @deprecated
 */
type EmphasisDeprecated = 'active' | 'active-primary';

// prettier-ignore
const mapSizeToInternalSizeAndStroke: Record<Size, { size: number; stroke: number }> = {
    s: { size: 16, stroke: 2 },
    m: { size: 32, stroke: 3 },
    l: { size: 72, stroke: 4 },
   s8: { size:  8, stroke: 1},
  s12: { size: 12, stroke: 1},
  s16: { size: 16, stroke: 2},
  s24: { size: 24, stroke: 3},
  s32: { size: 32, stroke: 3},
  s48: { size: 48, stroke: 4},
  s64: { size: 64, stroke: 4},
  s72: { size: 72, stroke: 4},
  s80: { size: 80, stroke: 4},
};

const mapProgressRingSizeToIconSize: Record<SizeDeprecated, IconBaseElement['size']> = {
  s: 's12',
  m: 's16',
  l: 's24',
};

/**
 * The progress ring component is used as a progress indicator and loading spinner.
 * The colors of the animated ring depends on the {@link emphasis} setting.
 *
 *  ## Figma
 * - [Styleguide - Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/📖--Styleguide---Desktop?node-id=1%3A102376)
 *
 * @example
 * HTML:
 * ```html
 * <zui-progress-ring size="m" emphasis="highlight" mode="activity"></zui-progress-ring>
 * ```
 * @cssprop --zui-progress-ring-animation-duration - the duration of the progress ring animation
 * @cssprop --zui-progress-ring-animation-timing - the timing function of the progress ring animation
 * @cssprop --zui-progress-ring-progress-color - the color of the animated progress ring foreground, derived from the emphasis attribute
 * @cssprop --zui-progress-ring-rail-color - the color of the static progress ring background rail, derived from the emphasis attribute
 * @cssprop --zui-progress-ring-rail-opacity - the opacity of the static progress ring background rail
 */
@customElement('zui-progress-ring')
export class ProgressRing extends IconBaseElement<SizeDeprecated> {
  static get styles(): CSSResultArray {
    return [hostStyles, progressRingStyles];
  }

  /**
   * Defines the mode of the progress ring.
   */
  @property({ reflect: true, type: String })
  mode: Mode = 'activity';

  /**
   * Defines one of three possible emphasis states (default/highlight/primary-highlight)
   * The deprecated emphasis "active" and "active-primary" were renamed to "highlight" and "primary-highlight"
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Defines the size of the component.
   * Only the following values should be used in the progress ring: s16, s32, s72!
   */
  @property({ reflect: true, type: String })
  size: Size = 's32';

  // TODO: remove once icon sizes are the same as progress ring sizes
  /**
   * Enables the usage as an icon and thus changing the behavior of the size attribute.
   *
   * @deprecated
   */
  @property({ reflect: true, type: Boolean, attribute: 'as-icon' })
  asIcon = false;

  /**
   * It allows to show the value as a percentage text (only usable with mode progress)
   */
  @property({ reflect: true, attribute: 'show-percent', type: Boolean })
  showPercent = false;

  /**
   * Defines the value of progress between 0 and 100 (only usable with mode progress)
   */
  @property({ reflect: true, type: Number })
  value = 0;

  private get _correctedValue(): number {
    const clamped = Math.min(Math.max(this.value, 0), 100);
    return Math.floor(clamped);
  }

  private get _internalStroke(): number {
    return mapSizeToInternalSizeAndStroke[
      this.asIcon ? mapProgressRingSizeToIconSize[this.size] ?? this.size : this.size
    ].stroke;
  }

  private get _internalSize(): number {
    return mapSizeToInternalSizeAndStroke[
      this.asIcon ? mapProgressRingSizeToIconSize[this.size] ?? this.size : this.size
    ].size;
  }

  /**
   * Renders a single SVG circle
   *
   * @param {string} className - the css class to be used
   * @returns {(TemplateResult|void)} lit-html template
   */
  private _renderCircle(className: 'rail' | 'progress'): TemplateResult {
    const radius = (this._internalSize - this._internalStroke) / 2;
    const center = this._internalSize / 2;
    const strokeMiterLimit = this._internalStroke / 2;
    const circumference = radius * 2 * Math.PI;
    const offset = circumference - (circumference * this._correctedValue) / 100;
    const hasProgress = this.mode === 'progress' && className === 'progress';

    return svg`
			<circle class="${className}"
							r="${radius}"
							cx="${center}"
							cy="${center}"
							stroke-miterlimit="${strokeMiterLimit}"
							stroke-dasharray="${ifDefined(hasProgress ? circumference : undefined)}"
							stroke-dashoffset="${ifDefined(hasProgress ? offset : undefined)}"
			/>
		`;
  }

  protected update(changedProperties: PropertyValues): void {
    super.update(changedProperties);
    // TODO: remove once deprecations are gone
    if (changedProperties.has('emphasis')) {
      if (this.emphasis === 'active' || this.emphasis === 'active-primary') {
        console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-progress-ring.`);
      }
    }
  }

  protected render(): TemplateResult {
    return html`<div
        class="host"
        style="${styleMap({
          '---zui-progress-ring-size': `${this._internalSize}px`,
          '---zui-progress-ring-width': `${this._internalStroke}px`,
        })}"
        >${svg`
        <svg preserveAspectRatio="xMidYMid meet"
             viewBox="0 0 ${this._internalSize} ${this._internalSize}"
        >
          ${this._renderCircle('rail')}
          ${this._renderCircle('progress')}
        </svg>`}
      </div>
      ${this.showPercent ? html`<span>${this._correctedValue}%</span>` : nothing}`;
  }
}
