import {
  css,
  CSSResultArray,
  customElement,
  html,
  property,
  queryAssignedNodes,
  state,
  TemplateResult,
  unsafeCSS,
} from 'lit-element';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import { hostStyles } from '../../../host.styles';
import { event } from '../../../decorators/event.decorator';
import style from './content-tab-default-item.component.scss';
import { getContentClass } from '../content-tab.utils';
import { ContentTabItem, ContentTabItemBase } from '../content-tab-base.class';

type Hierarchy = ContentTabItem['hierarchy'];
type Size = ContentTabItem['size'];

const contentTabDefaultItemStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The content tab default item is a content tab item for any content.
 * The content tab default item is intended for usage in the content tab bar component.
 *
 * The height of the item is depending on the selected size option: (48px as a default size m) or (32px as a size s).
 * The width of the item is solely be depending on width of the given content.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=52930%3A120)
 * - [Styleguide – Desktop](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---3.1?node-id=76949%3A367659)
 *
 * @example
 * HTML:
 *
 * Text only:
 * ```html
 * <zui-content-tab-default-item text="My custom text" value="textItemOne">
 * </zui-content-tab-default-item>
 * ```
 *
 * Icon only:
 * ```html
 * <zui-content-tab-default-item value="iconItemOne">
 *   <zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list>
 * </zui-content-tab-default-item>
 *  ```
 *
 * Text and icon:
 * ```html
 * <zui-content-tab-default-item text="My custom text" value="textAndIconItemOne">
 *   <zui-icon-server-server-server-list slot="icon"></zui-icon-server-server-server-list>
 * </zui-content-tab-default-item>
 *  ```
 *
 * @fires content-tab-item-closed - fired the close icon of a tab was clicked. Contains the value of the tab as payload.
 * @slot icon - Here you can insert your own icon. Size of them will set everytime automatically as 'm' 16x16px
 */
@customElement('zui-content-tab-default-item')
export class ContentTabDefaultItem extends ContentTabItemBase {
  static get styles(): CSSResultArray {
    return [hostStyles, contentTabDefaultItemStyles];
  }

  /**
   * Defines one of two possible sizes (s/l);
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  /**
   * Defines one of two possible hierarchy level (first/second).
   */
  @property({ reflect: true, type: String })
  hierarchy: Hierarchy = 'first';

  /**
   * Emit close event
   *
   * @private
   */
  @event({
    eventName: 'content-tab-item-closed',
    bubbles: true,
    composed: false,
    cancelable: false,
  })
  emitContentTabItemClosedEvent(): void {
    this.dispatchEvent(
      new CustomEvent('content-tab-item-closed', {
        bubbles: true,
        composed: false,
        cancelable: false,
        detail: {
          value: this.value,
        },
      })
    );
  }

  /**
   * We need to simulate a hover on a button.
   * We cannot use the normal `:hover` pseudo-selector here:
   * There is the requirement that when the close button is clicked, the rest of the tab item
   * may not be active/focused. To achieve this, we've put the close button and the
   * button of the tab next to each other instead of nesting them.
   * Then the close button is positioned above the tab-button so that it looks as if the close
   * button is a child of the tab-button.
   * However, due to this decision, we have a problem with the hover effect:
   * We want to have a hover styling on the whole tab also when the close button is hovered,
   * but the normal `:hover` on the tab-button won't match in this case.
   * We cannot apply stying to the tab-button when a sibling of it is hovered.
   * This isn't possible in pure css.
   *
   * As a workaround, we track when the close-button is hovered in classic JavaScript-way by
   * using mouseenter and mouseleave events and add a class to the tab-button in those cases.
   */
  @state()
  private _isHover = false;

  /**
   * Selects the icon slot element
   */
  @queryAssignedNodes('icon', true, '[zuiIcon]')
  private _slotIcon: HTMLElement[];

  /**
   * Set css classes based on content of the slots or removes them if slots change
   */
  private _handleIconSlotChange(): void {
    this._iconSizeUpdate();
    // we need to update (i.e. call render()) to re-calculate the new contentClass
    this.requestUpdate();
  }

  /**
   * Sets size of icon. Always as "m"
   */
  private _iconSizeUpdate(): void {
    this._slotIcon.forEach((icon) => icon.setAttribute('size', 'm'));
  }

  private _setHover(): void {
    this._isHover = true;
  }

  private _removeHover(): void {
    this._isHover = false;
  }

  protected render(): TemplateResult | void {
    return html`<button
        ?disabled=${this.disabled}
        class="${classMap({ [getContentClass(this._slotIcon, this.text)]: true, hover: this._isHover })}"
      >
        <div id="icon-slot-container">
          <slot id="icon-slot" name="icon" @slotchange=${this._handleIconSlotChange}></slot>
        </div>
        <div id="text-container">${this.text}</div>
      </button>
      ${this.closable
        ? html`<div id="closable-container-absolute" @mouseover=${this._setHover} @mouseleave=${this._removeHover}>
            <zui-interactive-icon
              @click="${this.emitContentTabItemClosedEvent}"
              ?disabled=${this.disabled}
              emphasis=${this.emphasis === 'default' ? 'subtle' : 'highlight'}
            >
              <zui-icon-close size="xs"></zui-icon-close>
            </zui-interactive-icon>
          </div>`
        : nothing}`;
  }
}
