import { css, CSSResult, customElement, html, query, queryAssignedNodes, TemplateResult, unsafeCSS } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { BaseElement } from '../base/BaseElement';

import { hostStyles } from '../../host.styles';
import styles from './focus-view-bar.component.scss';

const focusViewBarStyles = css`
  ${unsafeCSS(styles)}
`;
/**
 * The Focus View Bar is a different type of headerbar for the modal focus view dialog. It has slots for left, right and center content and additional allows to show up to two buttons at the sides.
 * If you are using the left or right content together with the center content it is important to implement custom breakpoints, so that you can control what happens with the content when there is not enough space.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/z4fyXFOJCpuaNImx3K234n/%E2%9D%96-04-Web---Component-Library---1.5?node-id=763%3A46553)
 * - [Styleguide – Desktop](https://www.figma.com/file/6dkjypErYWQPfuRBD58Aey/%F0%9F%93%96--Styleguide---Web?node-id=7683%3A79745)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-focus-view-bar>
 *   <zui-headerbar-icon-button slot="left-icon-button" emphasis="subtle" size="m">
 *     <zui-icon-arrow-outline-arrow-outline-actually-centred-left slot="icon"></zui-icon-arrow-outline-arrow-outline-actually-centred-left>
 *   </zui-headerbar-icon-button>
 *   <div slot="left-content" style="font: var(--zui-typography-h3); margin-left: 32px;">Left</div>
 *   <div slot="center-content" style="font: var(--zui-typography-h3);">Center</div>
 *   <div slot="right-content" style="font: var(--zui-typography-h3); margin-right: 32px;">Right</div>
 *   <zui-headerbar-icon-button slot="right-icon-button" emphasis="subtle" size="m">
 *     <zui-icon-close slot="icon"></zui-icon-close>
 *   </zui-headerbar-icon-button>
 * </focus-view-bar>
 * ```
 * @slot left-icon-button - This is the slot for zui-headerbar-icon-button that gets displayed on the left side.
 * @slot right-icon-button - This is the slot for zui-headerbar-icon-button that gets displayed on the right side.
 * @slot left-content - This is the slot for the content which gets displayed on the left side.
 * @slot right-content - This is the slot for the content which gets displayed on the right side.
 * @slot center-content - This is the slot for the content which gets displayed in the center of the component.
 */
@customElement('zui-focus-view-bar')
export class FocusViewBar extends BaseElement {
  @queryAssignedNodes('left-icon-button', true)
  private _leftIconButton: HTMLElement[] | null;

  @queryAssignedNodes('left-content', true)
  private _leftContent: HTMLElement[] | null;

  @queryAssignedNodes('right-icon-button', true)
  private _rightIconButton: HTMLElement[] | null;

  @queryAssignedNodes('right-content', true)
  private _rightContent: HTMLElement[] | null;

  @queryAssignedNodes('center-content', true)
  private _centerContent: HTMLElement[] | null;

  @query('#center-aligned-content', true)
  private _centerContainer: HTMLElement;

  private _widthCenter = 0;

  private _widthfocusViewBar = 0;

  /**
   * The left separator should only be shown if both the left icon button and the left content are visible.
   * This function checks if the corresponding slots are used.
   */
  private get _isLeftseparatorNeeded(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._leftContent?.length > 0 && this._leftIconButton?.length > 0;
  }

  /**
   * The right separator should only be shown if both the right icon button and the right content are visible.
   * This function checks if the corresponding slots are used.
   */
  private get _isRightseparatorNeeded(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._rightContent?.length > 0 && this._rightIconButton?.length > 0;
  }

  private get _isLeftContentUsed(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._leftContent?.length > 0;
  }

  private get _isRightContentUsed(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._rightContent?.length > 0;
  }

  private get _isCenterContentUsed(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._centerContent?.length > 0;
  }

  private _centerAlignedContentResizeObserver = new ResizeObserver(([{ contentRect }]) => {
    requestAnimationFrame(() => {
      this._widthCenter = contentRect.width;
      this.requestUpdate();
    });
  });

  private _focusViewBarResizeObserver = new ResizeObserver(([{ contentRect }]) => {
    requestAnimationFrame(() => {
      this._widthfocusViewBar = contentRect.width;
      this.requestUpdate();
    });
  });

  disconnectedCallback(): void {
    this._centerAlignedContentResizeObserver.unobserve(this._centerContainer);
    this._focusViewBarResizeObserver.unobserve(this);

    super.disconnectedCallback();
  }

  static get styles(): CSSResult[] {
    return [hostStyles, focusViewBarStyles];
  }

  /**
   * When slot content changes we need to update css classes. For this we need to trigger a rerendering on every slotchange.
   */
  private _handleSlotchange(): void {
    this.requestUpdate();
  }

  private get _minWidth(): string {
    const margin = this._isCenterContentUsed ? 64 : 32;
    return `${(this._widthfocusViewBar - this._widthCenter - margin) / 2}px`;
  }

  private get _maxWidth(): string {
    const margin = this._isCenterContentUsed ? 64 : 32;
    const maxCenterContentWidth = this._widthfocusViewBar - margin - 130;
    return maxCenterContentWidth < 0 ? '0px' : `${maxCenterContentWidth}px`;
  }

  protected firstUpdated(): void {
    this._centerAlignedContentResizeObserver.observe(this._centerContainer);
    this._focusViewBarResizeObserver.observe(this);
  }

  protected render(): TemplateResult {
    return html`
      <div id="container">
        <div
          id="left"
          class="${classMap({ separator: this._isLeftseparatorNeeded })}"
          style="min-width: ${this._minWidth}"
        >
          <div id="left-icon-button">
            <slot @slotchange="${this._handleSlotchange}" name="left-icon-button"></slot>
          </div>
          <zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider>
          <div id="left-aligned-content" class="${classMap({ used: this._isLeftContentUsed })}"
            ><slot name="left-content" @slotchange="${this._handleSlotchange}"></slot
          ></div>
        </div>
        <div
          id="center-aligned-content"
          class="${classMap({ used: this._isCenterContentUsed })}"
          style="max-width: ${this._maxWidth};"
          ><slot name="center-content" @slotchange="${this._handleSlotchange}"></slot
        ></div>
        <div
          id="right"
          class="${classMap({ separator: this._isRightseparatorNeeded })}"
          style="min-width: ${this._minWidth}"
        >
          <div id="right-aligned-content" class="${classMap({ used: this._isRightContentUsed })}"
            ><slot name="right-content" @slotchange="${this._handleSlotchange}"></slot
          ></div>
          <zui-divider emphasis="default" size="s" orientation="vertical"></zui-divider>
          <div id="right-icon-button"
            ><slot @slotchange="${this._handleSlotchange}" name="right-icon-button"></slot
          ></div>
        </div>
      </div>
    `;
  }
}
