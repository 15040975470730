import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  unsafeCSS,
  css,
  queryAssignedNodes,
} from 'lit-element';
import { event } from '../../../decorators/event.decorator';
import { nothing } from 'lit-html';
import { query, state } from 'lit-element/lib/decorators';
import { FormValidationMixin } from '../../../mixins/form-participation/form-validation.mixin';
import { FormDataHandlingMixin } from '../../../mixins/form-participation/form-data-handling.mixin';
import { RealBaseElement } from '../../base/BaseElement';
import { FormEnabledElement, FormValidationElement } from '../../../mixins/form-participation/form-participation.types';
import { ifDefined } from 'lit-html/directives/if-defined.js';
import { numberUndefinedConverter, stringUndefinedConverter } from '../../../utils/component.utils';
import { DelegateFocusMixin } from '../../../mixins/visual-focus/delegate-focus.mixin';
import { classMap } from 'lit-html/directives/class-map';

import { Select } from '../../select/select/select.component';
import '../../interactive-icon/interactive-icon.component';

import { hostStyles } from '../../../host.styles';
import style from './searchbar-input.component.scss';

const searchbarInputStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The SearchbarInput is a input field for search queries and allows users to quickly explore a website or application.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-searchbar-input placeholder="Enter your search here..."></zui-searchbar-input>
 * ```
 *
 *  * HTML (SearchbarInput with filter):
 *
 * ```html
 * <zui-searchbar-input placeholder="Enter your search here...">
 *   <zui-select
 *     all-item-label="Everything"
 *     hide-border
 *     multiple
 *     show-all-item
 *     slot="filter"
 *   >
 *     <zui-select-placeholder slot="placeholder" pattern-all="%selection" pattern-many="%selection">
 *       Filter...
 *     </zui-select-placeholder>
 *     <zui-select-option value="books">Books</zui-select-option>
 *     <zui-select-option value="movies">Movies</zui-select-option>
 *   </zui-select>
 * </zui-searchbar-input>
 * ```
 * @slot filter - Slot for a select with select options
 * @fires change - The change event is fired when the value of the searchbar-input has changed
 * @fires input - The input event is fired when the value of the searchbar-input has received input
 * @fires searchbar-input-changed - The searchbar-input-changed event is fired when the value of the searchbar-input has changed
 */
@customElement('zui-searchbar-input')
export class SearchbarInput
  extends FormValidationMixin(FormDataHandlingMixin(DelegateFocusMixin(RealBaseElement)))
  implements FormValidationElement<FormEnabledElement> {
  static get styles(): CSSResultArray {
    return [hostStyles, searchbarInputStyles];
  }

  /**
   * maxlength
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  maxlength: number | undefined;

  /**
   * minlength
   */
  @property({ reflect: true, converter: numberUndefinedConverter })
  minlength: number | undefined;

  /**
   * pattern
   */
  @property({ reflect: true, converter: stringUndefinedConverter })
  pattern: string | undefined;

  /**
   * Sets the placeholder text for the input
   */
  @property({ reflect: true, type: String })
  placeholder = '';

  /**
   * required
   */
  @property({ reflect: true, type: Boolean })
  required = false;

  /**
   * value
   *
   * @returns {string | undefined} value
   */
  @property({ reflect: true, converter: stringUndefinedConverter })
  get value(): string | undefined {
    return this._internalValue;
  }

  set value(value: string | undefined) {
    const oldValue = this.value;
    this._internalValue = value;
    this._hasValue = value !== undefined && value.length !== 0;

    this.requestUpdate('value', oldValue);
  }

  /**
   * @private
   */
  @event({
    eventName: 'input',
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitInputEvent(): void {
    this.dispatchEvent(
      new InputEvent('input', {
        bubbles: true,
        cancelable: false,
        composed: true,
      })
    );
  }

  /**
   * @private
   */
  @event({
    eventName: 'change',
    bubbles: true,
    cancelable: false,
    composed: false,
  })
  emitChangeEvent(): void {
    this.dispatchEvent(
      new Event('change', {
        bubbles: true,
        cancelable: false,
        composed: false,
      })
    );
  }

  /**
   * @param value changed value or input value on input
   *
   * @private
   */
  @event({
    eventName: 'searchbar-input-changed',
    bubbles: true,
    composed: true,
  })
  emitSearchbarInputChangedEvent(value: string | undefined): void {
    this.dispatchEvent(
      new CustomEvent('searchbar-input-changed', {
        bubbles: true,
        composed: true,
        detail: { value: value !== undefined ? value : '' },
      })
    );
  }

  @query('#searchbar-input')
  private _searchbarInput: HTMLInputElement;

  @queryAssignedNodes('filter', true, 'zui-select')
  private _assignedFilterMenu: Select[] | null;

  @state()
  private _hasValue = false;

  private _internalValue: string | undefined;

  constructor() {
    super();

    this.addValidator({
      type: 'patternMismatch',
      validatesOnProperties: ['pattern'],
      validator: (): boolean => !this._searchbarInput.validity.patternMismatch,
    });

    this.addValidator({
      type: 'tooLong',
      validatesOnProperties: ['maxlength'],
      validator: (): boolean =>
        this.value !== undefined && this.value.length > 0 && this.maxlength
          ? this.value.length <= this.maxlength
          : true,
    });

    this.addValidator({
      type: 'tooShort',
      validatesOnProperties: ['minlength'],
      validator: (): boolean =>
        this.value !== undefined && this.value.length > 0 && this.minlength
          ? this.value.length >= this.minlength
          : true,
    });

    this.addValidator({
      type: 'valueMissing',
      validatesOnProperties: ['required'],
      validator: (): boolean => !this._searchbarInput.validity.valueMissing,
    });
  }

  private get _hasFilter(): boolean {
    return this._assignedFilterMenu !== null ? this._assignedFilterMenu.length > 0 : false;
  }

  private get _showClearInputIcon(): boolean {
    return this._hasValue && !this.disabled && !this.readonly;
  }

  private get _showSearchIcon(): boolean {
    return !this._hasValue;
  }

  private _handleClearInputValue(): void {
    this.value = '';
    this._searchbarInput.focus();

    this.emitInputEvent();
    this.emitSearchbarInputChangedEvent(this.value);
  }

  // we need this to dispatch a custom event to react on input value changes (searchbar results) and not only already committed changed ones
  private _handleSearchbarInputEvent(): void {
    this._hasValue = this._searchbarInput.value.length > 0;

    this.emitSearchbarInputChangedEvent(this._searchbarInput.value);
  }

  private _handleSearchbarInputChangeEvent(): void {
    this.value = this._searchbarInput.value;

    this.emitChangeEvent();
  }

  private _handleSlotChange(): void {
    this.requestUpdate();
  }

  // todo: zuiCaptureFocus => zui-capture-focus
  protected render(): TemplateResult {
    return html`
      <div class="${classMap({ 'searchbar-input-wrapper': true, 'has-filter': this._hasFilter })}">
        <slot name="filter" @slotchange="${this._handleSlotChange}"></slot>
        <div class="input-wrapper">
          <input
            zuiFormControl
            .value="${this.value !== undefined ? this.value : null}"
            ?disabled=${this.disabled}
            ?readonly=${this.readonly}
            ?required=${this.required}
            ?zuiCaptureFocus="${!this.disabled}"
            id="searchbar-input"
            maxlength="${ifDefined(this.maxlength)}"
            minlength="${ifDefined(this.minlength)}"
            pattern="${ifDefined(this.pattern)}"
            placeholder="${this.placeholder}"
            type="text"
            @change="${this._handleSearchbarInputChangeEvent}"
            @input=${this._handleSearchbarInputEvent}
          />
        </div>
        ${this._showClearInputIcon
          ? html`
              <zui-interactive-icon id="close-icon" emphasis="subtle">
                <zui-icon-close size="s" @click="${this._handleClearInputValue}"></zui-icon-close>
              </zui-interactive-icon>
            `
          : nothing}
        ${this._showSearchIcon
          ? html`<zui-icon-search-search id="search-icon" size="m"></zui-icon-search-search> `
          : nothing}
      </div>
    `;
  }
}
