import { property } from 'lit-element';
import { BaseElement } from '../base/BaseElement';

type Variant = 'primary' | 'secondary';

type EmphasisDeprecated = 'active' | 'active-primary';
type Emphasis = 'default' | 'selected' | 'selected-primary' | EmphasisDeprecated;

/**
 * This interface for menubar-items contains those properties that
 * are used by the menubar.
 */
export interface MenubarItemElement {
  disabled: boolean;
  emphasis: Emphasis;
  value: string;

  variant: Variant;
}

/**
 * Abstract base class for all menubar-items that are used in the menubar.
 */
export abstract class MenubarItemBase extends BaseElement implements MenubarItemElement {
  /**
   * marker to know at runtime that this element is a menubar-item.
   *
   * @private
   */
  @property({ reflect: true, type: Boolean, attribute: 'zui-internal-is-menubar-item' })
  readonly isMenubarItem = true as const;

  /**
   * It is possible to set the attribute: disabled.
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * Defines one of three possible emphasis states (default/selected/selected-primary).
   * The deprecated emphasis "active" and "active-primary" were renamed to "selected" and "selected-primary".
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * Value is used to identify menubar item.
   */
  @property({ reflect: true, type: String })
  value: string;

  /**
   * Sets the Color variant of the Menu Item
   */
  @property({ reflect: true, type: String })
  variant: Variant = 'primary';

  /**
   * Detects if one of the deprecated emphasis states was used
   *
   * @param {Map<string,unknown>} changedProperties properties which have been changed
   */
  protected update(changedProperties: Map<string, unknown>): void {
    super.update(changedProperties);
    if (changedProperties.has('emphasis')) {
      if (this.emphasis === 'active' || this.emphasis === 'active-primary') {
        console.warn(`Deprecated emphasis: ${this.emphasis} was used on zui-menubar.`);
      }
    }
  }
}
