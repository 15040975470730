import {
  CSSResultArray,
  TemplateResult,
  customElement,
  html,
  property,
  css,
  unsafeCSS,
  queryAssignedNodes,
} from 'lit-element';
import { hostStyles } from '../../../host.styles';
import style from './menubar-nav-item.component.scss';
import { event } from '../../../decorators/event.decorator';
import { MenubarItemBase } from '../menubar-item-base.class';

const menuBarNavItemStyles = css`
  ${unsafeCSS(style)}
`;

/**
 * The zui-menubar-nav-item, is an item of the zui-menubar which holds an icon with label and can be clicked. It throws in this case an custom event with its own value as event value.
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-menubar-nav-item value="1">
 *   <zui-icon-holy-placeholder slot="icon"></zui-icon-holy-placeholder>
 * 	 Label
 * </zui-menubar-nav-item>
 * ```
 *
 * @fires {CustomEvent<{ value: string }>} menubar-item-selected - The Event which gets fired when the menu bar nav item gets clicked. It has the value of the menu bar nav item as value.
 * @fires {CustomEvent<{ value: string }>} menubarItemSelected - (Deprecated) The Event which gets fired when the menu bar nav item gets clicked. It has the value of the menu bar nav item as value.
 *
 * @slot icon - This is where the icon is displayed
 * @slot default - This is where the label is displayed
 * @cssprop --zui-menubar-nav-item-background-color - color of the nav item background, derived from emphasis and variant attributes by default
 * @cssprop --zui-menubar-nav-item-color - typo color of the nav item, derived from emphasis and variant attributes by default
 * @cssprop --zui-menubar-nav-item-disabled-opacity - opaqueness if disabled
 * @cssprop --zui-menubar-nav-item-height - sets the height of the item explicitly
 * @cssprop --zui-menubar-nav-item-label-font - sets the font family of the label
 * @cssprop --zui-menubar-nav-item-label-opacity - sets the opacity of the label, is toggled by visiblity
 */
@customElement('zui-menubar-nav-item')
export class MenubarNavItem extends MenubarItemBase {
  static get styles(): CSSResultArray {
    return [hostStyles, menuBarNavItemStyles];
  }

  /**
   * Sets the opened state of the MenubarNavItem
   */
  @property({ reflect: true, type: Boolean })
  opened = false;

  /**
   * @param detail detail
   * @param detail.value value
   *
   * @private
   */
  @event({
    eventName: 'menubar-item-selected',
  })
  emitMenubarItemSelectedEvent(detail: { value: string }): void {
    // TODO: remove in version 2.0
    this.dispatchEvent(
      new CustomEvent('menubarItemSelected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );

    this.dispatchEvent(
      new CustomEvent('menubar-item-selected', {
        bubbles: true,
        composed: true,
        detail,
      })
    );
  }

  @queryAssignedNodes('icon', true, '[zuiIcon]')
  private _assignedIcons: HTMLElement[];

  private _handleClick(): void {
    this.emitMenubarItemSelectedEvent({ value: this.value });
  }

  private _handleIconSlotChange(): void {
    // set size of icon in the named slot to 'm'.
    this._assignedIcons.forEach((icon) => icon.setAttribute('size', 'm'));
  }

  protected render(): TemplateResult {
    return html`
      <div class="menubar-nav-item" @click="${this._handleClick}">
        <div class="menubar-nav-item-icon">
          <slot name="icon" @slotchange="${this._handleIconSlotChange}"></slot>
        </div>
        <div class="menubar-nav-item-label">
          <slot></slot>
        </div>
      </div>
    `;
  }
}
