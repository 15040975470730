// FIXME: rethink the whole thing
import { LitElement, internalProperty, property } from 'lit-element';

/**
 * Environment
 * - AUTO "auto" globally set environment or browser capability
 * - Desktop "desktop" normal environment without touch capability
 * - TOUCH "touch" optimized for touch devices
 *
 * @property {string} AUTO "auto" globally set environment or browser capability
 * @property {string} Desktop "desktop" normal environment without touch capability
 * @property {string} TOUCH "touch" optimized for touch devices
 */
export enum Environment {
  AUTO = 'auto',
  DESKTOP = 'desktop',
  TOUCH = 'touch',
}

const env = 'environment';

/**
 * Base element for all components to extend.
 */
export abstract class BaseElement extends LitElement {
  //* Properties and Getter/Setter
  /**
   * (optional) Name of the theme that should be used for the component.
   */
  @property({ reflect: true })
  theme: string;

  /**
   * tagging property used to use *[zui-element] as a querySelector for all zui elements
   *
   * @private
   */
  @property({ reflect: true, attribute: 'zui-element', type: Boolean })
  isElement = true;

  /**
   * Determines which environment theme to apply ('desktop' | 'touch' | 'auto')
   *
   * @returns {Environment} the resolved environment
   */
  @property({ reflect: true })
  get environment(): Environment {
    if (this._environment !== Environment.AUTO) {
      return this._environment;
    }

    if (this._globalEnv !== Environment.AUTO) {
      return this._globalEnv;
    }

    return this._mediaEnv;
  }

  /**
   * Sets the environment for a component explicitly with ('desktop' | 'touch')
   * or to the global environment with ('auto')
   */
  set environment(val: Environment) {
    const oldVal = this._environment;
    this._environment = val;
    this.requestUpdate('environment', oldVal);
  }

  @internalProperty()
  private _globalEnv: Environment = (document.documentElement.getAttribute(env) as Environment) || Environment.AUTO;

  @internalProperty()
  private _mediaEnv: Environment;

  private _environment: Environment = Environment.AUTO;

  /**
   *  Determines if a touch environment is applicable
   *
   *  @returns {boolean} if in touch environmet
   */
  get hasTouch(): boolean {
    return this.environment === Environment.TOUCH;
  }

  private _envObserver: MutationObserver;

  private _mediaObserver: MediaQueryList;

  //* Constructor and static functions
  constructor() {
    super();

    this._handleMatchMedia = this._handleMatchMedia.bind(this);
    this._handleEnvironmentChange = this._handleEnvironmentChange.bind(this);

    this._mediaObserver = window.matchMedia('(pointer:coarse)');
    this._mediaEnv = this._mediaObserver.matches ? Environment.TOUCH : Environment.DESKTOP;
    this._envObserver = new MutationObserver(this._handleEnvironmentChange);
  }

  //* Callbacks and EventListener
  connectedCallback(): void {
    super.connectedCallback();

    // Safari doesn't have 'addEventListener' but only 'addListener'
    if (this._mediaObserver.addEventListener) {
      this._mediaObserver.addEventListener('change', this._handleMatchMedia);
    } else {
      this._mediaObserver.addListener(this._handleMatchMedia);
    }
    this._envObserver.observe(document.documentElement, { attributeFilter: [env] });
  }

  disconnectedCallback(): void {
    // Safari doesn't have 'removeEventListener' but only 'removeListener'
    if (this._mediaObserver.removeEventListener) {
      this._mediaObserver.removeEventListener('change', this._handleMatchMedia);
    } else {
      this._mediaObserver.removeListener(this._handleMatchMedia);
    }
    this._envObserver.disconnect();

    super.disconnectedCallback();
  }

  // TODO: get rid of crazy env change logic
  private _handleEnvironmentChange(ev: MutationRecord[]): void {
    ev.filter((val: MutationRecord) => val.attributeName === env && val.target === document.documentElement).forEach(
      (val: MutationRecord) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (this._globalEnv = (val.target as HTMLElement).getAttribute(val.attributeName!) as Environment)
    );
  }

  private _handleMatchMedia(ev: MediaQueryListEvent): void {
    this._mediaEnv = ev.matches ? Environment.TOUCH : Environment.DESKTOP;
  }

  /**
   * Helper method to generate class names for properties of the component that include the value of the property.
   *
   * This can be useful for properties whose type is a union type and depending on the value a class should be used in
   * the render method.
   *
   * @example
   * For example:
   * ```typescript
   * type Size = 's' | 'm' | 'l'
   * ...
   * @property({reflect: true})
   * size: Size = 'l'
   *
   * this.generateClass('size') // generates "size_s", "size_m" or "size_l" depending on the current value of 'size"
   * ```
   *
   * @param prop - the property name of the field.
   * @returns {string} a css class or empty string if the field has no value set.
   */
  protected generateClass(prop: keyof this): string {
    const value = this[prop];
    return typeof value === 'string' && value.length > 0 ? `${prop}_${value}` : '';
  }

  //* Render methods
  //* [...]
}

// we need this for usage with Mixins, because abstract classes are not possible due to TS restrictions...
export class RealBaseElement extends BaseElement {}
