import { CSSResultArray, TemplateResult, customElement, html, property, css, unsafeCSS } from 'lit-element';
import { hostStyles } from '../../../host.styles';
import { BaseElement } from '../../base/BaseElement';
import style from './list-accordion-item.component.scss';
import { query } from 'lit-element/lib/decorators';

const listAccordionItemStyles = css`
  ${unsafeCSS(style)}
`;

type Emphasis = 'default' | 'selected' | 'selected-primary';
type Size = 'm';

/**
 * A list accordion item is meant to be used inside the list accordion.
 *
 * ## Figma
 * - [Desktop - Component Library](https://www.figma.com/file/vMeLQZQBMU0gKnghKd23PI/%E2%9D%96-01-Desktop---Component-Library---4.1?node-id=76932%3A367574)
 * - [Styleguide – Desktop](https://www.figma.com/file/h21HmGasnyWg8IJib5HEzm/%F0%9F%93%96--Styleguide---Desktop?node-id=1%3A102403)
 *
 * @example
 * HTML:
 *
 * ```html
 * <zui-list-accordion-item emphasis="default" primary-text="List item"></zui-list-accordion-item>
 * ```
 *
 * @cssprop --zui-list-accordion-item-indent - override default primary text indent of 48px
 */
@customElement('zui-list-accordion-item')
export class ListAccordionItem extends BaseElement {
  static get styles(): CSSResultArray {
    return [hostStyles, listAccordionItemStyles];
  }

  /**
   * disabled
   */
  @property({ reflect: true, type: Boolean })
  disabled = false;

  /**
   * emphasis
   */
  @property({ reflect: true, type: String })
  emphasis: Emphasis = 'default';

  /**
   * primary text
   */
  @property({ reflect: true, type: String, attribute: 'primary-text' })
  primaryText = '';

  /**
   * size
   */
  @property({ reflect: true, type: String })
  size: Size = 'm';

  @query('.list-accordion-item')
  private _listAccordionItem: HTMLButtonElement;

  private _handleClick(): void {
    // Chrome `.focus()` on _clicked_ buttons, Firefox and Safari don't; because
    // we do not want to have this behavior from Chrome, we are explicitly blurring
    this._listAccordionItem.blur();
  }

  protected render(): TemplateResult {
    return html`
      <button ?disabled="${this.disabled}" class="list-accordion-item" @click="${this._handleClick}">
        <div class="list-accordion-item-primary-text">${this.primaryText}</div>
      </button>
    `;
  }
}
